.Tabs-menu {
  position: absolute;
  right: 0;
  z-index: 99999;
}

.nav-tabs {
  border: none;
  position: fixed;
  top: 28px;
  right: 0;
  z-index: 99999;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: rgb(51, 136, 223);
  font-weight: 500;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 2px solid rgb(51, 136, 223);
  padding-bottom: 17px;
  padding-left: 0px;
  padding-right: 0px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom: 2px solid #6539c8;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: transparent;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 34px;
}

.nav-tabs a {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(46, 50, 56);
  text-transform: uppercase;
  line-height: 1.2;
  border-bottom: 1px solid #6539c8;
}

.nav-tabs .nav-link.disabled {
  color: rgb(46, 50, 56);
}

@media only screen and (max-width: 900px) {
  .nav-tabing {
    display: none;
  }

  .nav-tabing.in {
    display: block;
    background-color: #252c41;
    color: #fff;
    left: 0;
    top: 0;
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .nav-tabing .nav-item.show .nav-link,
  .nav-tabing .nav-link {
    font-size: 24px;
    line-height: 2;
  }

  .nav-tabs .nav-link.disabled {
    color: #fff;
  }

  .nav-tabing .nav-item.show .nav-link,
  .nav-tabing .nav-link.active {
    color: #fff;
    font-weight: 500;
    background-color: transparent;
    border-color: transparent;
    border-bottom: none;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.menu-calculator {
  margin-top: 10px;
}
