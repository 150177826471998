.paymentowed-table .table {
  display: inline-table;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(100px);
  border-radius: 10px;
  display: block;
}

.paymentowed-table-2 .table {
  margin-top: 60px;
}

.paymentowed-table .dashTitle-bar {
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(100px);
  border-radius: 10px 10px 0 0;
  padding: 30px 30px 10px 30px;
  max-width: 100%;
  width: 100%;
}

.paymentowed-table .dashTitle h4 {
  font-size: 22px;
  line-height: 28px;
  color: #131521;
  font-weight: 500;
}

.paymentowed-table .createauction-btn button {
  background: #000000;
  border-radius: 33px;
  color: #ffffff;
  padding: 11px 25px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  font-family: "CircularStd";
  border: none;
  outline: none;
  position: relative;
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
}

.paymentowed-table .table thead th {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 30px;
  color: #9fa2b8;
  position: relative;
  z-index: 10;
  width: 22%;
  min-width: 100%;
  font-family: "CircularStd";
}

.paymentowed-table .table td {
  border-top: 1px solid #f4f5fa;
  padding: 15px 30px;
  vertical-align: middle;
  /* min-width: 170px; */
}

.paymentowed-table .table .property-col {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.paymentowed-table .table .property-col img {
  border-radius: 50%;
  margin-right: 15px;
  width: 55px;
  height: 55px;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(100px);
}

.paymentowed-table .table .property-col h6 {
  font-size: 15px;
  line-height: 21px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 500;
}

.paymentowed-table .table .tag--active,
.paymentowed-table .table .tag--inreview,
.paymentowed-table .table .tag--success,
.paymentowed-table .table .tag--closed {
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #3864e4;
  font-family: "CircularStd";
  font-weight: 500;
  background: rgba(56, 100, 228, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 60px;
  padding: 5px 15px;
}

.paymentowed-table .table .tag--inreview {
  background: rgba(255, 174, 0, 0.15);
  color: #ffae00;
}

.paymentowed-table .table .tag--success {
  background: rgba(83, 182, 73, 0.12);
  color: #53b649;
}

.paymentowed-table .table .tag--closed {
  background: rgba(189, 72, 101, 0.1);
  color: #bd4865;
}

.dash-tbl-col p {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131521;
  font-family: "Circular Std Book";
}

.Dashboard .AuctionPage {
  display: inherit;
}

/* @media only screen and (max-width: 1330px) {
	.paymentowed-table .table {
		display: block;
	}
} */

@media only screen and (max-width: 900px) {
  .paymentowed-page-topbar {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .paymentowed-table .table {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .paymentowed-table .dashTitle h4 {
    font-size: 19px;
    line-height: 25px;
  }

  .paymentowed-table .table {
    display: block;
  }

  .paymentowed-table .table .property-col {
    min-width: 250px;
  }

  .paymentowed-table .table .status-col {
    min-width: 190px;
  }

  .paymentowed-table .table .amount-raising-col,
  .paymentowed-table .table .date-col {
    min-width: 140px;
  }

  .paymentowed-page-topbar h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .paymentowed-page-topbar {
    padding-left: 15px;
  }

  .paymentowed-table .table .property-col h6 {
    font-size: 16px;
    line-height: 23px;
  }

  .paymentowed-table .table .property-col img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .paymentowed-table .table td {
    padding: 15px;
  }
  .paymentowed-table .table thead th {
    padding: 12px 15px;
  }

  .paymentowed-table .dashTitle-bar {
    padding: 30px 15px 10px 15px;
  }
}

@media only screen and (max-width: 480px) {
  .dashTitle-bar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .paymentowed-table .createauction-btn {
    margin-top: 20px;
  }

  .paymentowed-table .createauction-btn,
  .paymentowed-table .createauction-btn button {
    width: 100%;
  }
}
