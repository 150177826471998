.Header {
	position: absolute;
	top: 0;
	width: 100%;
	padding: 40px 30px;
	z-index: 100;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.fullheader {
	padding: 27px 40px;
	top: 0;
}

.FullHeaderSticky,
.HeaderScrolled {
	background: rgba(255, 255, 255);
	height: 80px;
	z-index: 11111 !important;
	position: fixed;
	width: 100%;
	padding: 26px 30px;
	z-index: 100;
	top: 0px;
	transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.headergroup {
	display: grid;
	grid-template-columns: 1fr 3fr;
	max-width: 1110px;
	margin: 0 auto;
	align-items: center;
}

.fullheadergroup {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	max-width: 100%;
	margin: 0 auto;
	align-items: center;
}

.brand img {
	transition: 2s ease-in-out;
	width: 110px;
}

.nav-links {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.nav-btn {
	text-decoration: none !important;
}

.nav-links button {
	text-decoration: none;
	display: block;
	font-size: 14px;
	font-family: "CircularStd";
	font-weight: bold;
	color: rgb(255, 255, 255);
	line-height: 1.2;
	outline: none;
	background: none;
	border: none;
	width: 152px;
	height: 45px;
	background: #000000;
	border-radius: 33px;
	transition: all 0.4s ease-in-out;
}

.nav-links button:hover {
	background: #202020;
	color: #fff;
	box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.3);
	cursor: pointer;
	outline: none;
}

.nav-links2 button:hover {
	background-image: linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	color: #ffffff;
	-webkit-box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.5);
	box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.5);
	cursor: pointer;
	outline: none;
}

.nav-links button:focus {
	outline: none;
}

@media only screen and (max-width: 600px) {
	.nav-links a {
		font-size: 13px;
	}

	.cl-effect a span::before {
		font-size: 12px;
	}

	/* .Header {
		padding: 20px 30px;
	} */

	.fullheader {
		padding: 15px 30px;
	}
}

.HeaderScrolled {
	background: rgba(255, 255, 255, 0.9);
	padding: 17px 30px;
	backdrop-filter: blur(8px);
}

.Header img {
	transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.HeaderScrolled .brand img {
	width: 90px;
}

/* .HeaderScrolled .nav-links button {
	padding: 10px 30px;
	font-size: 12px;
} */

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 900px;
	}
}

.signup-modal {
	max-width: 620px;
}

.modal-content {
	border-radius: 20px;
}

.modal-backdrop {
	z-index: 99;
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}

.modal-header {
	background-color: rgb(225, 227, 230, 0.2);
	height: 87px;
	width: 100%;
	border-radius: 25px 25px 0 0;
	position: relative;
	border: none;
	display: grid;
	grid-gap: 8px;
	padding: 20px 40px;
	grid-template-columns: 1fr;
}

.modal-body {
	padding: 0px;
	padding-bottom: 10px;
}

.modal-body {
	max-width: 900px;
}

.modal-content .modal-body {
	border-radius: 0px;
}

.modal-header-text {
	padding: 16px 20px 0 40px;
}

.modal-header-text h6 {
	font-size: 22px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: bold;
	line-height: 1.4;
}

.modal-header p {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.4;
}

.close {
	position: absolute;
	top: 30px;
	right: 30px;
	border: none;
	background: none;
	outline: none;
}

.close:focus {
	outline: none;
}

button {
	outline: none;
}

.s-close-btn:hover {
	cursor: pointer;
}

.s-close-btn img {
	width: 28px;
	height: 28px;
}

.modal-checkboxes {
	padding: 40px;
}

.modal-checkboxes p {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(41, 51, 53);
	line-height: 1.2;
	font-weight: bold;
	padding-bottom: 10px;
}

.checkboxes-wrapper {
	display: flex;
	justify-content: space-between;
	max-width: 485px;
}

.checkbox-name {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(76, 76, 76);
	line-height: 1.2;
	display: flex;
	align-items: center;
}

.checkbox-name {
	position: relative;
	left: 5px;
}

.custom-control-label::before {
	position: absolute;
	top: 1px !important;
	left: -1.5rem;
	display: block;
	width: 16px;
	height: 16px;
	margin-right: 5px;
	pointer-events: none;
	content: "";
	background-color: #fff;
	border: #adb5bd solid 1px;
}

.custom-control-label::after {
	left: -24px;
	top: 1px !important;
}

.modal-header h5 {
	font-size: 20px;
}

.checkbox-text {
	display: block;
	padding-left: 11px;
}

.signup-inputs li {
	list-style: none;
	display: flex;
	flex-direction: column;
}

.signup-form {
	padding: 0 40px;
}

.signup-inputs {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 30px;
}

.input-label {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(41, 51, 53);
	line-height: 1.2;
	padding-bottom: 0px;
}

::-webkit-input-placeholder {
	color: rgb(183, 188, 192);
	font-weight: 300;
}

:-ms-input-placeholder {
	color: rgb(183, 188, 192);
	font-weight: 300;
}

::-ms-input-placeholder {
	color: rgb(183, 188, 192);
	font-weight: 300;
}

::placeholder {
	color: rgb(183, 188, 192);
	font-weight: 300;
}

input:focus::-webkit-input-placeholder {
	color: transparent;
}

input:focus::-ms-input-placeholder {
	color: transparent;
}

input:focus::-ms-input-placeholder {
	color: transparent;
}

input:focus::placeholder {
	color: transparent;
}

ul.signup-inputs li input {
	border: none;
	outline: none;
	width: 100%;
	height: 44px;
	font-size: 14px;
	color: #202020;
	outline: none;
	background-color: rgb(255, 255, 255);
	border: 1px solid rgb(225, 227, 230);
	border-radius: 4px;
	padding: 0 17px;
	padding-bottom: 2px;
}

ul.signup-inputs li input:focus {
	border: 1px solid #4756f7;
	-webkit-box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.15);
	box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.15);
}

.btn-wrapper {
	padding: 35px 0 40px 0;
	display: flex;
}

.modal-footer {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0;
	padding-top: 20px;
	border: none;
}

.success-msg-body {
	padding: 40px;
	text-align: center;
}

.invitation-img {
	padding-bottom: 40px;
}

.invi-title {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(82, 188, 180);
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	padding-bottom: 25px;
}

.invi-thanku,
.invi-email {
	font-size: 16px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.625;
	text-align: center;
}

.invi-color-bold {
	color: #6539c8;
}

.modal-open .modal {
	background-color: rgba(48, 57, 78, 0.9);
	/* overflow: inherit; */
	/* backdrop-filter: blur(3px); */
}

.signup-btn {
	border: none;
	outline: none;
	background: none;
	border-radius: 4px;
	font-size: 15px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.2;
	background-image: linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	background-image: -moz-linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	background-image: -webkit-linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	background-image: -ms-linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	box-shadow: 0px 6px 25px 0px rgba(45, 10, 124, 0.2);
	width: 150px;
	height: 42px;
	margin: 0 auto;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.signup-btn:hover {
	transform: translateY(-3px);
	-webkit-box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.5);
	box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.5);
	cursor: pointer;
}

@-moz-keyframes boat {
	50% {
		-moz-transform: rotate(-5deg) translateY(-10px);
		transform: rotate(-5deg) translateY(-10px);
	}
}

@-webkit-keyframes boat {
	50% {
		-webkit-transform: rotate(-5deg) translateY(-10px);
		transform: rotate(-5deg) translateY(-10px);
	}
}

@keyframes boat {
	50% {
		-moz-transform: rotate(-5deg) translateY(-10px);
		-ms-transform: rotate(-5deg) translateY(-10px);
		-webkit-transform: rotate(-5deg) translateY(-10px);
		transform: rotate(-5deg) translateY(-10px);
	}
}

.hamburger {
	color: #000;
	position: absolute;
	right: 30px;
	font-size: 16px;
	display: none;
	cursor: pointer;
	z-index: 111111;
}

@media only screen and (max-width: 840px) {
	.modal-main {
		width: 90%;
		margin: 0 auto;
	}

	.hamburger {
		display: inherit;
	}

	.HeaderScrolled {
		height: 60px;
	}

	.Nav-links .number {
		position: relative;
		top: -40px;
	}

	.nav-links {
		position: absolute;
		background: #fff;
		width: 100%;
		height: 310px;
		display: flex;
		flex-direction: column-reverse;
		top: 0;
		left: 0;
		right: 0;
		text-align: center;
		border-bottom: 1px solid #d4d4d4;
		display: none;
		z-index: 99;
	}

	.login-link {
		margin-top: 35px;
	}

	.nav-links.in {
		display: inherit;
	}

	.brand img {
		position: relative;
		z-index: 100;
	}

	.f-slash {
		display: none;
	}

	.Nav-links {
		position: absolute;
		top: 130px;
		left: 30px;
		line-height: 34px;
	}

	.modal-header p {
		max-width: 450px;
		padding-right: 60px;
	}

	.signup-inputs {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 20px;
	}

	.nav-links button {
		font-size: 12px;
		width: 92%;
		position: absolute;
		bottom: 20px;
		font-size: 17px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 580px) {
	.hamburger {
		right: 15px;
	}

	/* .Header {
		padding: 40px 30px !important;
	} */
}

@media only screen and (max-width: 480px) {
	.modal-header p {
		font-size: 12px;
	}

	.Nav-links {
		left: 15px;
		top: 120px;
	}

	.signup-btn {
		width: 100%;
	}

	.close {
		right: 24px;
	}

	/* .nav-links button {
		font-size: 10px;
		font-weight: 700;
	} */

	ul.signup-inputs li input {
		height: 35px;
		font-size: 12px;
	}

	.modal-checkboxes {
		padding: 24px;
	}

	.modal-header {
		padding: 15px 24px;
	}

	.btn-wrapper {
		padding: 30px 0 30px 0;
	}

	.signup-form {
		padding: 0 24px;
	}

	.modal-header-text {
		padding: 16px 20px 0 24px;
	}

	.input-label {
		font-size: 11px;
	}

	.checkbox-name {
		font-size: 12px;
	}

	.s-close-btn {
		right: 24px;
	}

	/* .Header {
		padding: 10px 20px;
	} */

	/* .HeaderScrolled .nav-links button {
		font-size: 10px;
	} */
}

.main-modal-wrapper {
	padding: 10px 10px 0 10px;
}

.main-modal-wrapper iframe {
	width: 900px;
	max-width: 100%;
	height: 500px;
	border: none;
}

@media only screen and (max-width: 670px) {
	.modal-dialog {
		max-width: 100%;
		margin: 0 24px;
	}

	.main-modal-wrapper {
		padding: 30px 30px 0 30px;
	}

	.modal-body {
		padding-bottom: 30px;
	}
}

.CalculatorGroup {
	max-width: 1230px;
	margin: 0 auto;
	margin-top: 195px;
	padding: 0 30px;
}

.Nav-links {
	margin-right: 50px;
}

.Nav-links a,
.Nav-links span {
	color: #5b607d;
	text-decoration: none;
	font-size: 16px;
	font-family: "Circular Std Book";
	transition: all 0.3s ease-in-out;
}

.Nav-links a .bold {
	color: #31354e;
	font-weight: 500;
}

.f-slash {
	margin: 0 20px;
}

.live-link {
	display: inline-block;
	color: #000;
	text-decoration: none;
}

.live-link::after {
	content: "";
	display: block;
	width: 0;
	height: 1px;
	background: #000;
	transition: width 0.3s;
}

.live-link:hover::after {
	width: 100%;
}

@media only screen and (max-width: 520px) {
	/* .Nav-links {
		margin-right: 10px;
	}

	.Nav-links a {
		margin: 0 10px;
	} */

	.FullHeaderSticky {
		height: 70px;
		padding: 10px 30px;
	}
}

.signup-modal .modal-content {
	padding-bottom: 20px;
}

@media only screen and (max-width: 992px) {
	.Nav-links {
		margin-right: 30px;
	}
}
