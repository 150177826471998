.accerdition-content h5 {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 16px;
}

.accreditation-status {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5b607d;
  font-family: "CircularStd";
}

.accreditation-status .status {
  background: rgba(75, 181, 67, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 60px;
  color: #4bb543;
  padding: 6px 16px;
}

.accreditation-status .verified {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50px !important;
  background-color: #4bb543 !important;
  margin-right: 10px;
}

.accreditation-status .status-loader {
  background: rgba(189, 72, 101, 0.1);
  backdrop-filter: blur(5px);
  border-radius: 60px;
  padding: 6px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #bd4865;
  font-family: "CircularStd";
}

.status {
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.not_verified {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50px !important;
  background-color: red !important;
  margin-right: 7px;
}
.verified {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50px !important;
  background-color: green !important;
  margin-right: 7px;
}

.main-upload {
  border: 3px solid #000;
  border-style: dashed;
  height: 200px;
  width: 400px;
  border-radius: 15px;
}

.border-tbl {
  border: 3px solid black;
  padding: 5px;
}

.accreditation-DashContent {
  padding-top: 24px;
}

.accreditation-para {
  background: #f6f8fa;
  border-radius: 10px;
  padding: 34px 28px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.accreditation-para p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #31354e;
  font-family: "Circular Std Book";
}

.accreditation-para a {
  color: #3864e4;
  text-decoration: underline !important;
}

.separator {
  border-color: #f0f1f6;
  border-width: 1px;
  margin: 32px 0;
}

.document-upload,
.accreditation-table-wrapper,
.done-uploading-wrapper {
  margin-top: 48px;
}

.document-upload h4,
.accreditation-table-wrapper h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #31354e;
  font-family: "CircularStd";
  padding-bottom: 24px;
}

.main-upload {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(19, 21, 33, 0.15);
  border-radius: 10px;
  min-height: 190px;
}

.drag-file-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.drag-file-text h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #5b607d;
  font-family: "Circular Std Book";
}

.drag-file-text button {
  background: transparent;
  border: 0;
  padding: 0;
  color: #3864e4;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-family: "Circular Std Book";
  text-decoration: underline;
}

.drag-file-text button:hover {
  background-color: transparent;
  background: transparent;
  color: #072b95;
}

.uploading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 190px;
}

.accreditation-table-data {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid rgba(19, 21, 33, 0.15);
}

.accreditation-table-data .border-tbl {
  min-width: 200px;
  width: 100%;
  background: #ffffff;
  border: 0px;
  overflow: auto;
  padding: 0;
}

.accreditation-table-data table {
  display: block;
}

.accreditation-table-data .border-tbl th {
  background: rgba(56, 100, 228, 0.05);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #9fa2b8;
  font-family: "CircularStd";
  padding: 20px 30px;
}

.accreditation-table-data .border-tbl tr {
  border-bottom: 1px solid rgba(19, 21, 33, 0.15);
}

.accreditation-table-data .border-tbl tr:last-child {
  border-bottom: 0px;
}

.accreditation-table-data .border-tbl td,
.accreditation-table-data .file-name a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #131521;
  font-family: "Circular Std Book";
  padding: 24px 30px;
}

.accreditation-table-data .file-name {
  display: flex;
  align-items: center;
  gap: 6px;
}

.accreditation-table-data .file-name a {
  padding: 0;
}

.done-uploading-wrapper h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #31354e;
  font-family: "Circular Std Book";
}

.done-uploading-wrapper button {
  background: #3864e4;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-family: "CircularStd";
  border: 0;
  padding: 12px 32px;
}
