main {
    display: flex !important;
    justify-content:center !important;
    align-items: center !important;
}

.box{
    max-width:600px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.dropdown-item{
    font-weight: 800;
}
.dropdown-item:hover{
    background-color: black;
    color: white;
    font-weight: 800;
}