.form-wrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - 10vh);
	padding: 8vh 24px;
	background-position: center center;
	position: relative;
	z-index: 11;
	overflow: hidden;
	background: #fef9f5;
	-webkit-backdrop-filter: blur(100px);
	backdrop-filter: blur(100px);
}

.form-wrapper::before {
	content: "";
	position: absolute;
	background-image: url(/static/media/line-equity-sec.67e15412.svg);
	background-repeat: no-repeat;
	top: -150px;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
	z-index: -1;
}

.form-wrapper::after {
	content: "";
	position: absolute;
	background: rgba(254, 249, 245, 0.85);
	/* background-image: url(../Images/signin-bg.svg); */
	background-repeat: no-repeat;
	-webkit-backdrop-filter: blur(100px);
	backdrop-filter: blur(100px);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: -2;
	opacity: 0.08;
}

.form-wrapper .brand {
	margin-bottom: 50px;
}

.form-box {
	background: #ffffff;
	max-width: 420px;
	width: 100%;
	padding: 45px;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
}

.signup-form-box {
	max-width: 640px;
	width: 100%;
}

.form-text-wrapper {
	text-align: center;
	padding-bottom: 35px;
}

.form-text-wrapper h1 {
	font-style: normal;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
	padding-bottom: 15px;
}

.form-text-wrapper p {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: #5b607d;
	font-family: "Circular Std Book";
}

.form-box .form-group {
	margin-bottom: 35px;
}

.signup-double-input {
	display: flex;
	justify-content: space-between;
}

.signup-double-input .form-group {
	flex-basis: 50%;
}

.signup-double-input .form-group:first-child {
	margin-right: 30px;
}

.form-box .form-group label {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
	margin-bottom: 12px;
}

.form-box .form-group input {
	border: 1px solid #dee0ec;
	box-sizing: border-box;
	border-radius: 70px;
	padding: 10px 25px;
	min-height: 50px;
	width: 100%;
	font-size: 15px;
	color: #131521;
	font-family: "Circular Std Book";
	vertical-align: middle;
	display: inline;
}

.form-box .form-group input::placeholder {
	font-size: 15px;
	line-height: 19px;
	color: #9fa2b8;
	vertical-align: middle;
	display: inline;
	font-family: "Circular Std Book";
}

.form-box .form-group input:focus {
	border: 1px solid #8ea8f3;
	box-shadow: 0 0 0 3px rgba(56, 99, 228, 0.15);
}

.form-box .is-invalid:focus {
	border: 1px solid #bd4865 !important;
	box-shadow: 0 0 0 3px rgba(189, 72, 101, 0.15) !important;
}

.form-box .invalid-feedback {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #bd4865;
	font-family: "Circular Std Book";
	margin-top: 8px;
}

.form-box .blue-btn {
	text-decoration: none;
	display: block;
	font-size: 15px;
	font-family: "CircularStd";
	font-weight: bold;
	color: rgb(255, 255, 255);
	line-height: 1.2;
	outline: none;
	background: none;
	border: none;
	width: 100%;
	height: 50px;
	background: #3864e4;
	border-radius: 70px;
	margin-right: 22px;
	transition: all 0.4s ease-in-out;
}

.form-box .blue-btn:hover {
	background: #204ccf;
	color: #fff;
	box-shadow: 0px 10px 30px 0px rgba(74, 62, 243, 0.3);
	cursor: pointer;
	outline: none;
}

.form-box .blue-btn:active,
.form-box .blue-btn:focus {
	background: #1c4ee6;
}

.form-box .form-group .custom-control-label {
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	color: #5b607d;
	font-family: "Circular Std Book";
	margin: 0px !important;
	padding-left: 4px;
}

.form-box .form-group .custom-control-label:hover {
	cursor: pointer;
}

.form-box .pass-input,
.form-box .remember-checkbox {
	margin-bottom: 30px;
}

.form-box .pass-input .password-labels {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form-box .account-creation-link p {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #5b607d;
	font-family: "Circular Std Book";
}

.form-box .account-creation-link a,
.form-box .pass-input .password-labels .forgot-pass-link a {
	color: #3864e4;
}

.form-box .pass-input .password-labels .forgot-pass-link a {
	font-size: 12px;
	font-family: "Circular Std Book";
}

.inline-feeback {
	display: flex;
	background: #f4f7fd;
	border-radius: 100px;
	justify-content: flex-start;
	align-items: center;
	padding: 12px 25px;
	margin-top: 20px;
}

.inline-feeback p {
	text-align: left;
	margin-left: 15px;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	font-family: "Circular Std Book";
	color: #1c57d7;
}

.copyrights-text {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #9fa2b8;
	font-family: "Circular Std Book";
	margin-top: 60px;
}

.property-card-signup-wrapper {
	max-width: 640px;
	margin: 0 auto;
	background: white;
	padding: 30px;
	border-bottom: 1px solid #dee0ec;
	display: flex;
}

.property-card-signup-wrapper p {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #131521;
	font-family: "Circular Std Book";
}

@media screen and (max-width: 767px) {
	.form-wrapper .container {
		max-width: 100%;
		padding: 0;
	}
}

@media screen and (max-width: 580px) {
	.signup-double-input {
		flex-direction: column;
	}

	.signup-double-input .form-group {
		flex-basis: inherit;
		flex-direction: column;
	}

	.signup-double-input .form-group:first-child {
		margin-right: 0px;
	}

	.property-card-signup-wrapper {
		padding: 30px 20px;
	}

	.property-card-signup-wrapper .col-sm-5,
	.property-card-signup-wrapper .col-sm-7 {
		padding: 0;
	}

	.property-card-signup-wrapper p {
		margin-top: 25px;
	}
}

@media screen and (max-width: 480px) {
	.form-box {
		padding: 36px 20px;
	}

	.form-wrapper {
		padding: 80px 16px;
	}
}
