.introjs-tooltipReferenceLayer * {
	font-family: "Circular Std Book", "CircularStd";
}

.introjs-overlay {
	/* backdrop-filter: blur(5px); */
	opacity: 1 !important;
	background-color: rgba(48, 57, 78, 0.9);
}

.introjs-tooltip {
	max-width: 365px !important;
	width: 365px !important;
	min-width: 200px !important;
	display: flex;
	justify-content: center;
	background: #ffffff !important;
	border-radius: 18px !important;
	left: 0 !important;
}

.introjs-arrow.right-bottom {
	bottom: 23px !important;
}

.introjs-tooltip .introjs-tooltiptext {
	padding: 28px 28px 20px 28px;
}

.introjs-tooltip .introjs-tooltipbuttons {
	padding: 16px 28px;
	border-radius: 0 0 18px 18px;
	background: rgba(237, 237, 237, 0.4);
	display: flex;
	justify-content: flex-end;
}

.introjs-tooltip .introjs-tooltip-header {
	position: absolute;
	bottom: 20px;
}

.introjs-tooltip .introjs-skipbutton {
	opacity: 1;
	position: relative;
	z-index: 1;
}

.introjs-tooltip .introjs-skipbutton::after {
	content: "Exit";
	position: absolute;
	top: 0;
	left: 0;
	font-size: 16px;
	color: #3864e4;
	opacity: 1 !important;
	z-index: 1111;
	background: #f8f8f8;
	padding: 10px;
	font-size: 14px;
	line-height: 18px;
	font-family: "Circular Std Book";
}

.introjs-tooltip .introjs-skipbutton:hover::after {
	color: #244dc7;
}

div.introjs-arrow.left-bottom {
	bottom: 25px !important;
}

div.introjs-arrow.bottom,
div.introjs-arrow.top {
	left: 25px !important;
}

.introjs-tooltip .introjs-button {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 60px;
	min-width: 98px;
	font-style: normal;
	font-weight: bold;
	padding: 9px 0;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #000000;
	text-shadow: none;
	font-family: "CircularStd";
	transition: all 0.3s ease-in-out;
}

.introjs-tooltip .introjs-button:focus {
	box-shadow: inherit;
	outline: none;
}

.introjs-tooltip .introjs-nextbutton {
	background: #3864e4;
	color: #fff;
	margin-left: 16px;
}

.introjs-tooltip .introjs-button:hover {
	background: #244dc7;
	color: #ffffff;
	border: 1px solid #244dc7;
}

.introjs-tooltip h6 {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #131521;
	font-family: "CircularStd";
}

.introjs-tooltip p {
	font-style: normal;
	font-weight: 450;
	font-size: 15px;
	line-height: 23px;
	padding-top: 12px !important;
	font-family: "Circular Std Book";
}

.introjs-tooltip .introjs-bullets {
	padding-top: 0;
	padding-bottom: 20px;
}

.introjs-tooltip .introjs-bullets ul li a {
	background: rgba(0, 0, 0, 0.32);
}

.introjs-tooltip .introjs-bullets ul li a.active {
	width: 16px;
	background: #7195fc;
}

.introjs-tooltip.introjs-bullets ul li a:focus,
.introjs-tooltip .introjs-bullets ul li a:hover {
	background: #7195fc;
}

@media only screen and (min-width: 800) {
	.introjs-tooltip {
		max-width: 200px !important;
		width: 200px !important;
		min-width: 200px !important;
	}
}

@media (max-width: 480px) {
	.introjs-tooltip {
		max-width: 100% !important;
		width: 100% !important;
	}
}