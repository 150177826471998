.equity-graph,
.eit-2 {
	text-align: center;
	justify-content: center !important;
}

.equity-graph-sec {
	padding: 0 30px;
	position: relative;
	z-index: 10;
	/* overflow: hidden; */
}
.graph-slider.dstart {
	overflow: hidden;
}

/* .equity-graph-sec::after {
	content: "";
	position: absolute;
	background-image: url(../Images/line-equity-sec.svg);
	background-repeat: no-repeat;
	top: -630px;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
	z-index: -1;
} */

.equity-graph-wrapper {
	max-width: 1110px;
	margin: 0 auto;
}

.equity-graph-wrapper .linegraph-heading {
	margin-top: 50px;
	text-align: left;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #131521;
	margin-bottom: 20px;
}

.equity-graph-wrapper .exuity-para {
	max-width: 650px;
	font-style: normal;
	font-weight: 450;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	color: #4c506b;
	font-family: "Circular Std Book";
	margin: 0 auto;
	padding: 0 30px;
	padding-bottom: 18px;
	padding-top: 24px;
}

.equity-graph-wrapper .nav {
	justify-content: center;
	border: 1px solid rgba(236, 237, 243, 0.55);
	border-radius: 60px;
	background: rgba(236, 237, 243, 0.55);
	width: 278px;
	margin: 0 auto;
	margin-top: 30px;
	overflow: hidden;
}

.equity-graph-wrapper .line-graph {
	margin-top: 0;
	margin-bottom: 60px;
	height: 550px;
}

.section-title-2 {
	margin-top: 100px;
}

.equity-graph-wrapper .nav-tabs .nav-item {
	margin: 0;
}

.equity-graph-wrapper h3 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 38px;
	letter-spacing: 0.5px;
	color: #131521;
}

.equity-graph-wrapper p {
	color: #5b607d;
	font-style: normal;
	font-family: "Circular Std Book";
	font-weight: normal;
	font-size: 18px;
	margin-top: 30px;
	line-height: 33px;
}

.equity-graph-wrapper .nav-tabs .nav-link {
	border: none;
	border-radius: none;
	padding: 0;
	margin: 0;
	background: none;
	text-align: center;
	color: #4c506b;
	font-family: "CircularStd";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	width: 138px;
	/* background: rgba(236, 237, 243, 0.55); */
	padding: 12px 0;
	border-radius: 60px;
	text-transform: capitalize;
}

.equity-graph-wrapper .nav-tabs .nav-item.show .nav-link,
.equity-graph-wrapper .nav-tabs .nav-link.active {
	border: none;
	background: #ffffff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	border-radius: 60px;
	color: #3864e4;
	padding: 12px 0;
}

.graph-slider {
	/* display: flex;
	gap: 30px; */
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
}

.disabled-slider {
	opacity: 0.5;
}

.equity-graph-wrapper .nav-tabs .nav-link:focus,
.equity-graph-wrapper .nav-tabs .nav-link:hover {
	border: none;
	cursor: pointer;
}

.equity-graph-sec h5 {
	margin-bottom: 28px;
}

.equity-graph-sec p {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #5b607d;
}

.nav-tabs {
	position: relative !important;
	top: 0 !important;
	z-index: 1 !important;
}

.cash-tab,
.equity-tab {
	max-width: 1110px;
	margin: 0 auto;
}

.equity-input {
	min-width: 30%;
	padding: 20px 25px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.07);
	box-sizing: border-box;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.equity-graph-wrapper h6 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #131521;
	margin-top: 44px;
	margin-bottom: 32px;
}

.equity-input-text {
	display: flex;
	grid-gap: 40px;
	justify-content: space-between !important;
	align-items: center !important;
	/* margin-bottom: 15px; */
}

.equity-text {
	width: 12rem;
}

.sliders-data {
	display: flex;
	align-items: center;
	gap: 10px;
	flex: none;
}

.equity-input-text .slider-tooltip {
	background: rgba(56, 100, 228, 0.1);
	border-radius: 5px;
	color: #cfcac4;
	font-size: 12px;
	padding: 1px 0.542em;
	max-width: 300px;
}

.slider-chart-value {
	background: rgba(56, 100, 228, 0.1);
	border-radius: 5px;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 13px;
	text-align: right;
	color: #4c506b;
	padding: 3px 8px;
	font-family: "CircularStd";
	/* width: 80px; */
}

.equity-input-text .slider-tooltip:hover {
	cursor: pointer;
}

.equity-input-text h6 {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	font-family: "Circular Std Book";
	color: #5b607d;
	margin: 0;
	text-align: left;
}

.equity-input-text span {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #878787;
	font-family: "Circular Std Book";
}

.equity-input .input-range__label--value {
	top: 0.8rem !important;
}

.equity-input .input-range__label--value span {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	font-family: "CircularStd";
	font-weight: 500;
	color: #e3e8ee !important;
	background: #3c4257;
	border-radius: 4px;
	left: -42% !important;
	padding: 3px 7px;
}

.equity-input .input-range__label-container {
	left: 0 !important;
}

.equity-input .input-range__track {
	background: #ecedf3 !important;
	border-radius: 20px;
}

.equity-input .input-range__track--active {
	background: linear-gradient(
		270.02deg,
		#7195fc 30.31%,
		#3864e4 256.26%
	) !important;
}

.equity-input .input-range__slider {
	border: 1px solid #7195fc !important;
	width: 20px !important;
	height: 20px !important;
	box-shadow: 0px 2px 5px rgba(151, 150, 234, 0.2),
		0px 1px 1px rgba(151, 150, 234, 0.12) !important;
}

.glowing-btn {
	background: #ffffff;
	box-shadow: 0px 4px 16px rgba(110, 146, 250, 0.17);
	border-radius: 10px;
	width: 160px;
	height: 50px;
	border: none;
	outline: none;
	cursor: pointer;
	position: relative;
	z-index: 0;
	font-family: "CircularStd";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #3864e4;
	border: 1px solid transparent;
	background: linear-gradient(to right, #fff, #fff),
		linear-gradient(to right, #38d2e4, #3864e4);
	background-clip: padding-box, border-box;
	background-origin: padding-box, border-box;
	transition: all 0.3s ease-in-out;
	margin-top: 30px !important;
}

.glowing-btn:hover {
	color: #fff;
	background: linear-gradient(to right, #38d2e4, #3864e4);
	border: transparent;
	box-shadow: 0px 4px 16px rgba(93, 127, 228, 0.17);
}

/* .glowing-btn {
	width: 160px;
	height: 50px;
	border: none;
	outline: none;
	background: #000;
	cursor: pointer;
	position: relative;
	z-index: 0;
	border-radius: 33px;
	font-size: 16px;
	font-family: "CircularStd";
	font-weight: bold;
	color: rgb(255, 255, 255);
	line-height: 1.3;
}

.glowing-btn:before {
	content: "";
	background: linear-gradient(
		45deg,
		#ff0000,
		#ff7300,
		#fffb00,
		#48ff00,
		#00ffd5,
		#002bff,
		#7a00ff,
		#ff00c8,
		#ff0000
	);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	z-index: -1;
	filter: blur(5px);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	animation: glowing 20s linear infinite;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
	border-radius: 10px;
}

.glowing-btn:active {
	color: #000;
}

.glowing-btn:active:after {
	background: transparent;
}

.glowing-btn:after {
	z-index: -1;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background: #111;
	left: 0;
	top: 0;
	border-radius: 10px;
}

@keyframes glowing {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
} */

.equity-input .input-range__slider {
	margin-top: -12px !important;
}

.graph-wrapper {
	display: flex;
	position: relative;
}

.graph {
	flex-grow: 1;
	overflow: auto;
}

.graphheight {
	height: 540px;
	margin: 0 auto;
}

.loan-table,
.rent-table {
	border: 1px solid rgba(0, 0, 0, 0.1);
	text-align: left;
	background: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
	border-radius: 10px;
	overflow: hidden;
}

.graph-table {
	text-align: left;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.07);
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}

.graph-table thead th {
	border-bottom: 1px solid #e6e6e6;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	color: #5b607d;
	font-family: "CircularStd";
	background: rgba(56, 99, 228, 0.05);
	font-weight: bold;
	padding: 16px;
	min-height: 64px;
	text-align: center;
	vertical-align: middle;
	border-right: 1px solid #e6e6e6;
}

.graph-table td {
	/* background: white; */
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	color: #31354e;
	height: 60px;
	vertical-align: middle;
	text-align: center;
	/* border-bottom: 1px dashed #e6e6e6; */
	font-family: "Circular Std Book";
}

.graph-table thead th:last-child {
	border-right: 0;
}

.graph-table tbody tr {
	color: #fff;
	border-bottom: 1px dashed #e6e6e6;
}

.graph-table tbody tr:last-child {
	border-bottom: 0;
}

.graph-table tbody tr:nth-child(even) {
	background: rgba(245, 247, 254, 0.4);
}

.graph-table tbody tr:hover {
	background-color: inherit;
	cursor: inherit;
}

.graph-status-tag {
	background: rgba(83, 182, 73, 0.12) !important;
	backdrop-filter: blur(10px) !important;
	border-radius: 60px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	color: #53b649 !important;
	height: 23px;
	width: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.graph-sold-status-tag {
	background: rgba(182, 73, 73, 0.12) !important;
	backdrop-filter: blur(10px) !important;
	border-radius: 60px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	color: #b64949 !important;
	height: 23px;
	width: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.equity-graph .quity-title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 37px;
	text-align: center;
	color: #131521;
	/* padding-bottom: 24px; */
}

.modal-equity {
	margin-top: 100px;
}

@media only screen and (max-width: 992px) {
	.graph img {
		width: 100%;
	}

	.graph-slider {
		/* grid-template-columns: repeat(2, 50%); */
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-gap: 30px;
		min-height: 100px;
		/* overflow-y: hidden; */
		padding: 0 30px;
		/* border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-shadow: inset -8px 0px 8px -8px rgba(0, 0, 0, 0.2),
			inset 8px 0px 8px -8px rgba(105, 78, 78, 0.2); */
	}

	/* .equity-graph-wrapper h6 {
		margin-bottom: 10px;
	} */

	.graph-slider::-webkit-scrollbar {
		display: none;
		width: 0px;
		background: transparent;
	}

	.graph-slider {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

@media only screen and (max-width: 620px) {
	/* .graph-slider {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 20px;
	} */
}

@media only screen and (max-width: 580px) {
	.section-title h5 {
		font-size: 25px;
		line-height: 34px;
	}

	.Sold-btn .blue-btn {
		width: 90%;
		margin: 0 auto;
	}

	.graph-slider {
		grid-template-columns: repeat(1, auto);
		grid-gap: 15px;
	}

	.section-title-2 {
		margin-top: 60px;
	}

	.equity-graph-sec {
		padding: 0 15px;
	}

	.graph-slider {
		padding: 0 15px;
	}

	.graph-slider .equity-input {
		padding: 15px !important;
	}

	.equity-graph-wrapper .nav-tabs .nav-link {
		width: 100px;
		font-size: 12px;
		line-height: 18px;
	}

	.equity-graph-wrapper .nav {
		max-width: 202px;
	}

	.requirement .nav {
		max-width: 303px !important;
	}
}

@media only screen and (max-width: 480px) {
	.graph-wrapper {
		margin-top: 30px;
	}

	.equity-input {
		padding: 20px;
	}

	.equity-graph-wrapper .exuity-para {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (max-width: 430px) {
}

@media only screen and (max-width: 380px) {
	.graph-wrapper {
		margin-top: 40px;
	}
}

.canvasjs-chart-credit {
	display: none;
}

/*  */

/* STEPS STYLING */
/* @media(max-width:992px){
	.graph-slider {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.graph-slider .inner_div{
		width:90%;
	}
	.graph-slider .inner_div:last-child{
		margin-bottom: 20px;
	}
	.introjs-tooltip *{
		font-size: 14px;
	}

} */

.introjs-tooltip {
	margin-left: 0 !important;
}

.equity-graph .graph-slider .input-range__label-container {
	display: none;
}

.step_tabs {
	display: inline-flex;
	align-items: center;
	border-radius: 30px;
	border: 1px solid rgba(0, 0, 0, 0.219);
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	margin: 12px 0;
}
.step_tabs span {
	padding: 6px 20px;
	font-size: 14px;
	cursor: pointer;
	letter-spacing: 1px;
	text-transform: capitalize;
	transition: background-color 0.25s;
	font-weight: 500;
}
.step_tabs span.active {
	background-color: rgba(29, 29, 29, 0.507);

	color: white;
}

.main_equity h3 {
	font-size: 28px;
	line-height: 20px;
	font-family: "Circular Std Book";
	letter-spacing: 0.5px;
	font-style: normal;
	font-weight: 450;
	font-size: 20px;
	line-height: 24px;
	color: #131521;
}

.main_equity p {
	color: #5b607d;
	font-style: normal;
	font-family: "Circular Std Book";
	font-weight: normal;
	font-size: 18px;
	margin-top: 30px;
	line-height: 33px;
}

.output-sub-text {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	font-family: "Circular Std Book";
	color: #5b607d;
	margin: 0;
	text-align: left;
}

.equity-graph .row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 30px;
}

.equity-graph .row > * {
	padding: 0;
}

.InputBoxesTitle h4 {
	font-size: 15px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: 600;
	line-height: 1.2;
	padding-bottom: 0px;
	margin-top: 24px !important;
}

.introjs-helperLayer {
	box-shadow: none !important;
	border-radius: 0;
}

@media only screen and (max-width: 1200px) {
	.equity-graph .table-responsive-lg,
	.equity-graph .chart-container,
	.graph-slider {
		padding: 0 30px;
	}

	.financial-table .InputBoxesTitle,
	.slider-title .InputBoxesTitle {
		padding-left: 30px;
	}
}

@media only screen and (max-width: 580px) {
	.equity-graph .table-responsive-lg,
	.equity-graph .chart-container,
	.graph-slider {
		padding: 0 15px;
	}

	.equity-graph .row {
		gap: 15px;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}

	.equity-graph .equity-input {
		padding: 20px 25px !important;
	}

	.financial-table .InputBoxesTitle,
	.slider-title .InputBoxesTitle {
		padding-left: 15px;
	}
}
