.add_property_tabs .nav-tabs{
    align-items: center;
    display: inline-flex;
    margin-bottom: 8px;
    overflow: hidden;
}
.add_property_tabs .nav-tabs{
    border:1px solid rgba(0, 0, 0, 0.21);
    border-radius:8px;
}
.add_property_tabs .nav-tabs li{
    margin-top: -1px;
}
.add_property_tabs .nav-tabs li a{
    padding:16px 12px;
    margin-right: 0;
    border-right:1px solid rgba(0, 0, 0, 0.21);
    color:#222;
    cursor: pointer;
    letter-spacing: 0.5px;
    text-transform: capitalize ;
    font-weight: 500;
    border-bottom: 0px;
}
.add_property_tabs .nav-tabs li a:hover{
    border-bottom: 0px;
}
.add_property_tabs .nav-tabs li a.active{
    border:0;
    padding:16px 12px;
    background-color: rgb(51 136 223);
    color:white;
}
.add_property_tabs .nav-tabs li:last-child a{
    border-right: 0;
}

.search-dropdowns {
	-webkit-box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
	-moz-box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
	box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
	background: #ffffff;
	border-radius: 10px;
	padding-right: 10px;
}

.search-loading-state {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 15px 24px;
	width: 100%;
	margin-top: 5px;
}

.search-loading-state .search-loading-text {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: #373f41;
	font-family: "Circular Std Book";
}

.search-loading-state .spinning-loader img {
	width: 40px;
}