@import url("https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
	margin: 0;
	padding: 0;
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
