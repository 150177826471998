.calculation-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1111111111111;
	width: 100vw;
	height: 100vh;
	background-color: rgba(48, 57, 78, 0.9);
	transition: all 0.4s ease-in-out;
	/* backdrop-filter: blur(3px); */
}

.calculation-modal-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1111111111111111111;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all 0.4s ease-in-out;
}

.financial-table .cal-link,
.blue-text-link {
	color: #3864e4;
}

.financial-table .cal-link:hover,
.blue-text-link:hover {
	cursor: pointer;
	color: #0e36af;
}

.calculation-modal {
	z-index: 100;
	background: #ffffff;
	border-radius: 10px;
	position: relative;
	margin: 0 15px;
	max-width: 561px;
	padding: 40px 40px 24px 40px;
	transition: all 0.4s ease-in-out;
}

.calculation-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.calculation-modal-header .modal-title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #131521;
}

.calculation-modal-box {
	background: #f7f7fa;
	border-radius: 8px;
	padding: 16px 24px;
	margin: 24px 0;
}

.calculation-modal-box ul li {
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 28px;
	color: #131521;
	font-family: "CircularStd";
	position: relative;
	margin-left: 18px;
}

.calculation-modal-box ul li::after {
	content: "\003D";
	position: absolute;
	top: 0;
	left: -18px;
	color: #4c506b;
}

.calculation-modal-box ul li a,
.calculation-modal-box ul li span.cal-modal-link {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	text-decoration: underline !important;
	color: #3864e4;
	font-family: "Circular Std Book";
	transition: all 0.3s ease-in-out;
}

.calculation-modal-box ul li a:hover {
	color: #0e36af;
}

.calculation-modal-box .cal-equal,
.calculation-modal-box .cal-multiply {
	color: #4c506b;
	margin-right: 12px;
}

.calculation-modal-box .cal-multiply {
	margin-left: 12px;
}

.calculation-modal-body p {
	font-family: "Circular Std Book";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #4c506b;
}

.calculation-modal-header .modal-close-button {
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	background: rgba(74, 78, 102, 0.12);
	cursor: pointer;
	border: none;
	color: #4a4e66;
	border-radius: 100%;
	width: 20px;
	height: 20px;
}

@media only screen and (max-width: 580px) {
	.calculation-modal {
		padding: 40px 18px 24px 18px;
	}

	.calculation-modal-box {
		padding: 18px;
	}
}
