.search-header {
  background: rgba(255, 255, 255, 0.8);
  height: 90px;
  z-index: 9;
}

.searchPage {
  background-color: #f6f7fb;
}

.searchPageGroup {
  /* max-width: 1240px; */
  margin: 0 auto;
  /* margin-top: 130px; */
  position: relative;
  padding: 0 0 0 30px;
}

.dropDownGroup .wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 20px;
  padding: 0 66px;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
}

.dd-wrapper {
  width: 180px !important;
}

.dd-wrapper .dd-header-title {
  margin: 5px 20px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-family: Montserrat !important;
  color: #293335 !important;
  font-weight: 500 !important;
  text-align: left;
}

.dd-wrapper .dd-header span {
  position: relative !important;
  color: #acadb3 !important;
}

.dd-wrapper .dd-list-item {
  font-weight: 400 !important;
  cursor: pointer !important;
}

.dd-list-item:hover {
  color: #fff;
  background-color: #8d56da !important;
}

.fa-angle-down:before {
  content: "" !important;
  background: url(../../Images/filter-arrow.png);
  background-repeat: no-repeat;
  position: absolute;
  top: -5px;
  width: 6px;
  height: 10px;
}

.fa-angle-up:before {
  content: "" !important;
  background: url(../../Images/filter-arrow.png);
  background-repeat: no-repeat;
  position: absolute;
  top: -5px;
  width: 6px;
  height: 10px;
}

.dd-wrapper .dd-header {
  border-radius: 5px !important;
}

.dropDownGroup::-webkit-scrollbar {
  display: none;
}

.modal-backdrop {
  z-index: inherit;
}

.dropdown-toggle {
  position: relative;
}

.searchpageContent {
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-gap: 20px;
  position: relative;
}

.searchCardGroup {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
  width: 510px;
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  overflow: hidden;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.search-cards-component-group a {
  text-decoration: none;
  margin-bottom: 20px;
}

.search-cards-component-group a:last-child {
  margin-bottom: 0px;
}

.search-cards-component-group {
  display: flex;
  flex-direction: column;
}

.searchCardGroup:hover {
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.08);
  overflow: visible;
}

.searchCardGroup:hover .s-card-img img {
  transform: scale(1.1);
  overflow: hidden;
}

.search-map iframe {
  width: 100%;
  height: 700px;
  height: 82vh;
  position: sticky;
  top: 65px;
  margin-top: 30px;
  z-index: 1;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
}

.footer-section {
  position: relative;
  z-index: 10;
}

.dd-wrapper .dd-header-title {
  margin-right: 0px !important;
}

.searchcard-sec {
  margin-top: 30px;
  justify-self: end;
}

.searchCardGroup:last-child {
  margin-bottom: 0px;
}

.s-card-img img {
  max-width: 180px;
  min-height: 159px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.s-card-img img:hover {
  transform: scale(1.1);
  overflow: hidden;
}

.s-card-img {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
}

.price-tag {
  position: absolute;
  left: 0;
  bottom: 15px;
  background-color: rgb(135, 107, 252, 0.7);
  font-size: 10px;
  font-family: "Montserrat";
  color: rgba(255, 255, 255);
  font-weight: bold;
  padding: 4px 14px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.s-cta {
  position: relative;
}

.s-card-texts {
  padding-left: 24px;
}

.s-card-texts h6 {
  font-size: 16px;
  font-family: "Montserrat";
  color: rgb(41, 51, 53);
  line-height: 1.2;
  padding-bottom: 12px;
}

.s-card-texts p {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(131, 133, 135);
  line-height: 1.2;
  padding-bottom: 10px;
}

.color-bold {
  font-weight: 500;
  color: #3fdbb1;
}

.bold {
  font-weight: 500;
  color: rgb(41, 51, 53);
}

.active-issuance {
  margin-bottom: 10px;
  position: absolute;
  top: 20px;
  left: -32px;
  margin-top: 10px;
  transform: rotate(-45deg);
}

.active-issuance span {
  font-size: 10px;
  font-family: "Montserrat";
  color: #fff;
  line-height: 1.2;
  background-color: rgb(248, 122, 60);
  padding: 4px 30px;
}

.dropdown-sec {
  background-color: #fff;
  padding: 40px 0;
  z-index: 99;
}

.s-cta {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(131, 133, 135);
  line-height: 1.2;
  margin-top: 10px;
  position: relative;
  left: 28px;
}

.s-cards-icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.beds-grey::after {
  content: "";
  position: absolute;
  background-image: url(../../Images/beds-grey.png);
  width: 19px;
  height: 14px;
  left: -28px;
  margin-top: 2px;
}

.baths-grey::after {
  content: "";
  position: absolute;
  background-image: url(../../Images/baths-grey.png);
  width: 21px;
  height: 14px;
  left: -28px;
  margin-top: 0px;
}

.sqft-grey::after {
  content: "";
  position: absolute;
  background-image: url(../../Images/sqft-grey.png);
  width: 17px;
  height: 17px;
  left: -28px;
  margin-top: 0px;
}

.switch-btn {
  display: flex;
  align-items: center;
}

.switch-text {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(131, 133, 135);
  line-height: 1.2;
  position: relative;
  /* top: -2px; */
  left: 11px;
}

.map-list-toggle {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 1111111;
}

.map-list-toggle:hover {
  cursor: pointer;
}

.property-marker {
  background: transparent;
  border: none;
}

.property-marker .property-marker {
  background: url('../../Images/map-marker.svg');
  transition: transform .2s;
}

.propertyhovered .searchCardGroup {
  display: block;
}

.propertynothovered .searchCardGroup {
  display: none;
}

.markerhovered .property-marker img {
  transform: scale(1.2);
}

.cluster-marker {
  border: 2px solid yellow;
  background: #fff;
  border-radius: 90px;
  width: 25px;
  height: 25px;
  text-align: center;
}

.propertynothovered .cluster-marker,
.markerhovered .cluster-marker {
  border-color: red;
}

@media only screen and (max-width: 1199px) {
  .searchpageContent {
    display: grid;
    grid-template-columns: 500px 1fr;
    grid-gap: 20px;
    position: relative;
  }

  .dropDownGroup .wrapper {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 991px) {
  .searchpageContent {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .searchcard-sec {
    margin-top: 0px;
  }

  .searchPageGroup {
    margin-top: 40px;
  }

  .dropdown-sec {
    position: inherit;
    /* padding: 30px 0; */
  }

  .searchPageGroup {
    padding: 0 30px 0 30px;
  }

  .search-map iframe {
    position: inherit;
    height: 500px;
  }

  .search-cards-component-group {
    overflow-y: inherit;
    height: auto;
  }

  .searchCardGroup {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .search-header {
    height: 78px;
  }
}

@media only screen and (max-width: 560px) {
  .dropDownGroup {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 20px;
  }

  .dropdown-sec {
    padding: 30px 0;
  }

  .dropdown .btn-primary {
    width: 150px;
  }

  .dropDownGroup .wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr) !important;
  }

  .switch-btn {
    width: 210px;
  }

  .dropDownGroup {
    overflow: auto;
    height: 90px;
    position: relative;
    z-index: 98 !important;
  }

  .dd-wrapper {
    position: relative;
  }

  .wrapper {
    position: relative;
  }

  .dd-wrapper .dd-list {
    z-index: 111111 !important;
    width: 200px !important;
    left: 0 !important;
    right: 0 !important;
  }

  .dropdown-sec {
    padding-bottom: 0px;
  }

  .searchCardGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: inherit;
  }

  .s-card-texts {
    padding-left: 0px;
    margin-top: 24px;
  }

  .s-card-img img {
    max-width: 100%;
    width: 100%;
  }

  .s-cards-icons {
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 440px) {
  .dropDownGroup {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 20px;
  }

  .dropdown .btn-primary {
    width: 95%;
  }
}