.search-sec {
	position: relative;
}

.searchproperty {
	max-width: 724px;
	margin: 0 auto;
	position: absolute;
	top: -130px;
	z-index: 111 !important;
	left: 0;
	right: 0;
}

.searchproperty-text p {
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 19px;
	color: #4c506b;
	font-family: "Circular Std Book";
	margin-bottom: 20px;
}

.search-wrapper .nav {
	justify-content: center;
	border: 0;
	border-radius: 10px 10px 0 0;
	background: #e9eaf0;
	width: 260px;
	margin-top: 24px;
	position: relative;
	z-index: -1;
	overflow: hidden;
}

.search-wrapper .nav-tabs .nav-link {
	border: none;
	border-radius: none;
	padding: 0;
	margin: 0;
	background: none;
	text-align: center;
	color: #4c506b;
	font-family: "CircularStd";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	width: 130px;
	padding: 16px 0;
	border-radius: 10px 0 10px 0;
	text-transform: capitalize;
}

.search-wrapper .nav-tabs .nav-item.show .nav-link,
.search-wrapper .nav-tabs .nav-link.active {
	border: none;
	background: #ffffff;
	box-shadow: none;
	border-radius: 0px;
	color: #3864e4;
	padding: 16px 0;
}

.nav-link:focus,
.search-wrapper .nav-tabs .nav-link:hover {
	border: none;
	cursor: pointer;
}

.search-input .css-2b097c-container {
	width: 724px;
	position: relative;
	box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);
	border-radius: 0 10px 10px 10px;
}

.property-search input {
	/* border: 1px solid rgb(235, 235, 235) !important; */
	padding: 15px 55px !important;
	/* padding-right: 55px !important;
	padding-left: 55px !important; */
	-webkit-box-shadow: rgb(0 0 0 / 6%) 0px 6px 24px !important;
	-moz-box-shadow: rgb(0 0 0 / 6%) 0px 6px 24px !important;
	box-shadow: rgb(0 0 0 / 6%) 0px 6px 24px !important;
	position: relative;
}

.property-search input::placeholder {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: #373f41;
	font-family: "Circular Std Book";
	position: relative;
}

.property-search input:placeholder-shown {
	text-overflow: ellipsis;
}

div#preload img {
	display: none;
}

.property-search input[type="text"].property_address_input {
	background-image: url(../../Images/search-house.svg) !important;
	background-position: 24px 27px !important;
	background-repeat: no-repeat !important;
}

.property-search input[type="text"].region_input {
	background-image: url(../../Images/region.svg) !important;
	background-position: 24px 27px !important;
	background-repeat: no-repeat !important;
}

.search-input .css-yk16xz-control,
.search-input .css-1hwfws3 {
	height: 74px;
	border: 0;
	font-family: "CircularStd";
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
}

.search-input .css-1wy0on6 {
	display: none;
}

.search-input button {
	position: absolute;
	top: 12px;
	right: 12px;
	text-decoration: none;
	display: block;
	font-size: 14px;
	font-family: "CircularStd";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.3px;
	color: #ffffff;
	outline: none;
	background: none;
	border: none;
	width: 139px;
	height: 50px;
	background: #3864e4;
	border-radius: 6px;
	transition: all 0.4s ease-in-out;
}

.search-input .col-lg-6 {
	padding: 0;
}

.search-cross {
	background: rgba(55, 63, 65, 0.15) !important;
	width: 24px;
	height: 24px;
	border-radius: 100px;
	color: #373f41 !important;
	font-size: 16px !important;
	font-family: "CircularStd";
	font-weight: 500;
	padding: 7px 8px;
	top: 25px !important;
}

.search-cross span {
	display: block;
	line-height: 8px;
}

@media only screen and (max-width: 840px) {
	.search-input .css-2b097c-container {
		width: 100%;
		max-width: 100%;
	}

	.searchproperty-wrapper {
		padding: 0 30px;
	}

	.search-input .css-2b097c-container input {
		width: 100%;
		max-width: 100%;
	}
}

@media only screen and (max-width: 640px) {
	.searchproperty-wrapper {
		padding: 0 30px;
	}
}

@media only screen and (max-width: 480px) {
	.searchproperty {
		padding-bottom: 10px;
	}
}
