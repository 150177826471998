.hidden{
    display: none;
}
.HeroGrid {
	margin-top: 105px;
}

.detailimages {
	display: -ms-grid;
	display: grid;
	background-color: #202020;
	-ms-grid-columns: 1fr 1fr 1fr 1fr;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 0px;
}

.HeroGridImg1 img,
.HeroGridImg2 img,
.HeroGridImg3 img,
.HeroGridImg4 img,
.HeroGridImg5 img,
.HeroGridImg6 img {
	width: 100%;
}

.HeroGridImg1 {
	grid-column: 1 / 3;
	grid-row: 1 / 3;
}
.HeroGridImg2 {
	grid-column: 3 / 4;
	grid-row: 1 / 4;
}
.HeroGridImg3 {
	grid-column: 4 / 4;
	grid-row: 1 / 4;
}
.HeroGridImg4 {
	grid-column: 3 / 3;
	grid-row: 2 / 3;
}
.HeroGridImg5 {
	grid-column: 4 / 4;
	grid-row: 2 / 4;
}

/* .imgsix {
	grid-column: 4 / 4;
	grid-row: 3 / 4;
} */

.HeroGridImg5 {
	position: relative;
	z-index: 1;
}

.detailimages img {
	background-color: #202020;
}

.more-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	z-index: 1;
}

.showmore-btn {
	background-color: rgba(255, 255, 255, 0.949);
	box-shadow: 0px 3px 8px 0px rgba(17, 22, 28, 0.39);
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
	text-align: center;
	width: 114px;
	height: 32px;
	border-radius: 50px;
	outline: none;
	border: none;
	transition: all 0.4s ease-in-out;
}

.showmore-btn:hover {
	background-image: linear-gradient(
		-60deg,
		rgb(79, 34, 185) 0%,
		rgb(138, 75, 214) 100%
	);
	color: #ffffff;
	box-shadow: 0px 10px 30px 0px rgba(14, 13, 13, 0.3);
	cursor: pointer;
	outline: none;
}

.showmore-btn:focus,
.showmore-btn:active {
	outline: none;
	border: none;
}

@media only screen and (max-width: 900px) {
	.HeroGridImg1 {
		grid-column: 1 / 5;
		grid-row: 1 / 3;
		display: none;
	}
	.HeroGridImg2 {
		grid-column: 1 / 3;
		grid-row: 3;
	}
	.HeroGridImg3 {
		grid-column: 3 / 5;
		grid-row: 3 / 4;
	}
	.HeroGridImg4 {
		grid-column: 1 / 3;
		grid-row: 4 / 4;
	}
	.HeroGridImg5 {
		grid-column: 3 / 5;
		grid-row: 4 / 4;
	}
}

.DetailPageTitle {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
}

.InvesmentRCards {
	padding: 0;
	max-width: 1110px;
	margin: 0 auto;
}

.detailpage,
.InvesmentRCards {
	margin-top: 50px;
}

.detail-cta {
	border: none;
	outline: none;
	background: rgba(228, 173, 36, 0.15);
	color: #e4ad24;
	font-size: 12px;
	line-height: 15px;
	font-weight: normal;
	font-family: "Circular Std Book";
	text-transform: capitalize;
	text-align: center;
	padding: 6px 12px;
	border-radius: 11px;
	margin-right: 5px;
}

.title-name {
	padding-top: 30px;
}

.title-name h2 {
	font-size: 30px;
	font-family: "Montserrat";
	color: #131521;
	line-height: 37px;
	font-weight: 600;
	padding-bottom: 6px;
}

.prop-address {
	font-size: 15px;
	line-height: 19px;
	color: #131521;
	font-family: "Circular Std Book";
}

.home-quantity-cta {
	margin-top: 54px;
}

.home-cta {
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	width: 102px;
	height: 39px;
	padding: 12px 16px 12px 47px;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	font-family: "Circular Std Book";
	color: #5b607d;
	margin-right: 10px;
	position: relative;
}

/* Note: backdrop-filter has minimal browser support */

.beds::after {
	content: "";
	position: absolute;
	background-image: url(../Images/beds.png);
	width: 22px;
	height: 18px;
	left: 15px;
	top: 11px;
}

.baths::after {
	content: "";
	position: absolute;
	background-image: url(../Images/baths.png);
	width: 22px;
	height: 18px;
	left: 15px;
	top: 11px;
}

.sqft::after {
	content: "";
	position: absolute;
	background-image: url(../Images/sqft.png);
	width: 22px;
	height: 19px;
	left: 15px;
	top: 11px;
}

.DetailPageTitleGroup {
	position: relative;
}

.buy-shares-btn {
	position: absolute;
	top: 60px;
	right: 0;
}

.buy-shares-btn button {
	background: #3864e4;
	color: #fff;
	padding: 13px 71px 13px 30px;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	border-radius: 50px;
	font-family: "CircularStd";
	border: none;
	outline: none;
	position: relative;
	transition: all 0.6s ease-in-out;
}

.buy-shares-btn button::after {
	content: "";
	position: absolute;
	background-image: url(../Images/arrow-cta-white.png);
	width: 16px;
	height: 12px;
	right: 20px;
	margin-top: 4px;
}

.buy-shares-btn button::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 21px;
	right: 49px;
	top: 12px;
	border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.input-range__slider {
	margin-top: -10px !important;
}

.input-range__label-container {
	left: -70% !important;
	padding: 3px 5px;
	background: #3c4257;
	border-radius: 4px;
	color: white;
	top: 14px;
	font-size: 12px;
	line-height: 15px;
	font-family: "CircularStd";
}

.buy-shares-btn button:hover {
	background-image: linear-gradient(
		60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	color: #ffffff;
	-webkit-box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.5);
	box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.5);
	cursor: pointer;
	outline: none;
}

a.z-listing {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #016aff;
	border-bottom: 1px solid rgba(1, 106, 255, 0.7);
	padding-bottom: 5px;
	font-family: "Circular Std Book";
	text-decoration: none;
	margin-left: 20px;
}

a.z-listing svg {
	margin-right: 10px;
	position: relative;
	top: -2px;
}

button:focus,
button:active {
	border: none;
	outline: none;
}

.Calculation-Inputs {
	max-width: 1170px;
	margin: 0 auto;
	margin-top: 110px;
	padding: 0 30px;
}

.form {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding: 30px 0 0;
	grid-gap: 30px;

	@media (min-width: 800px) {
		max-width: 60%;
	}
}

.calculatorInputsGroup,
.Calculator-OutputBoxesGroup {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 30px 0 0;
	grid-gap: 30px;

	@media (min-width: 800px) {
		max-width: 60%;
	}
}

.InputRange {
	background-color: rgb(255, 255, 255);
	padding: 20px 25px;
}

.input-title, .equity-graph-wrapper h6.input-title {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	font-family: "Circular Std Book";
	color: #9fa2b8;
	margin: 0;
}

.inputTitleGroup {
	padding: 0 0 35px 0;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.inputTitleGroup2 {
	padding-bottom: 18px;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

span.box-toltip {
	border-radius: 50%;
	background-color: transparent;
	width: 16px;
	height: 16px;
	font-size: 10px;
	font-family: "Montserrat";
	color: #cfcac4;
	border: 2px solid #e8e3e0;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

span.box-toltip:hover {
	cursor: pointer;
}

.input-range__label--max,
.input-range__label--min {
	display: none;
}

.input-range__label {
	font-size: 16px !important;
	font-weight: 500 !important;
	font-family: "Montserrat" !important;
	color: rgb(46, 50, 56) !important;
}

.input-range__label--value {
	top: -3.2rem !important;
}

.input-range__label-container {
	left: -45% !important;
}

.input-range__slider {
	border-radius: 50% !important;
	background-color: rgb(255, 255, 255) !important;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.22) !important;
	border: 1px solid rgb(201, 205, 208) !important;
	width: 17px !important;
	height: 17px !important;
}

.input-range__track {
	background: rgb(193, 196, 198, 0.4) !important;
	height: 4px !important;
	background: #f8f9f9 !important;
}

.input-range__track--active {
	background: linear-gradient(
		250.15deg,
		#9897eb 15.63%,
		#7e7dda 73.48%
	) !important;
}

.InputBox {
	padding: 18px 22px;
	background: #f3efea;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
}

.InputBox.active {
	background-color: #fff;
}

.InputBoxes {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
}

.dp-section-title h4 {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
	padding-bottom: 30px;
	font-weight: 500;
}

.InputBoxesTitle h6 {
	font-size: 15px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: 600;
	line-height: 1.2;
	padding-bottom: 22px;
	text-align: left;
}

.OutputBoxes {
	margin-top: 60px;
}
.OutputBoxesGroup,
.InvesmentRCardsGroup {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
}

.OnputBox {
	background: #f3efea;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	padding: 20px 22px;
	text-align: left;
}

.Input-property,
.Onput-property {
	font-size: 28px;
	color: rgb(46, 50, 56);
	line-height: 20px;
	color: #131521;
	font-family: "Circular Std Book";
}

.Input-property {
	font-size: 16px;
}

span.output-Investor {
	font-size: 13px;
	color: rgb(46, 50, 56);
}

.InvesmentRCard {
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	padding: 32px 0 42px 0;
	text-align: center;
}

.InvesmentRCard-text {
	padding-top: 20px;
}

.InvesmentRCard-subtitle {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #9fa2b8;
	font-family: "Circular Std Book";
}

.InvesmentRCard-digits {
	font-weight: normal;
	font-size: 26px;
	line-height: 33px;
	text-align: center;
	color: #131521;
	font-family: "Circular Std Book";
	padding-top: 10px;
}

.DocumentsButtons {
	margin-top: 100px;
	text-align: center;
}

.DocumentsButtons-title h6 {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
	font-weight: 500;
	text-align: center;
	padding-bottom: 60px;
}

a.Documents-btn {
	background: rgba(56, 100, 228, 0.08);
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	border-radius: 80px;
	font-weight: normal;
	font-size: 15px;
	line-height: 19px;
	color: #3864e4;
	padding: 20px 30px 20px 60px;
	font-family: "Circular Std Book";
	text-decoration: none;
	margin-right: 20px;
	position: relative;
	transition: all 0.4s ease-in-out;
}

a.Documents-btn:hover {
	background-color: rgb(58, 109, 245);
	color: #fff;
	cursor: pointer;
	outline: none;
}

a.Documents-btn::after {
	content: "";
	position: absolute;
	background: url(../Images/doc-btnIcon.svg);
	width: 18px;
	height: 22px;
	left: 30px;
	top: 17px;
	transition: all 0.4s ease-in-out;
}

a.Documents-btn:hover::after {
	background: url(../Images/doc-btnIcon-white.svg);
}

.FactsFeatureCards-section {
	max-width: 1170px;
	margin: 0 auto;
	margin-top: 120px;
	padding: 0 30px;
}

.FAFCard {
	display: flex;
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	backdrop-filter: blur(100px);
	padding: 24px 30px;
}

.FAFCard-text {
	padding-left: 16px;
}

.FAFCard-text h6 {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #9fa2b8;
	font-family: "Circular Std Book";
}

.FAFCard-text p {
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 19px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
	padding-top: 6px;
}

.FactsFeatureCardsGroup {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
}

.splitline {
	max-width: 1110px;
	margin: 50px auto;
	border: none;
	height: 1px;
	padding: 0 30px;
	background-color: rgb(235, 237, 239);
}

.InteriorFeatures-section {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
	margin-top: 100px;
}

.InteriorFeatures-title {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #5b607d;
}

.InteriorFeatures-list {
	margin-top: 10px;
}

.InteriorFeatures-list li {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #9fa2b8;
	list-style: none;
	padding-bottom: 4px;
	font-family: "Circular Std Book";
}

.InteriorFeatures-list li span.bold {
	color: #2e3238;
}

.InteriorFeatures-space {
	margin-top: 24px;
}

.InteriorFeatures2 {
	margin-top: 24px;
}

.InteriorFeatures-col {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.white-header .Header {
	background: white;
	padding: 30px;
}

@media only screen and (max-width: 1100px) {
	.buy-shares-btn {
		position: relative;
		top: 20px;
	}

	.InteriorFeatures-col {
		grid-template-columns: repeat(2, 1fr);
	}

	.InteriorFeatures3 {
		margin-top: 50px;
	}
}

@media only screen and (max-width: 991px) {
	.InputBoxes,
	.form,
	.OutputBoxesGroup,
	.InvesmentRCardsGroup,
	.FactsFeatureCardsGroup {
		grid-template-columns: repeat(2, 1fr);
	}

	.DocumentsButtonsGroup {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
	}

	.Documents-btn {
		margin-bottom: 15px;
	}
}

@media only screen and (max-width: 767px) {
	.home-quantity-cta {
		display: flex;
		align-items: center;
		width: 100%;
		flex-wrap: wrap;
	}

	.home-cta {
		display: flex;
		width: 45%;
		align-items: center;
		margin-bottom: 15px;
		margin-right: 15px;
	}

	.baths::after,
	.beds::after,
	.sqft::after {
		margin-top: 0px;
	}
}

@media only screen and (max-width: 840px) {
	.HeroGrid {
		margin-top: 68px;
	}
}

@media only screen and (max-width: 600px) {
	.white-header .Header {
		padding: 20px 15px;
	}

	.InteriorFeatures-col {
		grid-template-columns: repeat(1, 1fr);
	}

	.InteriorFeatures2 {
		margin-top: 50px;
	}

	.detail-cta-group {
		display: flex;
		flex-wrap: wrap;
	}
	.detail-cta {
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 500px) {
	.InputBoxes,
	.form,
	.OutputBoxesGroup,
	.InvesmentRCardsGroup,
	.FactsFeatureCardsGroup,
	.Calculator-OutputBoxesGroup,
	.calculatorInputsGroup {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media only screen and (max-width: 480px) {
	.HeroGrid {
		margin-top: 60px;
	}

	.home-cta {
		width: 100%;
	}
}

.LocationPriceHistory,
.InvestmentReturnsGraph-section {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
	margin-top: 100px;
}

.LocationPriceHistory-Group {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
	grid-gap: 50px;
}

.LocationPriceHistory-Group.in {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	width: 100%;
	grid-gap: 50px;
}
.map{
	position: relative;
}
.map iframe, .map .gmapbox {
	width: 100%;
	height: 363px;
	border: 1px solid rgb(230, 232, 234);
	border-radius: 5px;
	background-color: rgb(82, 68, 205);
}

.LocationPriceHistory-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 26px;
}

.LocationPriceHistory-title h4 {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
}

.LocationPriceHistory-title a,
.LocationPriceHistory-title span {
	font-size: 11px;
	font-family: "Montserrat";
	color: rgb(58, 109, 245);
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 500;
	cursor: pointer;
	border-bottom: 1px solid rgb(58, 109, 245);
}

.price-chart img,
.InvestmentReturnsGraph-img img {
	width: 100%;
}

@media only screen and (max-width: 991px) {
	.LocationPriceHistory-Group {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		width: 100%;
		grid-gap: 50px;
	}

	.expand-link {
		display: none;
	}
}

.edit-textfield .form-control-sm {
	padding: 0px;
	font-size: 16px;
	color: #2e3238;
}

.edit-textfield .mt-auto {
	background: url(../Images/edit-icon.png);
	width: 16px;
	height: 16px;
	color: #c9cdd0;
	margin-top: 0px;
	margin-left: 14px !important;
}

.edit-textfield .mt-auto:hover {
	text-decoration: none;
}

.calculatorGraph {
	margin-top: 100px;
}

.InvesmentRCards-section {
	padding: 0 30px;
}
