.auction-table .table {
  display: inline-table;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(100px);
  border-radius: 10px;
}

.auction-table .dashTitle-bar {
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(100px);
  border-radius: 10px 10px 0 0;
  padding: 30px 30px 10px 30px;
  max-width: 100%;
  width: 100%;
}

.auction-table .dashTitle h4 {
  font-size: 22px;
  line-height: 28px;
  color: #131521;
  font-weight: 500;
}

.auction-table .createauction-btn button {
  background: #000000;
  border-radius: 33px;
  color: #ffffff;
  padding: 11px 25px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  font-family: "CircularStd";
  border: none;
  outline: none;
  position: relative;
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
}

.auction-table .createauction-btn-2 button {
  margin-top: 20px;
}

.auction-detailpage-row-1 .AuctionCardGroup {
  display: flex;
  grid-gap: 50px;
}

.auction-detailpage-row-2 {
  display: flex;
  grid-gap: 30px;
  /* display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 30px; */
}

.auction-detailpage-row-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin-top: 30px;
}

.auction-detailpage-row-1 .AuctionCardSubGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.auction-detailpage-row-1 .auction-img {
  width: 700px;
}

.AuctionTimeboxes {
  grid-gap: 10px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  font-size: 12px;
  color: #9fa2b8;
}

.ExistingBidCard .SubmitBidCardTitle {
  margin: 30px 15px 15px 15px;
}

.AuctionTimeboxes .time-box {
  margin-right: 0px;
  width: 100%;
}

.AuctionCard .AuctionCardText {
  width: 100%;
}

.auction-detailpage-row-2 .action-inputs {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
}

.auction-detailpage-row-2 .action-ip-1,
.auction-detailpage-row-2 .action-ip-2 {
  max-width: 100%;
  border: none;
  padding: 20px 8px;
}

.auction-table .table thead th {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 30px;
  color: #9fa2b8;
  position: relative;
  z-index: 10;
  width: 25%;
  font-family: "CircularStd";
  border-bottom: 1px solid #f4f5fa;
}

.auction-table .table td {
  border-top: 0;
  padding: 15px 30px;
  vertical-align: middle;
}

.auction-table .table tr {
  border-top: 1px solid #f4f5fa;
}

.AuctionTimeCard,
.AuctionMiniCard-section {
  margin-bottom: 0;
}

.auction-table .table .property-col {
  display: flex;
  align-items: center;
  min-width: 300px;
}

ul.AddProperty-inputs li input {
  height: auto;
  padding: 12px 17px;
}

.AddProperty {
  margin-top: 60px;
}

.auction-table .table .property-col img {
  border-radius: 50%;
  /* width: 55px; */
  margin-right: 15px;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(100px);
}

.AddProperty-rs-Group {
  width: 100%;
}

.auction-table .table .property-col h6 {
  font-size: 15px;
  line-height: 21px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 500;
}

.auction-table .table .amount-raising-col {
  font-size: 14px;
  line-height: 18px;
  color: #3864e4;
  font-family: "CircularStd";
  font-weight: 500;
  min-width: 150px;
}

.auction-table .table .date-col {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131521;
  font-family: "Circular Std Book";
  min-width: 150px;
}

.auction-table .table .status-col {
  min-width: 220px;
}

.auction-table .table .tag--active,
.auction-table .table .tag--inreview,
.auction-table .table .tag--success,
.auction-table .table .tag--closed {
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #3864e4;
  font-family: "CircularStd";
  font-weight: 500;
  background: rgba(56, 100, 228, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 60px;
  padding: 5px 15px;
}

.auction-table .table .tag--inreview {
  background: rgba(255, 174, 0, 0.15);
  color: #ffae00;
}

.auction-table .table .tag--success {
  background: rgba(83, 182, 73, 0.12);
  color: #53b649;
}

.auction-table .table .tag--closed {
  background: rgba(189, 72, 101, 0.1);
  color: #bd4865;
}

.auction-page-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 320px;
}

.auction-page-topbar h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 600;
  margin-top: 17px;
}

.table-empty-state {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 70px 15px;
  text-align: center;
}
.table-empty-state button {
  margin-top: 20px;
}

.table-empty-state-2 {
  position: relative;
  left: 25%;
}

.table-empty-state p {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #5b607d;
  font-family: "Circular Std Book";
  margin-top: 24px;
}

@media only screen and (max-width: 1200px) {
  .auction-table .table {
    display: block;
  }
}

@media only screen and (max-width: 1100px) {
  .auction-detailpage-row-1 .AuctionCardGroup {
    flex-direction: column;
    grid-gap: 0;
  }

  .auction-detailpage-row-1 .auction-img {
    max-width: 100%;
    width: 100%;
  }

  .auction-detailpage-row-2,
  .auction-detailpage-row-3 {
    /* grid-template-columns: repeat(1, 1fr); */
    grid-gap: 30px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 900px) {
  .auction-page-topbar {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .auction-table .table {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .auction-table .dashTitle h4 {
    font-size: 19px;
    line-height: 25px;
  }

  .auction-table .table .property-col {
    min-width: 250px;
  }

  .auction-table .table .status-col {
    min-width: 190px;
  }

  .auction-table .table .amount-raising-col,
  .auction-table .table .date-col {
    min-width: 140px;
  }

  .auction-page-topbar h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .auction-page-topbar {
    padding-left: 15px;
  }

  .auction-table .table .property-col h6 {
    font-size: 16px;
    line-height: 23px;
  }

  .auction-table .table .property-col img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .auction-table .table td {
    padding: 15px;
  }
  .auction-table .table thead th {
    padding: 12px 15px;
  }

  .auction-table .dashTitle-bar {
    padding: 30px 15px 10px 15px;
  }
}

@media only screen and (max-width: 480px) {
  .dashTitle-bar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .auction-detailpage-row-2,
  .auction-detailpage-row-3 {
    grid-gap: 15px;
  }

  .AuctionCard {
    margin-bottom: 15px;
  }

  .auction-detailpage-row-3 {
    margin-top: 15px;
  }

  .SubmitBidCardGroup {
    padding: 20px 70px 20px 20px;
  }

  .auction-table .createauction-btn {
    margin-top: 20px;
  }

  .auction-table .createauction-btn,
  .auction-table .createauction-btn button {
    width: 100%;
  }
}
