@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
.hidden{
    display: none;
}
.HeroGrid {
	margin-top: 105px;
}

.detailimages {
	display: grid;
	background-color: #202020;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 0px;
}

.HeroGridImg1 img,
.HeroGridImg2 img,
.HeroGridImg3 img,
.HeroGridImg4 img,
.HeroGridImg5 img,
.HeroGridImg6 img {
	width: 100%;
}

.HeroGridImg1 {
	grid-column: 1 / 3;
	grid-row: 1 / 3;
}
.HeroGridImg2 {
	grid-column: 3 / 4;
	grid-row: 1 / 4;
}
.HeroGridImg3 {
	grid-column: 4 / 4;
	grid-row: 1 / 4;
}
.HeroGridImg4 {
	grid-column: 3 / 3;
	grid-row: 2 / 3;
}
.HeroGridImg5 {
	grid-column: 4 / 4;
	grid-row: 2 / 4;
}

/* .imgsix {
	grid-column: 4 / 4;
	grid-row: 3 / 4;
} */

.HeroGridImg5 {
	position: relative;
	z-index: 1;
}

.detailimages img {
	background-color: #202020;
}

.more-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 10;
	z-index: 1;
}

.showmore-btn {
	background-color: rgba(255, 255, 255, 0.949);
	box-shadow: 0px 3px 8px 0px rgba(17, 22, 28, 0.39);
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
	text-align: center;
	width: 114px;
	height: 32px;
	border-radius: 50px;
	outline: none;
	border: none;
	transition: all 0.4s ease-in-out;
}

.showmore-btn:hover {
	background-image: linear-gradient(
		-60deg,
		rgb(79, 34, 185) 0%,
		rgb(138, 75, 214) 100%
	);
	color: #ffffff;
	box-shadow: 0px 10px 30px 0px rgba(14, 13, 13, 0.3);
	cursor: pointer;
	outline: none;
}

.showmore-btn:focus,
.showmore-btn:active {
	outline: none;
	border: none;
}

@media only screen and (max-width: 900px) {
	.HeroGridImg1 {
		grid-column: 1 / 5;
		grid-row: 1 / 3;
		display: none;
	}
	.HeroGridImg2 {
		grid-column: 1 / 3;
		grid-row: 3;
	}
	.HeroGridImg3 {
		grid-column: 3 / 5;
		grid-row: 3 / 4;
	}
	.HeroGridImg4 {
		grid-column: 1 / 3;
		grid-row: 4 / 4;
	}
	.HeroGridImg5 {
		grid-column: 3 / 5;
		grid-row: 4 / 4;
	}
}

.DetailPageTitle {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
}

.InvesmentRCards {
	padding: 0;
	max-width: 1110px;
	margin: 0 auto;
}

.detailpage,
.InvesmentRCards {
	margin-top: 50px;
}

.detail-cta {
	border: none;
	outline: none;
	background: rgba(228, 173, 36, 0.15);
	color: #e4ad24;
	font-size: 12px;
	line-height: 15px;
	font-weight: normal;
	font-family: "Circular Std Book";
	text-transform: capitalize;
	text-align: center;
	padding: 6px 12px;
	border-radius: 11px;
	margin-right: 5px;
}

.title-name {
	padding-top: 30px;
}

.title-name h2 {
	font-size: 30px;
	font-family: "Montserrat";
	color: #131521;
	line-height: 37px;
	font-weight: 600;
	padding-bottom: 6px;
}

.prop-address {
	font-size: 15px;
	line-height: 19px;
	color: #131521;
	font-family: "Circular Std Book";
}

.home-quantity-cta {
	margin-top: 54px;
}

.home-cta {
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	width: 102px;
	height: 39px;
	padding: 12px 16px 12px 47px;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	font-family: "Circular Std Book";
	color: #5b607d;
	margin-right: 10px;
	position: relative;
}

/* Note: backdrop-filter has minimal browser support */

.beds::after {
	content: "";
	position: absolute;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAASCAYAAABfJS4tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAG6SURBVHgBtVTLTsJAFD3tDOUR1uhGo0bUFYL6/39gKz42otEFuiK4pJ221HumLeFlAhgOIdCbuWfOuY86mQB7gIs9Qc8/ULwxMdJpCgebg5aV68LzPDiOs0psjIHvB5iEIZwtmFnMarWKu9sb+7tCPJ1OLen1dQc1ObBJ8Xl/GEXo9x9tfgm9clCkNup11Go1xHE8O+wWVulqPlapVOyvs2RR/6WEpPe+LyS5bq0Vet0u/CCwxLSvlIubXm9t/gJxmqY2KRJrVBBFBhcXbVuS98E7wslEbBu02+f2/NtggInE2HTmMX/mvJzjUGr78fGJr+9vVNhdiSVyUOv87iRJoJVaG8vkE5sEh4cHODs9QaPRyIlp+3XwZsmOj4/EIhMzq7pcH5YwK5q1ECseqHg4HMII19XlZb4gvHk0Gtkblc5JgTIp/9r/WbYaK+C6Cq1WC+PxT+4ExSRwMZ6eX2yHdwVVk4d8ulTGrnc7HXhVD7vCSGP9hwfLt993BaUnSYqg35fGKewKjht5FkrBYe/KKpe7vgs4/36Ql6JQ7KLZbKIuq/wfYjaePOSzc8wbOMvce2eb19oS5nl+AQNsAuip2s5nAAAAAElFTkSuQmCC);
	width: 22px;
	height: 18px;
	left: 15px;
	top: 11px;
}

.baths::after {
	content: "";
	position: absolute;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAASCAYAAABfJS4tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHvSURBVHgBtVTbbtpAED1r1hcMRSUl5qJUzUtQmwanUfv/j81LW0VtVAqoIpWamEKRgAeIuTkzQyAXBRTJyZHs9a5nz86cmVkVEfAM0Pyaz+cYj8eyYFkWDMNAXGgmDYIWzs7+AArY3X2DYqEQm1yH4Ri/m028LZdloVav49XWFhzHQRyQWxGmkwnclCsyTKdTkSWu9BKvoRQikqTeaAhxrd7AhMY4kOSxb0oZ8P0KTSL6VjC1RhysdjOZY9t4KugJ6au1CR5Ho9EGU0WHS0DXjiy/by8sBpscVJ+Pj6N+f4BkMrmxxDiZnFTbWUQVXoaSbI6UMef/YYhMJoOjD4dQg8Eg+lmt4vXODrLZ7FrikDZ9+foNh74PZSicnHzHp49H4h2j1+uhSb1QOXiPdDoN7bouvG0P/7td5HI58eIhcDQWkVRrv2Ru2ZZEycQcSZf2F/J5MB9HofiuGA6H+HF6igJ1XKlYhGmaa6XgTl0ddF33QauFi4sAfuVAiEXyaAEKpb/qulKpCCORuMnUGsxmMyL9J96W9/ZIypcrzdXyduOBPf97fo6ATufFBNWyWkPK9ouOTWF//x1ekK5LUsadOk6RESeRvfe8beQ9747xfeJ2u4N2pyP1f99OPXQfs5YJkoKfTWC9WWPOyaOInwJXEuTqTSp/aAEAAAAASUVORK5CYII=);
	width: 22px;
	height: 18px;
	left: 15px;
	top: 11px;
}

.sqft::after {
	content: "";
	position: absolute;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAATCAYAAACUef2IAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI/SURBVHgB7VRZc9JQGD1JWGXRjkrqDI6y1SrwoL6p/39GR6cP0GBpWVp2WmAgCbIkxO9elgk1gNPnnplM7iT3nu98X3KOYBFAYLf5fA5Jkvi1D2zfYrGAx+OBIAiOe8T1Yjab4dfZGWr1Oqa0dgIrrus6LopFfP/xE3f9PkzD3E/MFGiqhkajiYvfRaiahlUzHIZh4K7XQ/78nNYmxmMdBaWAm1oN0+l0NzGDy+VCNpOG1+dFLpdHt9ulTuaYTCaoXl+jSEpfR6NIJuLwer04PX2H/mAApVDAaDTi4jZc9yu53W7EY28RDoVQLlcRDvcx/qNDEiVk0mmE6PlaYSgQQPrDe9RuasjlFTr3BrIsQxTFbcV2clmOIJNNwzANUqMilUpSkTA/tAF9OJ/Ph1g8hgR1Uby82hR1JOYviCAUDHL1oijwMe36A9i7p+EgTNN0nvGuQyQNhyFsFT5I/FA8EjsTL51mHTy0NIL1/8QsfIYjjdvXCaww+0/b7Q6RW9yRdrc5EjOLMhOUSmVUKlV+yJ4VjEhVVSiUDwOysRyJoECZ0iHbs7S7j42lmSGOyY6BJwGycgl5RUEynqBodPMCt7ddNFsdvHzxHFHKC5dLQq/Xp70V7sxXx/IWsWDZZa3aZRFab7TQ6bSpWASXVyUEgwGcpE5wdPRsZZplFzqlXIUyRdVUSrwxvn39Ar/f/y/xGmzOw+GQk5q0zmYzPICcbM3yu9VsUkdtfP70kY91J7Fd/fob7APLCZbTbHSs+F8W1TO+chTcIwAAAABJRU5ErkJggg==);
	width: 22px;
	height: 19px;
	left: 15px;
	top: 11px;
}

.DetailPageTitleGroup {
	position: relative;
}

.buy-shares-btn {
	position: absolute;
	top: 60px;
	right: 0;
}

.buy-shares-btn button {
	background: #3864e4;
	color: #fff;
	padding: 13px 71px 13px 30px;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	border-radius: 50px;
	font-family: "CircularStd";
	border: none;
	outline: none;
	position: relative;
	transition: all 0.6s ease-in-out;
}

.buy-shares-btn button::after {
	content: "";
	position: absolute;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAAgklEQVQokaWQMQrCQBQFR0lvWosUaT2J4KFTCF7AwhQRBAuPkCLFpMgiChv4qwMLu3zefPZtVAJcgB1wBJ6fg20kDdTAAeiA5muiRk6j3l0Y0hs1LFiVlAiyEtSzv3ONlrjGVPqFVn2k7b26/ytcUmI2XCK45cIqVbCsFzACp3R/MwNDMqyvZUH3YAAAAABJRU5ErkJggg==);
	width: 16px;
	height: 12px;
	right: 20px;
	margin-top: 4px;
}

.buy-shares-btn button::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 21px;
	right: 49px;
	top: 12px;
	border-left: 1px solid rgba(255, 255, 255, 0.15);
}

.input-range__slider {
	margin-top: -10px !important;
}

.input-range__label-container {
	left: -70% !important;
	padding: 3px 5px;
	background: #3c4257;
	border-radius: 4px;
	color: white;
	top: 14px;
	font-size: 12px;
	line-height: 15px;
	font-family: "CircularStd";
}

.buy-shares-btn button:hover {
	background-image: linear-gradient(
		60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	color: #ffffff;
	box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.5);
	cursor: pointer;
	outline: none;
}

a.z-listing {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #016aff;
	border-bottom: 1px solid rgba(1, 106, 255, 0.7);
	padding-bottom: 5px;
	font-family: "Circular Std Book";
	text-decoration: none;
	margin-left: 20px;
}

a.z-listing svg {
	margin-right: 10px;
	position: relative;
	top: -2px;
}

button:focus,
button:active {
	border: none;
	outline: none;
}

.Calculation-Inputs {
	max-width: 1170px;
	margin: 0 auto;
	margin-top: 110px;
	padding: 0 30px;
}

.form {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding: 30px 0 0;
	grid-gap: 30px;

	@media (min-width: 800px) {
		max-width: 60%;
	}
}

.calculatorInputsGroup,
.Calculator-OutputBoxesGroup {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 30px 0 0;
	grid-gap: 30px;

	@media (min-width: 800px) {
		max-width: 60%;
	}
}

.InputRange {
	background-color: rgb(255, 255, 255);
	padding: 20px 25px;
}

.input-title, .equity-graph-wrapper h6.input-title {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	font-family: "Circular Std Book";
	color: #9fa2b8;
	margin: 0;
}

.inputTitleGroup {
	padding: 0 0 35px 0;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.inputTitleGroup2 {
	padding-bottom: 18px;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

span.box-toltip {
	border-radius: 50%;
	background-color: transparent;
	width: 16px;
	height: 16px;
	font-size: 10px;
	font-family: "Montserrat";
	color: #cfcac4;
	border: 2px solid #e8e3e0;
	font-weight: bold;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

span.box-toltip:hover {
	cursor: pointer;
}

.input-range__label--max,
.input-range__label--min {
	display: none;
}

.input-range__label {
	font-size: 16px !important;
	font-weight: 500 !important;
	font-family: "Montserrat" !important;
	color: rgb(46, 50, 56) !important;
}

.input-range__label--value {
	top: -3.2rem !important;
}

.input-range__label-container {
	left: -45% !important;
}

.input-range__slider {
	border-radius: 50% !important;
	background-color: rgb(255, 255, 255) !important;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.22) !important;
	border: 1px solid rgb(201, 205, 208) !important;
	width: 17px !important;
	height: 17px !important;
}

.input-range__track {
	background: rgb(193, 196, 198, 0.4) !important;
	height: 4px !important;
	background: #f8f9f9 !important;
}

.input-range__track--active {
	background: linear-gradient(
		250.15deg,
		#9897eb 15.63%,
		#7e7dda 73.48%
	) !important;
}

.InputBox {
	padding: 18px 22px;
	background: #f3efea;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
}

.InputBox.active {
	background-color: #fff;
}

.InputBoxes {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
}

.dp-section-title h4 {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
	padding-bottom: 30px;
	font-weight: 500;
}

.InputBoxesTitle h6 {
	font-size: 15px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: 600;
	line-height: 1.2;
	padding-bottom: 22px;
	text-align: left;
}

.OutputBoxes {
	margin-top: 60px;
}
.OutputBoxesGroup,
.InvesmentRCardsGroup {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
}

.OnputBox {
	background: #f3efea;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	padding: 20px 22px;
	text-align: left;
}

.Input-property,
.Onput-property {
	font-size: 28px;
	color: rgb(46, 50, 56);
	line-height: 20px;
	color: #131521;
	font-family: "Circular Std Book";
}

.Input-property {
	font-size: 16px;
}

span.output-Investor {
	font-size: 13px;
	color: rgb(46, 50, 56);
}

.InvesmentRCard {
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	padding: 32px 0 42px 0;
	text-align: center;
}

.InvesmentRCard-text {
	padding-top: 20px;
}

.InvesmentRCard-subtitle {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #9fa2b8;
	font-family: "Circular Std Book";
}

.InvesmentRCard-digits {
	font-weight: normal;
	font-size: 26px;
	line-height: 33px;
	text-align: center;
	color: #131521;
	font-family: "Circular Std Book";
	padding-top: 10px;
}

.DocumentsButtons {
	margin-top: 100px;
	text-align: center;
}

.DocumentsButtons-title h6 {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.2;
	font-weight: 500;
	text-align: center;
	padding-bottom: 60px;
}

a.Documents-btn {
	background: rgba(56, 100, 228, 0.08);
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	border-radius: 80px;
	font-weight: normal;
	font-size: 15px;
	line-height: 19px;
	color: #3864e4;
	padding: 20px 30px 20px 60px;
	font-family: "Circular Std Book";
	text-decoration: none;
	margin-right: 20px;
	position: relative;
	transition: all 0.4s ease-in-out;
}

a.Documents-btn:hover {
	background-color: rgb(58, 109, 245);
	color: #fff;
	cursor: pointer;
	outline: none;
}

a.Documents-btn::after {
	content: "";
	position: absolute;
	background: url(/static/media/doc-btnIcon.64120852.svg);
	width: 18px;
	height: 22px;
	left: 30px;
	top: 17px;
	transition: all 0.4s ease-in-out;
}

a.Documents-btn:hover::after {
	background: url(/static/media/doc-btnIcon-white.15dcc12d.svg);
}

.FactsFeatureCards-section {
	max-width: 1170px;
	margin: 0 auto;
	margin-top: 120px;
	padding: 0 30px;
}

.FAFCard {
	display: flex;
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	padding: 24px 30px;
}

.FAFCard-text {
	padding-left: 16px;
}

.FAFCard-text h6 {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #9fa2b8;
	font-family: "Circular Std Book";
}

.FAFCard-text p {
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 19px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
	padding-top: 6px;
}

.FactsFeatureCardsGroup {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 30px;
}

.splitline {
	max-width: 1110px;
	margin: 50px auto;
	border: none;
	height: 1px;
	padding: 0 30px;
	background-color: rgb(235, 237, 239);
}

.InteriorFeatures-section {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
	margin-top: 100px;
}

.InteriorFeatures-title {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #5b607d;
}

.InteriorFeatures-list {
	margin-top: 10px;
}

.InteriorFeatures-list li {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #9fa2b8;
	list-style: none;
	padding-bottom: 4px;
	font-family: "Circular Std Book";
}

.InteriorFeatures-list li span.bold {
	color: #2e3238;
}

.InteriorFeatures-space {
	margin-top: 24px;
}

.InteriorFeatures2 {
	margin-top: 24px;
}

.InteriorFeatures-col {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.white-header .Header {
	background: white;
	padding: 30px;
}

@media only screen and (max-width: 1100px) {
	.buy-shares-btn {
		position: relative;
		top: 20px;
	}

	.InteriorFeatures-col {
		grid-template-columns: repeat(2, 1fr);
	}

	.InteriorFeatures3 {
		margin-top: 50px;
	}
}

@media only screen and (max-width: 991px) {
	.InputBoxes,
	.form,
	.OutputBoxesGroup,
	.InvesmentRCardsGroup,
	.FactsFeatureCardsGroup {
		grid-template-columns: repeat(2, 1fr);
	}

	.DocumentsButtonsGroup {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;
	}

	.Documents-btn {
		margin-bottom: 15px;
	}
}

@media only screen and (max-width: 767px) {
	.home-quantity-cta {
		display: flex;
		align-items: center;
		width: 100%;
		flex-wrap: wrap;
	}

	.home-cta {
		display: flex;
		width: 45%;
		align-items: center;
		margin-bottom: 15px;
		margin-right: 15px;
	}

	.baths::after,
	.beds::after,
	.sqft::after {
		margin-top: 0px;
	}
}

@media only screen and (max-width: 840px) {
	.HeroGrid {
		margin-top: 68px;
	}
}

@media only screen and (max-width: 600px) {
	.white-header .Header {
		padding: 20px 15px;
	}

	.InteriorFeatures-col {
		grid-template-columns: repeat(1, 1fr);
	}

	.InteriorFeatures2 {
		margin-top: 50px;
	}

	.detail-cta-group {
		display: flex;
		flex-wrap: wrap;
	}
	.detail-cta {
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 500px) {
	.InputBoxes,
	.form,
	.OutputBoxesGroup,
	.InvesmentRCardsGroup,
	.FactsFeatureCardsGroup,
	.Calculator-OutputBoxesGroup,
	.calculatorInputsGroup {
		grid-template-columns: repeat(1, 1fr);
	}
}

@media only screen and (max-width: 480px) {
	.HeroGrid {
		margin-top: 60px;
	}

	.home-cta {
		width: 100%;
	}
}

.LocationPriceHistory,
.InvestmentReturnsGraph-section {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
	margin-top: 100px;
}

.LocationPriceHistory-Group {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
	grid-gap: 50px;
}

.LocationPriceHistory-Group.in {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	width: 100%;
	grid-gap: 50px;
}
.map{
	position: relative;
}
.map iframe, .map .gmapbox {
	width: 100%;
	height: 363px;
	border: 1px solid rgb(230, 232, 234);
	border-radius: 5px;
	background-color: rgb(82, 68, 205);
}

.LocationPriceHistory-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 26px;
}

.LocationPriceHistory-title h4 {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
}

.LocationPriceHistory-title a,
.LocationPriceHistory-title span {
	font-size: 11px;
	font-family: "Montserrat";
	color: rgb(58, 109, 245);
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 500;
	cursor: pointer;
	border-bottom: 1px solid rgb(58, 109, 245);
}

.price-chart img,
.InvestmentReturnsGraph-img img {
	width: 100%;
}

@media only screen and (max-width: 991px) {
	.LocationPriceHistory-Group {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		width: 100%;
		grid-gap: 50px;
	}

	.expand-link {
		display: none;
	}
}

.edit-textfield .form-control-sm {
	padding: 0px;
	font-size: 16px;
	color: #2e3238;
}

.edit-textfield .mt-auto {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABIUlEQVQ4jY3SuUoDURgG0JMoLmBna2HpEwiClRYmVuITKMQt4AsoLglYWNi7gEmrTay0SKuChWjnE4i4IIiduBW5A+OQMbkwzB1+zsd3uZO5ur7V5urBNsZxjC18ZdvE3ahhGe8o4QAd7QR04wQ57GAEZcxiNdPiCBGewAP6kMcl7vD0X4Oo9gQWMIxHnKKCIdTTAroCzmFR47z3GMMnZnCIrWYBXbEzL2E/NptHf8AF/CQDkngvNitjLeA5/EA2gWspuBRwJeDvaJBN4DyKCbyJ9YALcQyd4X2EyYB3E3gjDUcNBjEVvgdS8J/ayQajYV/HSth/BFwN+KsZjge8YRpnsZBqqJ2K4wG9eNH4+2jcfbEVjgJecYPz8FzguRWM1i9o0Us3O+QDxwAAAABJRU5ErkJggg==);
	width: 16px;
	height: 16px;
	color: #c9cdd0;
	margin-top: 0px;
	margin-left: 14px !important;
}

.edit-textfield .mt-auto:hover {
	text-decoration: none;
}

.calculatorGraph {
	margin-top: 100px;
}

.InvesmentRCards-section {
	padding: 0 30px;
}

*,
*:after,
*:before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

dl,
ol,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
}

html {
	scroll-behavior: smooth;
}

html,
body {
	margin: 0;
	padding: 0;
	font-family: "Montserrat", "CircularStd", "Circular Std Book", "sans-serif";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #ffffff;
}

button:focus {
	border: 1px solid #ffb601;
}

.hero {
	background-repeat: no-repeat;
	width: 100%;
	background-size: cover;
	background-position: center center;
	position: relative;
	/* z-index: 11; */
	/* overflow: hidden;
	background: rgba(254, 249, 245, 0.85);
	backdrop-filter: blur(100px); */
}

a {
	text-decoration: none !important;
}

/* Note: backdrop-filter has minimal browser support */

.hero::after {
	content: "";
	position: absolute;
	background: rgba(254, 249, 245, 0.85);
	/* background-image: url(./Images/hero-bg.svg); */
	background-repeat: no-repeat;
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: -2;
}

.hero::before {
	content: "";
	position: absolute;
	background: rgba(254, 249, 245, 0.85);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	width: 50%;
	height: 100%;
	top: 0;
	right: 0;
	z-index: -1;
}

.hero-group {
	max-width: 1110px;
	margin: 0 auto;
	position: relative;
	z-index: 99;
}

.hero-text h1 {
	font-size: 45px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.344;
	font-weight: 300;
	width: 100%;
}

.table > :not(:first-child),
.table > :not(caption) > * > * {
	border: none;
}

.hero-text .bold {
	font-weight: 600;
}

.hero-text .bold-color {
	color: #38f1bf;
	font-weight: 600;
}

.hero-text p {
	font-size: 23px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.35;
	font-weight: 300;
	padding: 20px 0;
}

.mortgage-sec {
	width: 100%;
	margin-top: 140px;
	position: relative;
	background-color: #fff;
	padding: 0 30px;
}

.mortgage-group {
	text-align: center;
}

.mortgage-text h3 {
	font-size: 35px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: 600;
	line-height: 1.429;
	text-align: center;
	padding-bottom: 10px;
}

.gist-component {
	margin-bottom: 120px;
	padding-top: 140px;
}

.mortgage-text p {
	font-size: 30px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.5;
	text-align: center;
	font-weight: 300;
}

.mortgage-card-sec {
	max-width: 1260px;
	margin: 0 auto;
	padding: 0 30px;
	margin-top: 120px;
}

.mortgage-card-group {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 40px;
	width: 100%;
}

.mortgage-card-group a,
.featured-card-group a {
	text-decoration: none;
}

.featured-card-sec {
	padding-top: 200px;
	overflow: hidden;
	padding: 0 15px;
}

.section-title {
	max-width: 1110px;
	margin: 0 auto;
	text-align: center;
}

.section-title h5 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 37px;
	color: #131521;
	margin-bottom: 28px;
}

.mortgage-card-title h5 {
	margin-top: 0px;
	margin-bottom: 60px;
}

.featured-card-group {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 26px;
	max-width: 1170px;
	margin: 0 auto;
}

.whatfor {
	margin-top: 100px;
}

.benefits-card-sec,
.stories-sec {
	margin-top: 200px;
}

.requirements-card-sec,
.benefits-card-sec {
	padding: 0 30px;
}

.howitworks-card-sec {
	margin-top: 140px;
	padding: 0 30px;
}

.benefit-card-group,
.featued-card-group,
.requirements-card-group,
.stories-group {
	margin-top: 60px;
}

.footer-section {
	/* background-color: rgb(48, 57, 78); */
	width: 100%;
	padding: 88px 0;
}

.fyLrfx {
	display: none !important;
}

.bg-ffffff {
	background-color: #ffffff;
}

@media only screen and (max-width: 991px) {
	.mortgage-card-group {
		grid-template-columns: repeat(1, 1fr);
	}

	.featured-card-group {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media only screen and (max-width: 640px) {
	.featured-card-group {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 30px;
	}

	.benefits-card-sec {
		margin-top: 140px;
	}

	.featured-card-sec {
		padding-top: 120px;
	}

	.benefit-card-group,
	.featued-card-group,
	.requirements-card-group,
	.stories-group {
		margin-top: 35px;
	}

	.benefits-card-sec,
	.stories-sec {
		margin-top: 100px;
	}

	.requirements-card-sec,
	.benefits-card-sec {
		padding: 0 15px;
	}

	.footer-section {
		padding-bottom: 80px;
	}

	.hero {
		background-position: 80%;
	}

	.featured-card-sec {
		padding: 0px;
	}
}

/*============================*/
/*ROTATING WORDS*/
/*============================*/

.cd-words-wrapper b {
	font-size: 45px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.444;
	font-weight: 300;
}

.cd-words-wrapper {
	display: inline-block;
	position: relative;
	/* float: left !important; */
	text-align: left;
}

.cd-words-wrapper b {
	display: inline-block;
	position: absolute;
	white-space: nowrap;
	left: 0;
	top: 0;
}

.hero-title .cd-words-wrapper {
	margin-right: 16px;
}

.cd-words-wrapper b.is-visible {
	position: relative;
}

.no-js .cd-words-wrapper b {
	opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
	opacity: 1;
}

.cd-headline.slide .cd-words-wrapper {
	overflow: hidden;
	vertical-align: top;
}

.cd-words-wrapper-2 b {
	font-size: 23px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.304;
	font-weight: 300;
	margin-top: 27px;
	display: block;
	padding-bottom: 6px;
}

.cd-words-wrapper-2 {
	display: block;
	position: relative;
	float: left !important;
	text-align: left;
	display: block;
}

.cd-words-wrapper-2 b {
	display: inline-block;
	position: absolute;
	left: 0;
	top: 0;
	display: block;
}

.cd-words-wrapper-2 b.is-visible {
	position: relative;
}

.no-js .cd-words-wrapper-2 b {
	opacity: 0;
}

.no-js .cd-words-wrapper-2 b.is-visible {
	opacity: 1;
}

.cd-headline.slide .cd-words-wrapper-2 {
	overflow: hidden;
	vertical-align: top;
}

.cd-headline.slide b {
	opacity: 0;
	top: 0.2em;
}

.Sold-btn {
	position: relative;
	z-index: 10;
	margin-top: 100px;
}

.Sold-btn::after {
	content: "";
	position: absolute;
	background-image: url(/static/media/diamond-shape.346c6f76.svg);
	background-repeat: no-repeat;
	width: 152px;
	height: 155px;
	bottom: -100px;
	left: -40px;
	z-index: -1;
}

.Sold-btn .blue-btn {
	text-decoration: none;
	display: block;
	font-size: 25px;
	font-family: "CircularStd";
	font-weight: bold;
	color: #fff;
	line-height: 32px;
	outline: none;
	background: none;
	border: none;
	width: 350px;
	height: 80px;
	margin: 0 auto;
	background: #3864e4;
	border-radius: 70px;
	transition: all 0.4s ease-in-out;
}

.Sold-btn .blue-btn:hover {
	background: #1e4acf;
}

/* -------------------------------- 

xrotate-1 

-------------------------------- */
.cd-headline.rotate-1 .cd-words-wrapper {
	-webkit-perspective: 300px;
	perspective: 300px;
}

.cd-headline.rotate-1 b {
	opacity: 0;
	-webkit-transform-origin: 50% 100%;
	transform-origin: 50% 100%;
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
}

.cd-headline.rotate-1 b.is-visible {
	opacity: 1;
	-webkit-transform: rotateX(0deg);
	transform: rotateX(0deg);
	-webkit-animation: cd-rotate-1-in 1.2s;
	animation: cd-rotate-1-in 1.2s;
}

.cd-headline.rotate-1 b.is-hidden {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
	-webkit-animation: cd-rotate-1-out 1.2s;
	animation: cd-rotate-1-out 1.2s;
}

@-webkit-keyframes cd-rotate-1-in {
	0% {
		-webkit-transform: rotateX(180deg);
		opacity: 0;
	}

	35% {
		-webkit-transform: rotateX(120deg);
		opacity: 0;
	}

	65% {
		opacity: 0;
	}

	100% {
		-webkit-transform: rotateX(360deg);
		opacity: 1;
	}
}

@keyframes cd-rotate-1-in {
	0% {
		-webkit-transform: rotateX(180deg);
		transform: rotateX(180deg);
		opacity: 0;
	}

	35% {
		-webkit-transform: rotateX(120deg);
		transform: rotateX(120deg);
		opacity: 0;
	}

	65% {
		opacity: 0;
	}

	100% {
		-webkit-transform: rotateX(360deg);
		transform: rotateX(360deg);
		opacity: 1;
	}
}

@-webkit-keyframes cd-rotate-1-out {
	0% {
		-webkit-transform: rotateX(0deg);
		opacity: 1;
	}

	35% {
		-webkit-transform: rotateX(-40deg);
		opacity: 1;
	}

	65% {
		opacity: 0;
	}

	100% {
		-webkit-transform: rotateX(180deg);
		opacity: 0;
	}
}

@keyframes cd-rotate-1-out {
	0% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
		opacity: 1;
	}

	35% {
		-webkit-transform: rotateX(-40deg);
		transform: rotateX(-40deg);
		opacity: 1;
	}

	65% {
		opacity: 0;
	}

	100% {
		-webkit-transform: rotateX(180deg);
		transform: rotateX(180deg);
		opacity: 0;
	}
}

.hero-group .hero-title {
	font-size: 45px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.444;
	font-weight: 300;
	width: 100%;
}

.hero-title h1 {
	font-weight: 600;
}

.light-text {
	font-weight: 300;
	font-size: 42px;
}

a:not([href]):not([tabindex]) {
	color: #cba3e6;
	text-decoration: none;
}

/* -------------------------------- 

xslide 

-------------------------------- */
.cd-headline.slide .cd-words-wrapper {
	overflow: hidden;
	vertical-align: top;
}

.cd-headline.slide b {
	opacity: 0;
	top: 0.2em;
}

.cd-headline.slide b.is-visible {
	top: 0;
	opacity: 1;
	-webkit-animation: slide-in 0.6s;
	animation: slide-in 0.6s;
}

.cd-headline.slide b.is-hidden {
	-webkit-animation: slide-out 0.6s;
	animation: slide-out 0.6s;
}

@-webkit-keyframes slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
	}

	60% {
		opacity: 1;
		-webkit-transform: translateY(20%);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@keyframes slide-in {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}

	60% {
		opacity: 1;
		-webkit-transform: translateY(20%);
		transform: translateY(20%);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@-webkit-keyframes slide-out {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}

	60% {
		opacity: 0;
		-webkit-transform: translateY(120%);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(100%);
	}
}

@keyframes slide-out {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}

	60% {
		opacity: 0;
		-webkit-transform: translateY(120%);
		transform: translateY(120%);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
}

/* -------------------------------- 

xloading-bar 

-------------------------------- */

.cd-headline.loading-bar .cd-words-wrapper {
	overflow: hidden;
	vertical-align: top;
}

.cd-headline.loading-bar .cd-words-wrapper::after {
	/* loading bar */
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	height: 3px;
	width: 0;
	background: #38f1bf;
	z-index: 2;
	transition: width 0.3s -0.1s;
}

.cd-headline.loading-bar .cd-words-wrapper.is-loading::after {
	width: 100%;
	transition: width 5s;
}

.cd-headline.loading-bar b {
	/* top: 0.1em; */
	opacity: 0;
	transition: opacity 0.3s;
}

.cd-headline.loading-bar b.is-visible {
	opacity: 1;
	top: 0;
}

@media only screen and (max-width: 640px) {
	.popup-btn button.closes,
	.popup-btn button.ask-question {
		width: 24px !important;
		margin-top: 10px !important;
	}
}

@media only screen and (max-width: 600px) {
	.cd-words-wrapper-2 b,
	.cd-words-wrapper-2 {
		width: 100%;
		display: inline-block;
		white-space: pre-wrap;
		/* font-size: 18px; */
	}
}

.d-modal-heading {
	font-size: 20px;
	font-weight: 700;
	line-height: 1.3;
	padding-bottom: 25px;
}

.d-modal-text {
	font-size: 14px;
	line-height: 1.857;
	font-family: Montserrat;
	color: #2e3238;
}

.clickable-link {
	cursor: pointer !important;
}

.newsletter-sec {
	margin-top: 100px;
	padding: 0 30px;
}

.modal {
	overflow: initial !important;
}

.hero-btn {
	text-decoration: none;
	display: block;
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	text-transform: uppercase;
	line-height: 1.2;
	outline: none;
	background: none;
	border: none;
	border-radius: 30px;
	padding: 10px 30px;
	background-image: linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.2);
	transition: all 0.3s ease-in-out;
}

.hero-btn:hover {
	background: #fff;
	color: #202020;
	box-shadow: 0px 10px 30px 0px rgba(255, 255, 255, 0.3);
	cursor: pointer;
	outline: none;
}

.error {
	color: red;
}

.range-vertical .input-range__slider {
	border: 1px solid #5ad4ee !important;
}

.popup-btn button.closes {
	background: #fff;
	border: 1px solid #000;
	width: 24px;
	height: 24px;
	position: relative;
	color: #202020;
	font-weight: 800;
	border-radius: 50%;
	position: absolute;
	top: -11px;
	right: -10px;
}

.rent-table-sec,
.requirement-section {
	padding-top: 200px;
	padding-left: 30px;
	padding-right: 30px;
}

.requirement-section {
	padding-top: 0;
}

.loan-table-sec {
	padding-top: 200px;
	padding-left: 30px;
	padding-right: 30px;
}

.financial-model-sec {
	padding-top: 140px;
}

@media only screen and (max-width: 580px) {
	.rent-table-sec,
	.requirement-section,
	.financial-model-sec {
		padding-top: 100px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.financial-model-sec {
		padding-left: 0px;
		padding-right: 0px;
	}

	.accordion__panel .canvasjs-chart-canvas {
		width: 100% !important;
	}

	.loan-table-sec {
		padding-top: 100px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.gist-component {
		margin-bottom: 60px;
		padding-top: 120px;
	}
}

/* slick styling */

.region_card_container .slick-slider{
	max-height: 280px !important;
}

.region_card_container .slick-list .slick-track{
	height: 300px !important;
}

#react-select-3-input, #react-select-2-input {
	opacity:1 !important
}

.Header {
	position: absolute;
	top: 0;
	width: 100%;
	padding: 40px 30px;
	z-index: 100;
	transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.fullheader {
	padding: 27px 40px;
	top: 0;
}

.FullHeaderSticky,
.HeaderScrolled {
	background: rgba(255, 255, 255);
	height: 80px;
	z-index: 11111 !important;
	position: fixed;
	width: 100%;
	padding: 26px 30px;
	z-index: 100;
	top: 0px;
	transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
	border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.headergroup {
	display: grid;
	grid-template-columns: 1fr 3fr;
	max-width: 1110px;
	margin: 0 auto;
	align-items: center;
}

.fullheadergroup {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	max-width: 100%;
	margin: 0 auto;
	align-items: center;
}

.brand img {
	transition: 2s ease-in-out;
	width: 110px;
}

.nav-links {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.nav-btn {
	text-decoration: none !important;
}

.nav-links button {
	text-decoration: none;
	display: block;
	font-size: 14px;
	font-family: "CircularStd";
	font-weight: bold;
	color: rgb(255, 255, 255);
	line-height: 1.2;
	outline: none;
	background: none;
	border: none;
	width: 152px;
	height: 45px;
	background: #000000;
	border-radius: 33px;
	transition: all 0.4s ease-in-out;
}

.nav-links button:hover {
	background: #202020;
	color: #fff;
	box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.3);
	cursor: pointer;
	outline: none;
}

.nav-links2 button:hover {
	background-image: linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	color: #ffffff;
	box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.5);
	cursor: pointer;
	outline: none;
}

.nav-links button:focus {
	outline: none;
}

@media only screen and (max-width: 600px) {
	.nav-links a {
		font-size: 13px;
	}

	.cl-effect a span::before {
		font-size: 12px;
	}

	/* .Header {
		padding: 20px 30px;
	} */

	.fullheader {
		padding: 15px 30px;
	}
}

.HeaderScrolled {
	background: rgba(255, 255, 255, 0.9);
	padding: 17px 30px;
	-webkit-backdrop-filter: blur(8px);
	        backdrop-filter: blur(8px);
}

.Header img {
	transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.HeaderScrolled .brand img {
	width: 90px;
}

/* .HeaderScrolled .nav-links button {
	padding: 10px 30px;
	font-size: 12px;
} */

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 900px;
	}
}

.signup-modal {
	max-width: 620px;
}

.modal-content {
	border-radius: 20px;
}

.modal-backdrop {
	z-index: 99;
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}

.modal-header {
	background-color: rgb(225, 227, 230, 0.2);
	height: 87px;
	width: 100%;
	border-radius: 25px 25px 0 0;
	position: relative;
	border: none;
	display: grid;
	grid-gap: 8px;
	padding: 20px 40px;
	grid-template-columns: 1fr;
}

.modal-body {
	padding: 0px;
	padding-bottom: 10px;
}

.modal-body {
	max-width: 900px;
}

.modal-content .modal-body {
	border-radius: 0px;
}

.modal-header-text {
	padding: 16px 20px 0 40px;
}

.modal-header-text h6 {
	font-size: 22px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: bold;
	line-height: 1.4;
}

.modal-header p {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.4;
}

.close {
	position: absolute;
	top: 30px;
	right: 30px;
	border: none;
	background: none;
	outline: none;
}

.close:focus {
	outline: none;
}

button {
	outline: none;
}

.s-close-btn:hover {
	cursor: pointer;
}

.s-close-btn img {
	width: 28px;
	height: 28px;
}

.modal-checkboxes {
	padding: 40px;
}

.modal-checkboxes p {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(41, 51, 53);
	line-height: 1.2;
	font-weight: bold;
	padding-bottom: 10px;
}

.checkboxes-wrapper {
	display: flex;
	justify-content: space-between;
	max-width: 485px;
}

.checkbox-name {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(76, 76, 76);
	line-height: 1.2;
	display: flex;
	align-items: center;
}

.checkbox-name {
	position: relative;
	left: 5px;
}

.custom-control-label::before {
	position: absolute;
	top: 1px !important;
	left: -1.5rem;
	display: block;
	width: 16px;
	height: 16px;
	margin-right: 5px;
	pointer-events: none;
	content: "";
	background-color: #fff;
	border: #adb5bd solid 1px;
}

.custom-control-label::after {
	left: -24px;
	top: 1px !important;
}

.modal-header h5 {
	font-size: 20px;
}

.checkbox-text {
	display: block;
	padding-left: 11px;
}

.signup-inputs li {
	list-style: none;
	display: flex;
	flex-direction: column;
}

.signup-form {
	padding: 0 40px;
}

.signup-inputs {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 30px;
}

.input-label {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(41, 51, 53);
	line-height: 1.2;
	padding-bottom: 0px;
}

::-webkit-input-placeholder {
	color: rgb(183, 188, 192);
	font-weight: 300;
}

::placeholder {
	color: rgb(183, 188, 192);
	font-weight: 300;
}

input:focus::-webkit-input-placeholder {
	color: transparent;
}

input:focus::placeholder {
	color: transparent;
}

ul.signup-inputs li input {
	border: none;
	outline: none;
	width: 100%;
	height: 44px;
	font-size: 14px;
	color: #202020;
	outline: none;
	background-color: rgb(255, 255, 255);
	border: 1px solid rgb(225, 227, 230);
	border-radius: 4px;
	padding: 0 17px;
	padding-bottom: 2px;
}

ul.signup-inputs li input:focus {
	border: 1px solid #4756f7;
	box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.15);
}

.btn-wrapper {
	padding: 35px 0 40px 0;
	display: flex;
}

.modal-footer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	padding-top: 20px;
	border: none;
}

.success-msg-body {
	padding: 40px;
	text-align: center;
}

.invitation-img {
	padding-bottom: 40px;
}

.invi-title {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(82, 188, 180);
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	padding-bottom: 25px;
}

.invi-thanku,
.invi-email {
	font-size: 16px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	line-height: 1.625;
	text-align: center;
}

.invi-color-bold {
	color: #6539c8;
}

.modal-open .modal {
	background-color: rgba(48, 57, 78, 0.9);
	/* overflow: inherit; */
	/* backdrop-filter: blur(3px); */
}

.signup-btn {
	border: none;
	outline: none;
	background: none;
	border-radius: 4px;
	font-size: 15px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.2;
	background-image: linear-gradient(
		-60deg,
		rgb(51, 160, 223) 0%,
		rgb(51, 136, 223) 100%
	);
	background-image: -ms-linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	box-shadow: 0px 6px 25px 0px rgba(45, 10, 124, 0.2);
	width: 150px;
	height: 42px;
	margin: 0 auto;
	transition: all 0.3s ease-in-out;
}

.signup-btn:hover {
	-webkit-transform: translateY(-3px);
	        transform: translateY(-3px);
	box-shadow: 0px 6px 25px 0px rgba(51, 136, 223, 0.5);
	cursor: pointer;
}

@-webkit-keyframes boat {
	50% {
		-webkit-transform: rotate(-5deg) translateY(-10px);
		transform: rotate(-5deg) translateY(-10px);
	}
}

@keyframes boat {
	50% {
		-webkit-transform: rotate(-5deg) translateY(-10px);
		transform: rotate(-5deg) translateY(-10px);
	}
}

.hamburger {
	color: #000;
	position: absolute;
	right: 30px;
	font-size: 16px;
	display: none;
	cursor: pointer;
	z-index: 111111;
}

@media only screen and (max-width: 840px) {
	.modal-main {
		width: 90%;
		margin: 0 auto;
	}

	.hamburger {
		display: inherit;
	}

	.HeaderScrolled {
		height: 60px;
	}

	.Nav-links .number {
		position: relative;
		top: -40px;
	}

	.nav-links {
		position: absolute;
		background: #fff;
		width: 100%;
		height: 310px;
		display: flex;
		flex-direction: column-reverse;
		top: 0;
		left: 0;
		right: 0;
		text-align: center;
		border-bottom: 1px solid #d4d4d4;
		display: none;
		z-index: 99;
	}

	.login-link {
		margin-top: 35px;
	}

	.nav-links.in {
		display: inherit;
	}

	.brand img {
		position: relative;
		z-index: 100;
	}

	.f-slash {
		display: none;
	}

	.Nav-links {
		position: absolute;
		top: 130px;
		left: 30px;
		line-height: 34px;
	}

	.modal-header p {
		max-width: 450px;
		padding-right: 60px;
	}

	.signup-inputs {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 20px;
	}

	.nav-links button {
		font-size: 12px;
		width: 92%;
		position: absolute;
		bottom: 20px;
		font-size: 17px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 580px) {
	.hamburger {
		right: 15px;
	}

	/* .Header {
		padding: 40px 30px !important;
	} */
}

@media only screen and (max-width: 480px) {
	.modal-header p {
		font-size: 12px;
	}

	.Nav-links {
		left: 15px;
		top: 120px;
	}

	.signup-btn {
		width: 100%;
	}

	.close {
		right: 24px;
	}

	/* .nav-links button {
		font-size: 10px;
		font-weight: 700;
	} */

	ul.signup-inputs li input {
		height: 35px;
		font-size: 12px;
	}

	.modal-checkboxes {
		padding: 24px;
	}

	.modal-header {
		padding: 15px 24px;
	}

	.btn-wrapper {
		padding: 30px 0 30px 0;
	}

	.signup-form {
		padding: 0 24px;
	}

	.modal-header-text {
		padding: 16px 20px 0 24px;
	}

	.input-label {
		font-size: 11px;
	}

	.checkbox-name {
		font-size: 12px;
	}

	.s-close-btn {
		right: 24px;
	}

	/* .Header {
		padding: 10px 20px;
	} */

	/* .HeaderScrolled .nav-links button {
		font-size: 10px;
	} */
}

.main-modal-wrapper {
	padding: 10px 10px 0 10px;
}

.main-modal-wrapper iframe {
	width: 900px;
	max-width: 100%;
	height: 500px;
	border: none;
}

@media only screen and (max-width: 670px) {
	.modal-dialog {
		max-width: 100%;
		margin: 0 24px;
	}

	.main-modal-wrapper {
		padding: 30px 30px 0 30px;
	}

	.modal-body {
		padding-bottom: 30px;
	}
}

.CalculatorGroup {
	max-width: 1230px;
	margin: 0 auto;
	margin-top: 195px;
	padding: 0 30px;
}

.Nav-links {
	margin-right: 50px;
}

.Nav-links a,
.Nav-links span {
	color: #5b607d;
	text-decoration: none;
	font-size: 16px;
	font-family: "Circular Std Book";
	transition: all 0.3s ease-in-out;
}

.Nav-links a .bold {
	color: #31354e;
	font-weight: 500;
}

.f-slash {
	margin: 0 20px;
}

.live-link {
	display: inline-block;
	color: #000;
	text-decoration: none;
}

.live-link::after {
	content: "";
	display: block;
	width: 0;
	height: 1px;
	background: #000;
	transition: width 0.3s;
}

.live-link:hover::after {
	width: 100%;
}

@media only screen and (max-width: 520px) {
	/* .Nav-links {
		margin-right: 10px;
	}

	.Nav-links a {
		margin: 0 10px;
	} */

	.FullHeaderSticky {
		height: 70px;
		padding: 10px 30px;
	}
}

.signup-modal .modal-content {
	padding-bottom: 20px;
}

@media only screen and (max-width: 992px) {
	.Nav-links {
		margin-right: 30px;
	}
}

.footer {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
}

.footergroup {
	display: flex;
	justify-content: space-between;
	align-content: space-between;
	align-items: center;
	width: 100%;
}

.footer-sub-sec {
	display: flex;
	align-items: center;
}

.footer-sub-sec .footer-logo {
	margin-right: 20px;
}

.footer h6 {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(92, 101, 123);
	text-transform: uppercase;
	line-height: 1.538;
	padding: 28px 0 20px 0;
	font-weight: 700;
}

.footer-sub-sec p {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #5b607d;

	font-family: "Circular Std Book";
	margin-bottom: 0;
}

.footer-sub-sec a {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.429;
	text-decoration: none;
}

.footer-link li,
.footer-link li a {
	list-style: none;
	text-decoration: none;
	font-size: 14px;
	font-family: "Montserrat";
	color: rgba(255, 255, 255, 0.8);
	font-weight: 400;
	display: block;
	text-transform: uppercase;
}

.footer-social-icon li,
.footer-social-icon li a {
	list-style: none;
	text-decoration: none;
	display: inline-block;
}

.footer-social-icon li a svg {
	width: 32px;
	margin-top: 10px;
	margin-right: 10px;
}

.footer-social-icon svg {
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.footer-social-icon svg:hover {
	-webkit-transform: translateY(-3px);
	transform: translateY(-3px);
}

.footer-sub-sec .email-link,
.footer-sub-sec .email-link a {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #4c506b;
	font-family: Circular Std Book;
	list-style: none;
	transition: all 0.3s ease-in-out;
}

.footer-sub-sec .email-link a:hover {
	color: #131521;
}

.footer-sub-sec .email-link {
	display: flex;
	justify-content: center;
	align-items: center;
	grid-gap: 30px;
	gap: 30px;
}

.footer-sub-sec .email-link svg {
	margin-right: 10px;
}

.footer-sub-sec .email-link .devider svg {
	margin-right: 0;
}

.twitter,
.fb,
.l-in,
.med {
	fill: #77829c;
	fill-rule: evenodd;
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.footer-social-icon svg:hover .twitter {
	fill: #55acee;
}

.footer-social-icon svg:hover .fb {
	fill: #3b5999;
}

.footer-social-icon svg:hover .l-in {
	fill: #0077b5;
}

.footer-social-icon svg:hover .med {
	fill: #02b875;
}

.cl-effect li a {
	overflow: hidden;
	height: 1.2em;
	margin-top: 12px;
}

.cl-effect li a span {
	position: relative;
	display: block;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}

.cl-effect li a span::before {
	position: absolute;
	top: 100%;
	content: attr(data-hover);
	font-weight: 700;
	font-size: 13.5px;
	color: #10e6ac;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.cl-effect li a:hover span,
.cl-effect li a:focus span {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
}

.join-newsletter-form input {
	border-radius: 4px;
	background-color: rgb(255, 255, 255);
	width: 232px;
	width: 100%;
	height: 40px;
	border: none;
	outline: none;
	font-size: 14px;
	font-family: "Montserrat";
	border: 1px solid rgba(56, 241, 191, 0);
	color: rgb(32, 32, 32);
	line-height: 2.286;
	padding: 0 30px 0 15px;
	position: relative;
}

.join-newsletter-form {
	position: relative;
	margin-top: 10px;
}

/* .footer-logo img {
	width: 110px;
	margin-bottom: 15px;
} */

.join-newsletter-form button {
	position: absolute;
	height: 40px;
	top: 0;
	right: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	background: #fff;
}

.join-newsletter-form button:hover {
	cursor: pointer;
}

.contact-link,
.contact-link a {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(152, 161, 182);
	line-height: 2.462;
	font-weight: 300;
	margin-top: 10px;
	text-decoration: none;
}

.join-newsletter-form a {
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.contact-link a:hover {
	color: #10e6ac;
}

.newsletter-hidden {
	position: absolute;
	left: -5000px;
}

li.f-email-add {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.429;
	font-weight: 400;
	padding: 6px 0;
}

@media only screen and (max-width: 940px) {
	.footergroup {
		grid-template-columns: repeat(2, 1fr);
	}

	.footer-sub-sec:nth-child(3),
	.footer-sub-sec:nth-child(4) {
		margin-top: 60px;
	}
}

@media only screen and (max-width: 767px) {
	.footergroup {
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}

	.footer-sub-sec:nth-child(2),
	.footer-sub-sec:nth-child(3),
	.footer-sub-sec:nth-child(4) {
		margin-top: 20px;
	}

	.footer-sub-sec:nth-child(1) {
		padding: 0 0 10px 0;
	}

	.footer-sub-sec:nth-child(2) {
		justify-self: center;
	}
}

.newsletterwrapper {
	background-color: rgb(48, 57, 78);
	max-width: 870px;
	/* padding: 27px 50px; */
	margin: 0 auto;
	border-radius: 50px;
	position: relative;
}

.newsletter-btn {
	position: absolute;
	top: 31%;
	right: 50px;
	width: 40px;
	height: 40px;
	background: none;
	border: none;
	outline: none;
	border-radius: 50%;
	background-image: linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	box-shadow: 0px 6px 25px 0px rgba(45, 10, 124, 0.2);
	transition: all 0.3s ease-in-out;
}

.newsletter-btn img {
	position: relative;
	top: -1px;
	transition: all 0.8s ease;
}

.newsletter-btn:hover img {
	-webkit-animation: arrrow-animate 0.8s ease infinite;
	animation: arrrow-animate 0.8s ease infinite;
}

@-webkit-keyframes arrrow-animate {
	0% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
	}
	50% {
		-webkit-transform: translateX(2px);
		        transform: translateX(2px);
	}
	75% {
		-webkit-transform: translateX(-2px);
		        transform: translateX(-2px);
	}
	100% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
	}
}

@keyframes arrrow-animate {
	0% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
	}
	50% {
		-webkit-transform: translateX(2px);
		        transform: translateX(2px);
	}
	75% {
		-webkit-transform: translateX(-2px);
		        transform: translateX(-2px);
	}
	100% {
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
	}
}

.newsletter-btn:hover {
	background: none;
	border: none;
	outline: none;
	background-image: linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
}

.newsletter-btn2 {
	position: absolute;
	top: 31%;
	right: 50px;
	width: 152px;
	height: 40px;
	background: none;
	border: none;
	outline: none;
	border-radius: 20px;
	background-image: linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	box-shadow: 0px 6px 25px 0px rgba(45, 10, 124, 0.2);
}

.newsletter-btn2:hover img {
	-webkit-animation: arrrow-animate 0.8s ease infinite;
	animation: arrrow-animate 0.8s ease infinite;
}

.subscribe-btn {
	padding-right: 9px;
}
.inputwrapper {
	padding: 27px 50px;
}

.inputwrapper h4 {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.3;
	padding-bottom: 10px;
}

.inputwrapper p {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(118, 128, 153);
	line-height: 1.4;
}

.iw-hover:hover {
	cursor: pointer;
}

.iw-hover {
	cursor: pointer;
	padding: 27px 50px;
}

.input-label,
.email-r-title {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(118, 128, 153);
	line-height: 1.429;
}

.email-feild {
	border: none;
	outline: none;
	background: none;
	color: #fff;
	width: 60%;
	font-size: 20px;
}

.email-feild:focus,
.email-feild:active {
	outline: none;
	border: none;
	background: none;
}

.email-feild::-webkit-input-placeholder {
	color: rgb(233, 233, 233);
	font-size: 14px;
	opacity: 1;
}

.email-feild::placeholder {
	color: rgb(233, 233, 233);
	font-size: 14px;
	opacity: 1;
}

.tick-mark {
	border-radius: 50%;
	background-color: rgb(118, 128, 153);
	padding: 9px 10px;
	margin-right: 25px;
}

.thanku-textGroup {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 600px) {
	.inputwrapper {
		padding-right: 70px;
	}

	.footer {
		padding: 0 20px;
	}

	.footer-sub-sec .email-link {
		grid-gap: 15px;
		grid-gap: 15px;
		gap: 15px;
	}

	.inputwrapper2 {
		padding-right: 0px;
	}

	.inputwrapper h4 {
		font-size: 16px;
	}

	.inputwrapper {
		padding: 27px 30px;
	}

	.newsletter-btn {
		right: 30px;
	}

	.email-feild {
		width: 100%;
	}

	.newsletter-btn2 {
		position: relative;
		width: 86%;
		margin-top: 30px;
		right: 0;
		left: 0;
		top: -30px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (max-width: 428px) {
	.resend-link {
		margin-left: 0px;
	}

	.email-feild {
		font-size: 16px;
	}

	.tick-mark {
		margin-right: 15px;
	}
}

.cursor {
	position: relative;
}
.cursor i {
	position: absolute;
	width: 1px;
	height: 80%;
	left: 0;
	background-color: #fff;
	top: 10%;
	-webkit-animation-name: blink;
	        animation-name: blink;
	-webkit-animation-duration: 900ms;
	        animation-duration: 900ms;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	opacity: 1;
}

.cursor input:focus + i {
	display: none;
}

/* .join-newsletter-form input {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  width: 232px;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: "Montserrat";
  border: 1px solid rgba(56, 241, 191, 0);
  color: rgb(32, 32, 32);
  line-height: 2.286;
  padding: 0 30px 0 15px;
  position: relative;
}

.join-newsletter-form input:focus {
  border: 1px solid #10e6ac;
}

.join-newsletter-form {
  position: relative;
  margin-top: 10px;
}

.join-newsletter-form button {
  position: absolute;
  height: 40px;
  top: 0;
  right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  outline: none;
  background: transparent;
}

.join-newsletter-form button:hover {
  cursor: pointer;
} */

.introjs-tooltipReferenceLayer * {
	font-family: "Circular Std Book", "CircularStd";
}

.introjs-overlay {
	/* backdrop-filter: blur(5px); */
	opacity: 1 !important;
	background-color: rgba(48, 57, 78, 0.9);
}

.introjs-tooltip {
	max-width: 365px !important;
	width: 365px !important;
	min-width: 200px !important;
	display: flex;
	justify-content: center;
	background: #ffffff !important;
	border-radius: 18px !important;
	left: 0 !important;
}

.introjs-arrow.right-bottom {
	bottom: 23px !important;
}

.introjs-tooltip .introjs-tooltiptext {
	padding: 28px 28px 20px 28px;
}

.introjs-tooltip .introjs-tooltipbuttons {
	padding: 16px 28px;
	border-radius: 0 0 18px 18px;
	background: rgba(237, 237, 237, 0.4);
	display: flex;
	justify-content: flex-end;
}

.introjs-tooltip .introjs-tooltip-header {
	position: absolute;
	bottom: 20px;
}

.introjs-tooltip .introjs-skipbutton {
	opacity: 1;
	position: relative;
	z-index: 1;
}

.introjs-tooltip .introjs-skipbutton::after {
	content: "Exit";
	position: absolute;
	top: 0;
	left: 0;
	font-size: 16px;
	color: #3864e4;
	opacity: 1 !important;
	z-index: 1111;
	background: #f8f8f8;
	padding: 10px;
	font-size: 14px;
	line-height: 18px;
	font-family: "Circular Std Book";
}

.introjs-tooltip .introjs-skipbutton:hover::after {
	color: #244dc7;
}

div.introjs-arrow.left-bottom {
	bottom: 25px !important;
}

div.introjs-arrow.bottom,
div.introjs-arrow.top {
	left: 25px !important;
}

.introjs-tooltip .introjs-button {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 60px;
	min-width: 98px;
	font-style: normal;
	font-weight: bold;
	padding: 9px 0;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #000000;
	text-shadow: none;
	font-family: "CircularStd";
	transition: all 0.3s ease-in-out;
}

.introjs-tooltip .introjs-button:focus {
	box-shadow: inherit;
	outline: none;
}

.introjs-tooltip .introjs-nextbutton {
	background: #3864e4;
	color: #fff;
	margin-left: 16px;
}

.introjs-tooltip .introjs-button:hover {
	background: #244dc7;
	color: #ffffff;
	border: 1px solid #244dc7;
}

.introjs-tooltip h6 {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #131521;
	font-family: "CircularStd";
}

.introjs-tooltip p {
	font-style: normal;
	font-weight: 450;
	font-size: 15px;
	line-height: 23px;
	padding-top: 12px !important;
	font-family: "Circular Std Book";
}

.introjs-tooltip .introjs-bullets {
	padding-top: 0;
	padding-bottom: 20px;
}

.introjs-tooltip .introjs-bullets ul li a {
	background: rgba(0, 0, 0, 0.32);
}

.introjs-tooltip .introjs-bullets ul li a.active {
	width: 16px;
	background: #7195fc;
}

.introjs-tooltip.introjs-bullets ul li a:focus,
.introjs-tooltip .introjs-bullets ul li a:hover {
	background: #7195fc;
}

@media only screen and (min-width: 800) {
	.introjs-tooltip {
		max-width: 200px !important;
		width: 200px !important;
		min-width: 200px !important;
	}
}

@media (max-width: 480px) {
	.introjs-tooltip {
		max-width: 100% !important;
		width: 100% !important;
	}
}
.equity-graph,
.eit-2 {
	text-align: center;
	justify-content: center !important;
}

.equity-graph-sec {
	padding: 0 30px;
	position: relative;
	z-index: 10;
	/* overflow: hidden; */
}
.graph-slider.dstart {
	overflow: hidden;
}

/* .equity-graph-sec::after {
	content: "";
	position: absolute;
	background-image: url(../Images/line-equity-sec.svg);
	background-repeat: no-repeat;
	top: -630px;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
	z-index: -1;
} */

.equity-graph-wrapper {
	max-width: 1110px;
	margin: 0 auto;
}

.equity-graph-wrapper .linegraph-heading {
	margin-top: 50px;
	text-align: left;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #131521;
	margin-bottom: 20px;
}

.equity-graph-wrapper .exuity-para {
	max-width: 650px;
	font-style: normal;
	font-weight: 450;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	color: #4c506b;
	font-family: "Circular Std Book";
	margin: 0 auto;
	padding: 0 30px;
	padding-bottom: 18px;
	padding-top: 24px;
}

.equity-graph-wrapper .nav {
	justify-content: center;
	border: 1px solid rgba(236, 237, 243, 0.55);
	border-radius: 60px;
	background: rgba(236, 237, 243, 0.55);
	width: 278px;
	margin: 0 auto;
	margin-top: 30px;
	overflow: hidden;
}

.equity-graph-wrapper .line-graph {
	margin-top: 0;
	margin-bottom: 60px;
	height: 550px;
}

.section-title-2 {
	margin-top: 100px;
}

.equity-graph-wrapper .nav-tabs .nav-item {
	margin: 0;
}

.equity-graph-wrapper h3 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 38px;
	letter-spacing: 0.5px;
	color: #131521;
}

.equity-graph-wrapper p {
	color: #5b607d;
	font-style: normal;
	font-family: "Circular Std Book";
	font-weight: normal;
	font-size: 18px;
	margin-top: 30px;
	line-height: 33px;
}

.equity-graph-wrapper .nav-tabs .nav-link {
	border: none;
	border-radius: none;
	padding: 0;
	margin: 0;
	background: none;
	text-align: center;
	color: #4c506b;
	font-family: "CircularStd";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	width: 138px;
	/* background: rgba(236, 237, 243, 0.55); */
	padding: 12px 0;
	border-radius: 60px;
	text-transform: capitalize;
}

.equity-graph-wrapper .nav-tabs .nav-item.show .nav-link,
.equity-graph-wrapper .nav-tabs .nav-link.active {
	border: none;
	background: #ffffff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	border-radius: 60px;
	color: #3864e4;
	padding: 12px 0;
}

.graph-slider {
	/* display: flex;
	gap: 30px; */
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
}

.disabled-slider {
	opacity: 0.5;
}

.equity-graph-wrapper .nav-tabs .nav-link:focus,
.equity-graph-wrapper .nav-tabs .nav-link:hover {
	border: none;
	cursor: pointer;
}

.equity-graph-sec h5 {
	margin-bottom: 28px;
}

.equity-graph-sec p {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	color: #5b607d;
}

.nav-tabs {
	position: relative !important;
	top: 0 !important;
	z-index: 1 !important;
}

.cash-tab,
.equity-tab {
	max-width: 1110px;
	margin: 0 auto;
}

.equity-input {
	min-width: 30%;
	padding: 20px 25px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.07);
	box-sizing: border-box;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.equity-graph-wrapper h6 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #131521;
	margin-top: 44px;
	margin-bottom: 32px;
}

.equity-input-text {
	display: flex;
	grid-gap: 40px;
	justify-content: space-between !important;
	align-items: center !important;
	/* margin-bottom: 15px; */
}

.equity-text {
	width: 12rem;
}

.sliders-data {
	display: flex;
	align-items: center;
	grid-gap: 10px;
	gap: 10px;
	flex: none;
}

.equity-input-text .slider-tooltip {
	background: rgba(56, 100, 228, 0.1);
	border-radius: 5px;
	color: #cfcac4;
	font-size: 12px;
	padding: 1px 0.542em;
	max-width: 300px;
}

.slider-chart-value {
	background: rgba(56, 100, 228, 0.1);
	border-radius: 5px;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 13px;
	text-align: right;
	color: #4c506b;
	padding: 3px 8px;
	font-family: "CircularStd";
	/* width: 80px; */
}

.equity-input-text .slider-tooltip:hover {
	cursor: pointer;
}

.equity-input-text h6 {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	font-family: "Circular Std Book";
	color: #5b607d;
	margin: 0;
	text-align: left;
}

.equity-input-text span {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #878787;
	font-family: "Circular Std Book";
}

.equity-input .input-range__label--value {
	top: 0.8rem !important;
}

.equity-input .input-range__label--value span {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	font-family: "CircularStd";
	font-weight: 500;
	color: #e3e8ee !important;
	background: #3c4257;
	border-radius: 4px;
	left: -42% !important;
	padding: 3px 7px;
}

.equity-input .input-range__label-container {
	left: 0 !important;
}

.equity-input .input-range__track {
	background: #ecedf3 !important;
	border-radius: 20px;
}

.equity-input .input-range__track--active {
	background: linear-gradient(
		270.02deg,
		#7195fc 30.31%,
		#3864e4 256.26%
	) !important;
}

.equity-input .input-range__slider {
	border: 1px solid #7195fc !important;
	width: 20px !important;
	height: 20px !important;
	box-shadow: 0px 2px 5px rgba(151, 150, 234, 0.2),
		0px 1px 1px rgba(151, 150, 234, 0.12) !important;
}

.glowing-btn {
	background: #ffffff;
	box-shadow: 0px 4px 16px rgba(110, 146, 250, 0.17);
	border-radius: 10px;
	width: 160px;
	height: 50px;
	border: none;
	outline: none;
	cursor: pointer;
	position: relative;
	z-index: 0;
	font-family: "CircularStd";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #3864e4;
	border: 1px solid transparent;
	background: linear-gradient(to right, #fff, #fff),
		linear-gradient(to right, #38d2e4, #3864e4);
	background-clip: padding-box, border-box;
	background-origin: padding-box, border-box;
	transition: all 0.3s ease-in-out;
	margin-top: 30px !important;
}

.glowing-btn:hover {
	color: #fff;
	background: linear-gradient(to right, #38d2e4, #3864e4);
	border: transparent;
	box-shadow: 0px 4px 16px rgba(93, 127, 228, 0.17);
}

/* .glowing-btn {
	width: 160px;
	height: 50px;
	border: none;
	outline: none;
	background: #000;
	cursor: pointer;
	position: relative;
	z-index: 0;
	border-radius: 33px;
	font-size: 16px;
	font-family: "CircularStd";
	font-weight: bold;
	color: rgb(255, 255, 255);
	line-height: 1.3;
}

.glowing-btn:before {
	content: "";
	background: linear-gradient(
		45deg,
		#ff0000,
		#ff7300,
		#fffb00,
		#48ff00,
		#00ffd5,
		#002bff,
		#7a00ff,
		#ff00c8,
		#ff0000
	);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	z-index: -1;
	filter: blur(5px);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	animation: glowing 20s linear infinite;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
	border-radius: 10px;
}

.glowing-btn:active {
	color: #000;
}

.glowing-btn:active:after {
	background: transparent;
}

.glowing-btn:after {
	z-index: -1;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background: #111;
	left: 0;
	top: 0;
	border-radius: 10px;
}

@keyframes glowing {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
} */

.equity-input .input-range__slider {
	margin-top: -12px !important;
}

.graph-wrapper {
	display: flex;
	position: relative;
}

.graph {
	flex-grow: 1;
	overflow: auto;
}

.graphheight {
	height: 540px;
	margin: 0 auto;
}

.loan-table,
.rent-table {
	border: 1px solid rgba(0, 0, 0, 0.1);
	text-align: left;
	background: #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 2px 6px rgb(0 0 0 / 8%);
	border-radius: 10px;
	overflow: hidden;
}

.graph-table {
	text-align: left;
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.07);
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}

.graph-table thead th {
	border-bottom: 1px solid #e6e6e6;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	color: #5b607d;
	font-family: "CircularStd";
	background: rgba(56, 99, 228, 0.05);
	font-weight: bold;
	padding: 16px;
	min-height: 64px;
	text-align: center;
	vertical-align: middle;
	border-right: 1px solid #e6e6e6;
}

.graph-table td {
	/* background: white; */
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	color: #31354e;
	height: 60px;
	vertical-align: middle;
	text-align: center;
	/* border-bottom: 1px dashed #e6e6e6; */
	font-family: "Circular Std Book";
}

.graph-table thead th:last-child {
	border-right: 0;
}

.graph-table tbody tr {
	color: #fff;
	border-bottom: 1px dashed #e6e6e6;
}

.graph-table tbody tr:last-child {
	border-bottom: 0;
}

.graph-table tbody tr:nth-child(even) {
	background: rgba(245, 247, 254, 0.4);
}

.graph-table tbody tr:hover {
	background-color: inherit;
	cursor: inherit;
}

.graph-status-tag {
	background: rgba(83, 182, 73, 0.12) !important;
	-webkit-backdrop-filter: blur(10px) !important;
	        backdrop-filter: blur(10px) !important;
	border-radius: 60px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	color: #53b649 !important;
	height: 23px;
	width: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.graph-sold-status-tag {
	background: rgba(182, 73, 73, 0.12) !important;
	-webkit-backdrop-filter: blur(10px) !important;
	        backdrop-filter: blur(10px) !important;
	border-radius: 60px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	color: #b64949 !important;
	height: 23px;
	width: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.equity-graph .quity-title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 37px;
	text-align: center;
	color: #131521;
	/* padding-bottom: 24px; */
}

.modal-equity {
	margin-top: 100px;
}

@media only screen and (max-width: 992px) {
	.graph img {
		width: 100%;
	}

	.graph-slider {
		/* grid-template-columns: repeat(2, 50%); */
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		grid-gap: 30px;
		min-height: 100px;
		/* overflow-y: hidden; */
		padding: 0 30px;
		/* border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-shadow: inset -8px 0px 8px -8px rgba(0, 0, 0, 0.2),
			inset 8px 0px 8px -8px rgba(105, 78, 78, 0.2); */
	}

	/* .equity-graph-wrapper h6 {
		margin-bottom: 10px;
	} */

	.graph-slider::-webkit-scrollbar {
		display: none;
		width: 0px;
		background: transparent;
	}

	.graph-slider {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

@media only screen and (max-width: 620px) {
	/* .graph-slider {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 20px;
	} */
}

@media only screen and (max-width: 580px) {
	.section-title h5 {
		font-size: 25px;
		line-height: 34px;
	}

	.Sold-btn .blue-btn {
		width: 90%;
		margin: 0 auto;
	}

	.graph-slider {
		grid-template-columns: repeat(1, auto);
		grid-gap: 15px;
	}

	.section-title-2 {
		margin-top: 60px;
	}

	.equity-graph-sec {
		padding: 0 15px;
	}

	.graph-slider {
		padding: 0 15px;
	}

	.graph-slider .equity-input {
		padding: 15px !important;
	}

	.equity-graph-wrapper .nav-tabs .nav-link {
		width: 100px;
		font-size: 12px;
		line-height: 18px;
	}

	.equity-graph-wrapper .nav {
		max-width: 202px;
	}

	.requirement .nav {
		max-width: 303px !important;
	}
}

@media only screen and (max-width: 480px) {
	.graph-wrapper {
		margin-top: 30px;
	}

	.equity-input {
		padding: 20px;
	}

	.equity-graph-wrapper .exuity-para {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media only screen and (max-width: 430px) {
}

@media only screen and (max-width: 380px) {
	.graph-wrapper {
		margin-top: 40px;
	}
}

.canvasjs-chart-credit {
	display: none;
}

/*  */

/* STEPS STYLING */
/* @media(max-width:992px){
	.graph-slider {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.graph-slider .inner_div{
		width:90%;
	}
	.graph-slider .inner_div:last-child{
		margin-bottom: 20px;
	}
	.introjs-tooltip *{
		font-size: 14px;
	}

} */

.introjs-tooltip {
	margin-left: 0 !important;
}

.equity-graph .graph-slider .input-range__label-container {
	display: none;
}

.step_tabs {
	display: inline-flex;
	align-items: center;
	border-radius: 30px;
	border: 1px solid rgba(0, 0, 0, 0.219);
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	margin: 12px 0;
}
.step_tabs span {
	padding: 6px 20px;
	font-size: 14px;
	cursor: pointer;
	letter-spacing: 1px;
	text-transform: capitalize;
	transition: background-color 0.25s;
	font-weight: 500;
}
.step_tabs span.active {
	background-color: rgba(29, 29, 29, 0.507);

	color: white;
}

.main_equity h3 {
	font-size: 28px;
	line-height: 20px;
	font-family: "Circular Std Book";
	letter-spacing: 0.5px;
	font-style: normal;
	font-weight: 450;
	font-size: 20px;
	line-height: 24px;
	color: #131521;
}

.main_equity p {
	color: #5b607d;
	font-style: normal;
	font-family: "Circular Std Book";
	font-weight: normal;
	font-size: 18px;
	margin-top: 30px;
	line-height: 33px;
}

.output-sub-text {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	font-family: "Circular Std Book";
	color: #5b607d;
	margin: 0;
	text-align: left;
}

.equity-graph .row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-gap: 30px;
	gap: 30px;
}

.equity-graph .row > * {
	padding: 0;
}

.InputBoxesTitle h4 {
	font-size: 15px;
	font-family: "Montserrat";
	color: rgb(46, 50, 56);
	font-weight: 600;
	line-height: 1.2;
	padding-bottom: 0px;
	margin-top: 24px !important;
}

.introjs-helperLayer {
	box-shadow: none !important;
	border-radius: 0;
}

@media only screen and (max-width: 1200px) {
	.equity-graph .table-responsive-lg,
	.equity-graph .chart-container,
	.graph-slider {
		padding: 0 30px;
	}

	.financial-table .InputBoxesTitle,
	.slider-title .InputBoxesTitle {
		padding-left: 30px;
	}
}

@media only screen and (max-width: 580px) {
	.equity-graph .table-responsive-lg,
	.equity-graph .chart-container,
	.graph-slider {
		padding: 0 15px;
	}

	.equity-graph .row {
		grid-gap: 15px;
		gap: 15px;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	}

	.equity-graph .equity-input {
		padding: 20px 25px !important;
	}

	.financial-table .InputBoxesTitle,
	.slider-title .InputBoxesTitle {
		padding-left: 15px;
	}
}

.calculation-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1111111111111;
	width: 100vw;
	height: 100vh;
	background-color: rgba(48, 57, 78, 0.9);
	transition: all 0.4s ease-in-out;
	/* backdrop-filter: blur(3px); */
}

.calculation-modal-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1111111111111111111;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all 0.4s ease-in-out;
}

.financial-table .cal-link,
.blue-text-link {
	color: #3864e4;
}

.financial-table .cal-link:hover,
.blue-text-link:hover {
	cursor: pointer;
	color: #0e36af;
}

.calculation-modal {
	z-index: 100;
	background: #ffffff;
	border-radius: 10px;
	position: relative;
	margin: 0 15px;
	max-width: 561px;
	padding: 40px 40px 24px 40px;
	transition: all 0.4s ease-in-out;
}

.calculation-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;
}

.calculation-modal-header .modal-title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
	color: #131521;
}

.calculation-modal-box {
	background: #f7f7fa;
	border-radius: 8px;
	padding: 16px 24px;
	margin: 24px 0;
}

.calculation-modal-box ul li {
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 28px;
	color: #131521;
	font-family: "CircularStd";
	position: relative;
	margin-left: 18px;
}

.calculation-modal-box ul li::after {
	content: "\003D";
	position: absolute;
	top: 0;
	left: -18px;
	color: #4c506b;
}

.calculation-modal-box ul li a,
.calculation-modal-box ul li span.cal-modal-link {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	text-decoration: underline !important;
	color: #3864e4;
	font-family: "Circular Std Book";
	transition: all 0.3s ease-in-out;
}

.calculation-modal-box ul li a:hover {
	color: #0e36af;
}

.calculation-modal-box .cal-equal,
.calculation-modal-box .cal-multiply {
	color: #4c506b;
	margin-right: 12px;
}

.calculation-modal-box .cal-multiply {
	margin-left: 12px;
}

.calculation-modal-body p {
	font-family: "Circular Std Book";
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: #4c506b;
}

.calculation-modal-header .modal-close-button {
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	background: rgba(74, 78, 102, 0.12);
	cursor: pointer;
	border: none;
	color: #4a4e66;
	border-radius: 100%;
	width: 20px;
	height: 20px;
}

@media only screen and (max-width: 580px) {
	.calculation-modal {
		padding: 40px 18px 24px 18px;
	}

	.calculation-modal-box {
		padding: 18px;
	}
}

.search-header {
  background: rgba(255, 255, 255, 0.8);
  height: 90px;
  z-index: 9;
}

.searchPage {
  background-color: #f6f7fb;
}

.searchPageGroup {
  /* max-width: 1240px; */
  margin: 0 auto;
  /* margin-top: 130px; */
  position: relative;
  padding: 0 0 0 30px;
}

.dropDownGroup .wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 20px;
  padding: 0 66px;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
}

.dd-wrapper {
  width: 180px !important;
}

.dd-wrapper .dd-header-title {
  margin: 5px 20px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-family: Montserrat !important;
  color: #293335 !important;
  font-weight: 500 !important;
  text-align: left;
}

.dd-wrapper .dd-header span {
  position: relative !important;
  color: #acadb3 !important;
}

.dd-wrapper .dd-list-item {
  font-weight: 400 !important;
  cursor: pointer !important;
}

.dd-list-item:hover {
  color: #fff;
  background-color: #8d56da !important;
}

.fa-angle-down:before {
  content: "" !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAGVJREFUCJltyzEKg0AYBeFvfwQvYMgxUngCKyEWOauFAY8U9AJbaZGFhGQHppnHS7d+gBZ3b57IUeKES3FCGxjR+dBhDCT/pAZzZRC1CA0euP70V+CoHI7Aiv0r7lgDGQu24oJ8Ap3xELvLJWO8AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  position: absolute;
  top: -5px;
  width: 6px;
  height: 10px;
}

.fa-angle-up:before {
  content: "" !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAGVJREFUCJltyzEKg0AYBeFvfwQvYMgxUngCKyEWOauFAY8U9AJbaZGFhGQHppnHS7d+gBZ3b57IUeKES3FCGxjR+dBhDCT/pAZzZRC1CA0euP70V+CoHI7Aiv0r7lgDGQu24oJ8Ap3xELvLJWO8AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  position: absolute;
  top: -5px;
  width: 6px;
  height: 10px;
}

.dd-wrapper .dd-header {
  border-radius: 5px !important;
}

.dropDownGroup::-webkit-scrollbar {
  display: none;
}

.modal-backdrop {
  z-index: inherit;
}

.dropdown-toggle {
  position: relative;
}

.searchpageContent {
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-gap: 20px;
  position: relative;
}

.searchCardGroup {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
  width: 510px;
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
  overflow: hidden;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.search-cards-component-group a {
  text-decoration: none;
  margin-bottom: 20px;
}

.search-cards-component-group a:last-child {
  margin-bottom: 0px;
}

.search-cards-component-group {
  display: flex;
  flex-direction: column;
}

.searchCardGroup:hover {
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.08);
  overflow: visible;
}

.searchCardGroup:hover .s-card-img img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  overflow: hidden;
}

.search-map iframe {
  width: 100%;
  height: 700px;
  height: 82vh;
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  margin-top: 30px;
  z-index: 1;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
}

.footer-section {
  position: relative;
  z-index: 10;
}

.dd-wrapper .dd-header-title {
  margin-right: 0px !important;
}

.searchcard-sec {
  margin-top: 30px;
  justify-self: end;
}

.searchCardGroup:last-child {
  margin-bottom: 0px;
}

.s-card-img img {
  max-width: 180px;
  min-height: 159px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.s-card-img img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  overflow: hidden;
}

.s-card-img {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  transition: all 0.4s ease-in-out;
}

.price-tag {
  position: absolute;
  left: 0;
  bottom: 15px;
  background-color: rgb(135, 107, 252, 0.7);
  font-size: 10px;
  font-family: "Montserrat";
  color: rgba(255, 255, 255);
  font-weight: bold;
  padding: 4px 14px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.s-cta {
  position: relative;
}

.s-card-texts {
  padding-left: 24px;
}

.s-card-texts h6 {
  font-size: 16px;
  font-family: "Montserrat";
  color: rgb(41, 51, 53);
  line-height: 1.2;
  padding-bottom: 12px;
}

.s-card-texts p {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(131, 133, 135);
  line-height: 1.2;
  padding-bottom: 10px;
}

.color-bold {
  font-weight: 500;
  color: #3fdbb1;
}

.bold {
  font-weight: 500;
  color: rgb(41, 51, 53);
}

.active-issuance {
  margin-bottom: 10px;
  position: absolute;
  top: 20px;
  left: -32px;
  margin-top: 10px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.active-issuance span {
  font-size: 10px;
  font-family: "Montserrat";
  color: #fff;
  line-height: 1.2;
  background-color: rgb(248, 122, 60);
  padding: 4px 30px;
}

.dropdown-sec {
  background-color: #fff;
  padding: 40px 0;
  z-index: 99;
}

.s-cta {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(131, 133, 135);
  line-height: 1.2;
  margin-top: 10px;
  position: relative;
  left: 28px;
}

.s-cards-icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.beds-grey::after {
  content: "";
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAOCAYAAADNGCeJAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAALxJREFUOI2t0rFtAjEUh/HfHacEFmAMqgzADqnCFnQ0VKeDkgZFYoQoLMIuIDoKkBCNDXcnQMLiq57/tj69Zzsrq7lAib7X2WMCRS3sY5wgW8SiaG0cEmRX2rJIhU6oT5iGLENeyxrkD2SfWOIXvZD1wrqeNcjKav6BbwzdxsxxbtX3Muhig/8CP6H9sbQ7i82McnzhL1EER6wxKIJ5lihqdBhfM+V/tVk9es0kYmeLp6delL1tzN0bRLC9AGWkIAFMubjcAAAAAElFTkSuQmCC);
  width: 19px;
  height: 14px;
  left: -28px;
  margin-top: 2px;
}

.baths-grey::after {
  content: "";
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAOCAYAAADABlfOAAAA/UlEQVQ4ja3Tu0oEQRCF4U8dvAa+gYmpoSCikWIgKGZmPsQkBmKwsKyCGopgaii+giAYiIIvYGIuG5jsiuIt6Bm2GXRHnDlJVVfTP9VdpwearX2YxSa+cI57FZRkcQtHGERaF3QUbXxgqAqQ0FmsBoaz+G8lhfVOFViuYqe1KIc+470uaIIDTOJQsFQ/jaP7Qx7vtxNsY1fw50MJ9FR495csT6O9acGajXxQd1j+A7SLvSiPtYRbetO/wiLmcdMHmv5Sn8MUzugN6g3HWMMGJko6zjWG9ezMCV7jTuFJuNoqmsLP+iyBjuARLXTyYtH8HVxgBpe4LoEuYCUGwjfDYi949hOJbAAAAABJRU5ErkJggg==);
  width: 21px;
  height: 14px;
  left: -28px;
  margin-top: 0px;
}

.sqft-grey::after {
  content: "";
  position: absolute;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAABJElEQVQ4je3TPSjFURzG8c/lDkIou9dFBonJilLqbspgYWIQBiODvGewChnYDGQwKyWUhZKJUjZiYFB0r+F/bv64ksHmGc/z9D2/c3p+icnp2Rns4sD3KkA3GrCIm7iZh3K0YgBFOQBVGEMGxRhBOxJxCMzhHuOoi3mdGMQW1sP5PJoxhBJIBuMFmzhHH05QiTSm8RCb7BYLSIVLN/J81DmmUIrq8P4HX5XGDpbQ/xkCj1gNU2Zy+HFdIpkL8mv9Q/4Q8oraH3IJUdWz1f8CWRbtTeqbycowjCbsiVpaHw8kcYpr9GIUa7gLfiN6sC/a9DTOQvYE25Df2tYOzzhGYQg8BUANVnDkvb13OEQLOlCShWR1hQt0if5qQrRwn/USJsmg4g3T+0EIk48dAQAAAABJRU5ErkJggg==);
  width: 17px;
  height: 17px;
  left: -28px;
  margin-top: 0px;
}

.switch-btn {
  display: flex;
  align-items: center;
}

.switch-text {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(131, 133, 135);
  line-height: 1.2;
  position: relative;
  /* top: -2px; */
  left: 11px;
}

.map-list-toggle {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 1111111;
}

.map-list-toggle:hover {
  cursor: pointer;
}

.property-marker {
  background: transparent;
  border: none;
}

.property-marker .property-marker {
  background: url(/static/media/map-marker.02b3a06b.svg);
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.propertyhovered .searchCardGroup {
  display: block;
}

.propertynothovered .searchCardGroup {
  display: none;
}

.markerhovered .property-marker img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.cluster-marker {
  border: 2px solid yellow;
  background: #fff;
  border-radius: 90px;
  width: 25px;
  height: 25px;
  text-align: center;
}

.propertynothovered .cluster-marker,
.markerhovered .cluster-marker {
  border-color: red;
}

@media only screen and (max-width: 1199px) {
  .searchpageContent {
    display: grid;
    grid-template-columns: 500px 1fr;
    grid-gap: 20px;
    position: relative;
  }

  .dropDownGroup .wrapper {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 991px) {
  .searchpageContent {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .searchcard-sec {
    margin-top: 0px;
  }

  .searchPageGroup {
    margin-top: 40px;
  }

  .dropdown-sec {
    position: inherit;
    /* padding: 30px 0; */
  }

  .searchPageGroup {
    padding: 0 30px 0 30px;
  }

  .search-map iframe {
    position: inherit;
    height: 500px;
  }

  .search-cards-component-group {
    overflow-y: inherit;
    height: auto;
  }

  .searchCardGroup {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .search-header {
    height: 78px;
  }
}

@media only screen and (max-width: 560px) {
  .dropDownGroup {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 20px;
  }

  .dropdown-sec {
    padding: 30px 0;
  }

  .dropdown .btn-primary {
    width: 150px;
  }

  .dropDownGroup .wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr) !important;
  }

  .switch-btn {
    width: 210px;
  }

  .dropDownGroup {
    overflow: auto;
    height: 90px;
    position: relative;
    z-index: 98 !important;
  }

  .dd-wrapper {
    position: relative;
  }

  .wrapper {
    position: relative;
  }

  .dd-wrapper .dd-list {
    z-index: 111111 !important;
    width: 200px !important;
    left: 0 !important;
    right: 0 !important;
  }

  .dropdown-sec {
    padding-bottom: 0px;
  }

  .searchCardGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: inherit;
  }

  .s-card-texts {
    padding-left: 0px;
    margin-top: 24px;
  }

  .s-card-img img {
    max-width: 100%;
    width: 100%;
  }

  .s-cards-icons {
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 440px) {
  .dropDownGroup {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 20px;
  }

  .dropdown .btn-primary {
    width: 95%;
  }
}
.form-wrapper {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - 10vh);
	padding: 8vh 24px;
	background-position: center center;
	position: relative;
	z-index: 11;
	overflow: hidden;
	background: #fef9f5;
	-webkit-backdrop-filter: blur(100px);
	backdrop-filter: blur(100px);
}

.form-wrapper::before {
	content: "";
	position: absolute;
	background-image: url(/static/media/line-equity-sec.67e15412.svg);
	background-repeat: no-repeat;
	top: -150px;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
	z-index: -1;
}

.form-wrapper::after {
	content: "";
	position: absolute;
	background: rgba(254, 249, 245, 0.85);
	/* background-image: url(../Images/signin-bg.svg); */
	background-repeat: no-repeat;
	-webkit-backdrop-filter: blur(100px);
	backdrop-filter: blur(100px);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: -2;
	opacity: 0.08;
}

.form-wrapper .brand {
	margin-bottom: 50px;
}

.form-box {
	background: #ffffff;
	max-width: 420px;
	width: 100%;
	padding: 45px;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
}

.signup-form-box {
	max-width: 640px;
	width: 100%;
}

.form-text-wrapper {
	text-align: center;
	padding-bottom: 35px;
}

.form-text-wrapper h1 {
	font-style: normal;
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
	padding-bottom: 15px;
}

.form-text-wrapper p {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: #5b607d;
	font-family: "Circular Std Book";
}

.form-box .form-group {
	margin-bottom: 35px;
}

.signup-double-input {
	display: flex;
	justify-content: space-between;
}

.signup-double-input .form-group {
	flex-basis: 50%;
}

.signup-double-input .form-group:first-child {
	margin-right: 30px;
}

.form-box .form-group label {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
	margin-bottom: 12px;
}

.form-box .form-group input {
	border: 1px solid #dee0ec;
	box-sizing: border-box;
	border-radius: 70px;
	padding: 10px 25px;
	min-height: 50px;
	width: 100%;
	font-size: 15px;
	color: #131521;
	font-family: "Circular Std Book";
	vertical-align: middle;
	display: inline;
}

.form-box .form-group input::-webkit-input-placeholder {
	font-size: 15px;
	line-height: 19px;
	color: #9fa2b8;
	vertical-align: middle;
	display: inline;
	font-family: "Circular Std Book";
}

.form-box .form-group input::placeholder {
	font-size: 15px;
	line-height: 19px;
	color: #9fa2b8;
	vertical-align: middle;
	display: inline;
	font-family: "Circular Std Book";
}

.form-box .form-group input:focus {
	border: 1px solid #8ea8f3;
	box-shadow: 0 0 0 3px rgba(56, 99, 228, 0.15);
}

.form-box .is-invalid:focus {
	border: 1px solid #bd4865 !important;
	box-shadow: 0 0 0 3px rgba(189, 72, 101, 0.15) !important;
}

.form-box .invalid-feedback {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #bd4865;
	font-family: "Circular Std Book";
	margin-top: 8px;
}

.form-box .blue-btn {
	text-decoration: none;
	display: block;
	font-size: 15px;
	font-family: "CircularStd";
	font-weight: bold;
	color: rgb(255, 255, 255);
	line-height: 1.2;
	outline: none;
	background: none;
	border: none;
	width: 100%;
	height: 50px;
	background: #3864e4;
	border-radius: 70px;
	margin-right: 22px;
	transition: all 0.4s ease-in-out;
}

.form-box .blue-btn:hover {
	background: #204ccf;
	color: #fff;
	box-shadow: 0px 10px 30px 0px rgba(74, 62, 243, 0.3);
	cursor: pointer;
	outline: none;
}

.form-box .blue-btn:active,
.form-box .blue-btn:focus {
	background: #1c4ee6;
}

.form-box .form-group .custom-control-label {
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	color: #5b607d;
	font-family: "Circular Std Book";
	margin: 0px !important;
	padding-left: 4px;
}

.form-box .form-group .custom-control-label:hover {
	cursor: pointer;
}

.form-box .pass-input,
.form-box .remember-checkbox {
	margin-bottom: 30px;
}

.form-box .pass-input .password-labels {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form-box .account-creation-link p {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #5b607d;
	font-family: "Circular Std Book";
}

.form-box .account-creation-link a,
.form-box .pass-input .password-labels .forgot-pass-link a {
	color: #3864e4;
}

.form-box .pass-input .password-labels .forgot-pass-link a {
	font-size: 12px;
	font-family: "Circular Std Book";
}

.inline-feeback {
	display: flex;
	background: #f4f7fd;
	border-radius: 100px;
	justify-content: flex-start;
	align-items: center;
	padding: 12px 25px;
	margin-top: 20px;
}

.inline-feeback p {
	text-align: left;
	margin-left: 15px;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	font-family: "Circular Std Book";
	color: #1c57d7;
}

.copyrights-text {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #9fa2b8;
	font-family: "Circular Std Book";
	margin-top: 60px;
}

.property-card-signup-wrapper {
	max-width: 640px;
	margin: 0 auto;
	background: white;
	padding: 30px;
	border-bottom: 1px solid #dee0ec;
	display: flex;
}

.property-card-signup-wrapper p {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #131521;
	font-family: "Circular Std Book";
}

@media screen and (max-width: 767px) {
	.form-wrapper .container {
		max-width: 100%;
		padding: 0;
	}
}

@media screen and (max-width: 580px) {
	.signup-double-input {
		flex-direction: column;
	}

	.signup-double-input .form-group {
		flex-basis: inherit;
		flex-direction: column;
	}

	.signup-double-input .form-group:first-child {
		margin-right: 0px;
	}

	.property-card-signup-wrapper {
		padding: 30px 20px;
	}

	.property-card-signup-wrapper .col-sm-5,
	.property-card-signup-wrapper .col-sm-7 {
		padding: 0;
	}

	.property-card-signup-wrapper p {
		margin-top: 25px;
	}
}

@media screen and (max-width: 480px) {
	.form-box {
		padding: 36px 20px;
	}

	.form-wrapper {
		padding: 80px 16px;
	}
}

.gm-iv-address{
    display: none !important;
}
.Dashboard {
  background: rgba(254, 249, 245, 0.38);
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

.navbar-bg {
  z-index: 10;
  height: auto;
  padding: 0px;
}

.Profile-image .btn-primary {
  padding-right: 30px;
}
.Profile-image img {
  max-width: 45px;
  border-radius: 90px;
}
.dash-logo img {
  padding-top: 12px;
  width: 100px;
}

.dash-logo {
  padding-top: 0px;
  padding-bottom: 0px;
  border-right: 1px solid rgb(245, 245, 245);
  height: 82px;
  padding: 14px 100px;
  margin-right: 35px;
}

.formGroup {
  position: relative;
}

.search-btn {
  position: absolute;
  background: none;
  border: none;
  outline: none;
  padding: none;
}

.search-btn:hover,
.search-btn:focus {
  background: none;
  border: none;
  outline: none;
  padding: none;
  box-shadow: none;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0 40px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(185, 187, 193);
  background-clip: padding-box;
  border: none;
  outline: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
}

.form-inline .form-control {
  display: inline-block;
  width: 260px;
  height: 40px;
  background: #e9ecf3;
  font-family: "Circular Std Book";
  border-radius: 60px;
  color: #000;
  font-size: 13px;
  vertical-align: middle;
}

.form-inline .form-control::-webkit-input-placeholder {
  color: #9fa2b8;
}

.form-inline .form-control::placeholder {
  color: #9fa2b8;
}

@media (min-width: 1024px) {
  .form-inline .form-control {
    display: inline-block;
    width: 260px;
    border-radius: 60px;
    vertical-align: middle;
  }
}

@media (max-width: 1023px) {
  .form-inline .form-control {
    display: inline-block;
    width: 300px;
    vertical-align: middle;
  }
}

.form-control:active,
.form-control:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.list-group {
  width: 100%;
  border-bottom: 1px solid #242739;
  margin: 0 auto;
}

.list-group-item:first-child {
  margin-top: 20px;
}

.list-group-item:last-child {
  margin-bottom: 20px;
}

.list-group:last-child {
  border-bottom: none;
  /* padding-bottom: 120px; */
}

.sidebar-menu-nav:hover {
  color: #f9f9f9;
  text-decoration: none;
}

.sidebar-sec {
  background-color: #131521;
  /* box-shadow: 2px 0px 8px 0px rgba(19, 25, 35, 0.15); */
  height: 100%;
  width: 270px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

.logo-dashbaord {
  margin-top: 35px;
  padding-left: 30px;
  margin-bottom: 40px;
}

.list-group-item {
  border: none;
  padding: 0 !important;
  transition: 0.3s;
  background-color: transparent;
  position: relative;
  z-index: 10;
  background: #131521 !important;
  border-radius: none;
  border: none !important;
  transition: 0.3s ease-in-out;
}

.list-group-item a {
  display: block;
  padding: 13px 0;
  font-style: normal;
  font-weight: normal;
  font-family: "Circular Std Book";
  font-size: 15px;
  line-height: 19px;
  color: #98999f;
  text-decoration: none;
  border-radius: none;
  width: 100%;
  padding: 14px 15px;
}

.no-hover:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.table-responsive .header:hover,
.table-responsive .table-empty-state-2:hover {
  background-color: #fff;
  cursor: inherit;
}

.table-responsive tr {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.table-responsive tr:hover {
  background-color: #f7f8ff;
  cursor: pointer;
}

.list-group-item a:hover {
  text-decoration: none;
}

.sidebar-menu {
  padding-left: 8px;
}

.list-group-item:hover {
  position: relative;
  width: 100%;
  color: #fff;
}

.list-group-item .active {
  background: red;
  background: #373a4e;
  border-radius: 10px;
  color: white;
}

.navbar__link--active {
  width: 100%;
  position: absolute;
  background-color: #373a4e;
  color: #fff;
  border-radius: 10px;
  padding: 0 15px;
  position: relative;
  z-index: 999;
}

.list-group-item span.active {
  color: #fff;
}

.list-group-item:hover span.sidebar-menu {
  color: #fff;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.DashContent {
  width: calc(100%);
  padding: 50px 50px 130px 320px;
  margin: 0 auto;
}

.nav-heading {
  padding: 12px 30px;
  color: #f3f3f3;
  position: relative;
  font-size: 12px;
  font-family: "Montserrat";
}

.auction-navbar,
.auction-topbar {
  display: flex;
  justify-content: center;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 270px);
  margin-left: 270px;
  padding-top: 40px;
}

.auction-navbar .createauction-btn button {
  background: #000000;
  border-radius: 33px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  border: none;
  color: #ffffff;
  padding: 11px 25px;
  font-family: "CircularStd";
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
}

.auction-navbar .createauction-btn button:hover {
  background: #202020;
  color: #fff;
  box-shadow: 0px 10px 30px 0px rgb(20 20 20 / 30%);
  cursor: pointer;
  outline: none;
}

.auction-navbar .dashTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 50px;
}

.dashTitle-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
}

.dashTitle h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 600;
}

.logout-btn .log-link {
  /* padding: 0 25px; */
  margin-top: 20px;
  width: 85%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding-top: 33px;
  margin-bottom: 33px;
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
  border-top: 1px solid #242739;
}

.logout-btn .log-link span {
  font-size: 15px;
  line-height: 19px;
  font-family: "Circular Std Book";
  color: #ce3939;
  margin: 0 5px;
}

.logout-btn .log-link span:hover {
  color: #921515;
}

.accordion__button {
  border-radius: 0 !important;
  border-bottom: 1px solid #26293a !important;
  margin-bottom: 0 !important;
  background-color: #181a2a !important;
  color: white !important;
  font-size: 13px;
}

.accordion__panel {
  padding: 0 15px !important;
}

.auction-navbar .dashTitle h4,
.auction-topbar .dashTitle h4 {
  padding-left: 50px;
}

.addPropert-btn button {
  background: #3864e4;
  color: #fff;
  padding: 13px 30px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  border-radius: 50px;
  font-family: "CircularStd";
  border: none;
  outline: none;
  position: relative;
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
}

.addPropert-btn button:hover {
  background-color: #1e6fc1;
  box-shadow: 0px 8px 35px 0px rgba(51, 136, 223, 0.2);
}

.addPropert-btn {
  position: relative;
}

.btn-plusIcon {
  margin-right: 10px;
  transition: 0.3s ease-in-out;
}

.addPropert-btn button:hover .btn-plusIcon {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.PropertyCardGroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(273px, 1fr));
  width: 100%;
  grid-gap: 43px;
  margin-top: 35px;
}

.PropertyCard {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.06);
  /* border-radius: 5px; */
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.PropertyCard:hover {
  box-shadow: 0px 15px 35px 0px rgba(0, 0, 0, 0.1);
}

.PropertyCard img {
  width: 100%;
  padding: 15px;
  position: relative;
  z-index: 1;
}

.card-cta {
  position: relative;
}

.card-cta span {
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 9;
  background: rgba(56, 100, 228, 0.65);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 7px 12px;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  font-family: "Circular Std Book";
}

.PropertyCard-text {
  padding: 15px 30px 30px;
}

.PropertyCard-text span {
  background-color: rgba(248, 122, 60, 0.2);
  color: rgb(248, 122, 60);
  font-size: 10px;
  font-family: "Montserrat";
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  padding: 5px 10px;
  border-radius: 11px;
  margin-bottom: 20px;
  font-weight: 600;
}

.PropertyCard-text h3 {
  font-size: 16px;
  font-family: "Montserrat";
  color: rgb(46, 50, 56);
  line-height: 1.2;
  padding: 0 0 20px 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  color: #31354e;
  font-family: "CircularStd";
}

.PropertyCard-lists {
  position: relative;
}

.PropertyCard-lists li {
  list-style: none;
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(131, 133, 135);
  line-height: 1.2;
  padding-left: 28px;
  position: relative;
}

.PropertyCard-lists li:nth-child(1) {
  background-image: url(/static/media/address-state-icon1.b625239b.svg);
  background-repeat: no-repeat;
}

.PropertyCard-lists li:nth-child(2) {
  margin-top: 18px;
  background-image: url(/static/media/address-state-icon.ab32e117.svg);
  background-repeat: no-repeat;
}

@media only screen and (max-width: 900px) {
  .sidebar-sec {
    /* background-color: rgb(37, 44, 65); */
    box-shadow: 2px 0px 8px 0px rgba(19, 25, 35, 0.15);
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 999;
    top: 82px;
    left: 0;
    overflow-x: hidden;
  }

  .DashContent {
    padding: 50px 40px 70px 340px;
  }
}

@media only screen and (max-width: 900px) {
  .sidebar-sec {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999;
    display: none;
    top: 0;
  }

  #Dashboard .nav-right-side {
    width: 85%;
  }

  .dashTitle-bar {
    margin-top: 0;
  }

  .sidebar-sec.in {
    display: block;
  }

  .list-group-item1.active {
    margin-top: 150px;
  }

  .DashContent {
    width: calc(100%);
    padding: 50px 30px 30px 30px;
    margin: 0 auto;
  }
}

.notificationGroup {
  float: right;
}

.Notify-bell {
  background: #e9ecf3;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-right: 17px;
  justify-content: center;
  border-radius: 50%;
}

.Notify-bell .btn-primary {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  background-image: url(/static/media/bell-icon.f6be8c16.svg);
  background-repeat: no-repeat;
  outline: none;
  border: none;
  width: 15px;
  height: 17px;
  position: absolute;
  left: 13px;
}

.Notify-bell .btn-primary::after {
  content: "";
  position: absolute;
  border: 4px solid #3864e4;
  top: -9px;
  right: -2px;
  border-radius: 100px;
}

.Notify-bell .show {
  position: absolute !important;
  top: 27px !important;
}

.Notify-bell .btn-primary:active,
.Notify-bell .btn-primary:focus {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  border: none;
  width: 0px;
  height: 21px;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  border: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.dropdown-menu.show {
  top: 20px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  padding: 16px 30px;
  clear: both;
  font-size: 13px;
  font-weight: 300;
  color: #212529;
}

.nav-wrapper {
  display: flex;
  width: 100%;
}

.nav-right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.dropdown-menu {
  border: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05),
    0 2px 3px rgba(0, 0, 0, 0.06);
}

.dropdown-menu-right.show {
  top: 28px;
  position: relative;
}

.veiw-all-noti {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
}

.Profile-image .btn-primary {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  border: none;
}

span.profile-name {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(46, 50, 56);
  line-height: 1.2;
  padding-left: 10px;
}

.Profile-image .show {
  position: absolute !important;
  top: 55px !important;
  left: -110px !important;
}

/* Hamburger styling */

#menu_button {
  width: 30px;
  overflow: hidden;
  position: absolute;
  right: 30px;
  top: 40px;
  display: none;
  z-index: 11111111;
  z-index: 99999999;
}

#menu_checkbox {
  display: none;
}

#menu_label {
  position: relative;
  display: block;
  height: 16px;
  cursor: pointer;
}

#menu_label:before,
#menu_label:after,
#menu_text_bar {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #202020;
}

#menu_label:before,
#menu_label:after {
  content: "";
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) left;
  color: #fff;
}

#menu_label:before {
  top: 0;
  color: #fff;
}

#menu_label:after {
  top: 7px;
  color: #fff;
}

#menu_text_bar {
  top: 14px;
  color: #fff;
}

#menu_text_bar:before {
  content: "  Close";
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  font-family: "Montserrat";
  text-align: center;
}

#menu_checkbox:checked + #menu_label:before {
  left: -39px;
  color: #fff;
}

#menu_checkbox:checked + #menu_label:after {
  left: 39px;
  color: #fff;
}

#menu_checkbox:checked + #menu_label #menu_text_bar:before {
  -webkit-animation: moveUpThenDown 0.8s ease 0.2s forwards, shakeWhileMovingUp 0.8s ease 0.2s forwards,
    shakeWhileMovingDown 0.2s ease 0.8s forwards;
          animation: moveUpThenDown 0.8s ease 0.2s forwards, shakeWhileMovingUp 0.8s ease 0.2s forwards,
    shakeWhileMovingDown 0.2s ease 0.8s forwards;
  color: #fff;
}

@-webkit-keyframes moveUpThenDown {
  0% {
    top: 0;
  }
  50% {
    top: -18px;
  }
  100% {
    top: -12px;
  }
}

@keyframes moveUpThenDown {
  0% {
    top: 0;
  }
  50% {
    top: -18px;
  }
  100% {
    top: -12px;
  }
}

@-webkit-keyframes shakeWhileMovingUp {
  0% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
  25% {
    -webkit-transform: rotateZ(-10deg);
            transform: rotateZ(-10deg);
  }
  50% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  75% {
    -webkit-transform: rotateZ(10deg);
            transform: rotateZ(10deg);
  }
  100% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
}

@keyframes shakeWhileMovingUp {
  0% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
  25% {
    -webkit-transform: rotateZ(-10deg);
            transform: rotateZ(-10deg);
  }
  50% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  75% {
    -webkit-transform: rotateZ(10deg);
            transform: rotateZ(10deg);
  }
  100% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
}

@-webkit-keyframes shakeWhileMovingDown {
  0% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
  80% {
    -webkit-transform: rotateZ(3deg);
            transform: rotateZ(3deg);
  }
  90% {
    -webkit-transform: rotateZ(-3deg);
            transform: rotateZ(-3deg);
  }
  100% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
}

@keyframes shakeWhileMovingDown {
  0% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
  80% {
    -webkit-transform: rotateZ(3deg);
            transform: rotateZ(3deg);
  }
  90% {
    -webkit-transform: rotateZ(-3deg);
            transform: rotateZ(-3deg);
  }
  100% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
}

@media (max-width: 900px) {
  #menu_button {
    display: block;
  }

  .formGroup,
  .dash-logo {
    display: none;
  }

  .Notify-bell {
    left: 30px;
  }

  .Profile-image {
    left: 80px;
  }

  .auction-navbar {
    margin-left: 0;
    width: calc(100% - 50px);
    padding-top: 25px !important;
  }

  .dropdown-menu-right.show {
    top: 50px !important;
  }

  .Notify-bell {
    left: 60px;
  }

  #menu_button {
    top: 40px;
  }
}

/******* Add Property Styling ********/

.AddProperty {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 100%;
  height: 655px;
  margin-top: 0px !important;
}

.AddPropertyGroup {
  display: grid;
  grid-template-columns: 340px 77%;
  grid-template-columns: 1fr 3fr;
}

.AddProperty-ls {
  background-color: rgba(246, 247, 251, 0.6);
  height: 655px;
  padding: 50px;
}

.AddProperty-t h5 {
  font-size: 18px;
  font-family: "Montserrat";
  color: rgb(46, 50, 56);
  font-weight: bold;
  line-height: 1.5;
  max-width: 300px;
}

.AddProperty-steps {
  padding: 95px 0;
}

.AddProperty-steps ul li {
  list-style: none;
  font-size: 14px;
  font-family: "Montserrat";
  color: rgb(139, 148, 178);
  line-height: 1.2;
  position: relative;
  padding-left: 50px;
  margin-top: 40px;
  font-weight: 500;
  counter-increment: my-awesome-counter;
}

.AddProperty-steps ul li.active {
  color: rgb(47, 51, 65);
}

.AddProperty-steps ul li:first-child {
  margin-top: 0px;
}

.AddProperty-steps ul li::after {
  content: counter(my-awesome-counter);
  color: rgb(139, 148, 178);
  font-size: 14px;
  position: absolute;
  left: 0;
  top: -10px;
  width: 36px;
  height: 36px;
  font-weight: 500;
  border-radius: 50%;
  background-color: rgb(239, 239, 249);
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddProperty-steps ul li.active::after {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  color: rgb(47, 51, 65);
}

.AddProperty-steps ul li.active-tick::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAMCAYAAACEJVa/AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAIxJREFUKJGl0DEKwkAQBdBn0NqLWJvSSrsc1kZIbGxs9AieIgcIWrgLyxLJJk4zMMw8PrPavS4W1hZnbKo/gBYHDEuQCNR4opmL5MAR/RxkFIAKd9zCUgnwSIGIDL4Pan9AOXBKgYg0IV49Ak0CEelDvBwqAmAdeoSu4ajDuwRIkRzah9kkkCM5pASAD07ALtL0puRMAAAAAElFTkSuQmCC);
  background-color: transparent;
}

.AddProperty-rs {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}

.AddProperty-rs-Group {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 50px;
}

.property-type-tags h6 {
  font-size: 14px;
  font-family: "Montserrat";
  color: rgb(46, 50, 56);
  line-height: 1.2;
  font-weight: 500;
  padding-bottom: 15px;
}

.property-tags button {
  border: 1px solid rgb(225, 227, 230);
  border-radius: 17px;
  background-color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: "Montserrat";
  color: rgba(78, 90, 107, 0.851);
  line-height: 1.2;
  padding: 10px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.property-tags button:focus,
.property-tags button.active {
  background-color: rgb(51, 136, 223);
  color: #fff;
}

.Ap-submitted-btn {
  text-align: center;
  padding-top: 50px;
}

.Ap-submitted-btn button {
  background-image: linear-gradient(-60deg, rgb(51, 160, 223) 0%, rgb(51, 136, 223) 100%);
  padding: 8px 35px;
  /* width: 154px; */
  height: 44px;
  border-radius: 50px;
  font-size: 14px;
  font-family: "Montserrat";
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-align: center;
  border: none;
  outline: none;
}

.AddProperty-form {
  width: 80%;
  max-width: 80%;
}

.AddProperty-inputs {
  padding: 0;
}

.AddProperty-inputs li {
  list-style: none;
  margin-top: 30px;
}

.AddProperty-inputs li:first-of-type {
  margin-top: 0px;
}

/* .AddProperty-inputs {
	width: 467px;
} */

ul.AddProperty-inputs li input,
ul.AddProperty-inputs .css-yk16xz-control {
  border: none;
  outline: none;
  width: 100% !important;
  min-height: 44px;
  font-size: 14px;
  color: #202020;
  outline: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(225, 227, 230);
  border-radius: 30px;
  padding-bottom: 2px;
  padding: 0;
}
*/ .css-1wa3eu0-placeholder {
  font-size: 14px;
  /* padding: 0 10px; */
}

.AddProperty-inputs .css-1pahdxg-control {
  border-radius: 30px !important;
  min-height: 44px;
  padding: 0;
}

.AddProperty-inputs .css-1wa3eu0-placeholder {
  margin: 0;
}

.css-1g6gooi {
  margin: 0 !important;
  padding: 0 !important;
}

ul.AddProperty-inputs li input:focus {
  border: 1px solid #4756f7;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.15);
}

.AddProperty-inputs .input-label {
  color: rgb(46, 50, 56);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 500;
  margin-bottom: 12px;
}

@media (max-width: 1400px) {
  /* .AddProperty-inputs {
		width: 100%;
	} */
}

@media (max-width: 1140px) {
  .AddPropertyGroup {
    display: flex;
    flex-direction: column;
  }

  .AddProperty,
  .AddProperty-ls {
    height: auto;
  }

  .AddProperty-inputs {
    width: 100%;
  }

  .AddProperty-rs,
  .AddProperty-ls {
    padding: 30px;
  }

  .AddProperty-illustration {
    display: none;
  }

  .AddProperty-steps {
    padding: 0px;
    padding-top: 40px;
  }
}

.AuctionPage {
  display: flex;
}

.auction-page-rightside {
  margin-left: 30px;
}

.AuctionCard {
  padding: 10px;
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 15px;
}

.auction-img img {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 100%;
}

.AuctionCardText {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AuctionCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}

.AuctionCardTitle .property-location {
  display: flex;
}

.AuctionCardTitle .property-location img {
  margin-right: 10px;
}

.AuctionCardTitle .property-location p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #5b607d;
  font-family: "Circular Std Book";
}

.AuctionCardTitle h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  font-family: "Montserrat";
  color: #131521;
}

.AuctionCardSubGroup {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 45px;
}

.acsg-2 {
  margin-top: 30px;
}

.AuctionSubTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #9fa2b8;
  font-family: "Circular Std Book";
  padding-bottom: 4px;
}

.AuctionCardSubWrapper span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131521;
  font-family: "Circular Std Book";
}

span.acsg-amount {
  color: #3864e4;
  font-weight: 600;
}

.AuctionDoubleCard {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 50px;
  margin-top: 30px;
}

.SubmitBidCardGroup {
  padding: 20px 67px 30px 20px;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 15px;
}

.SubmitBidCardTitle {
  display: flex;
  justify-content: space-between;
  margin: 0px 0 30px 0;
}

.ExistingBidCard .SubmitBidCardTitle {
  margin: 30px 30px 15px 30px;
}

.Submit-lm-btn {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.SubmitBidCardHeading {
  font-size: 16px;
  font-family: "Montserrat";
  color: rgb(46, 50, 56);
  font-weight: 600;
  line-height: 1.2;
}

.SubmitBidCardTimer {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(131, 133, 135);
  line-height: 1.2;
}

.SubmitBidCardText span {
  color: #2e3238;
  font-weight: 500;
}

.Submit-lm-btn {
  float: right;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(131, 133, 135, 0.302);
  border-radius: 4px;
  background-color: rgba(51, 136, 223, 0);
  color: #202020;
  position: relative;
  top: -16px;
}

.action-ip-1,
.action-ip-2 {
  max-width: 260px;
  margin: 0;
  margin-top: 0px;
}

.action-ip-1 .input-range__track,
.action-ip-2 .input-range__track {
  background: #f8f9f9 !important;
}

.action-ip-2 .input-range__slider {
  border: 1px solid #5ad4ee !important;
}

.sbc-checkmark label:hover {
  cursor: pointer;
}

.action-ip-1 .input-range__track--active {
  background: linear-gradient(250.15deg, #9897eb 15.63%, #7e7dda 73.48%) !important;
}

.action-ip-2 .input-range__track--active {
  background: linear-gradient(51.13deg, #41c4e0 -1.42%, #60dcf7 80.84%) !important;
}

.sb-slider h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131521;
  margin-bottom: 10px;
  font-family: "Circular Std Book";
}

.input-range__label-container {
  font-size: 13px !important;
}

.action-ip-1 .equity-input-text,
.action-ip-2 .equity-input-text {
  margin-bottom: 0;
}

.sbc-checkmark {
  margin-top: 60px;
  max-width: 100%;
}

.sbc-checkmark .custom-control-label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #5b607d;
  font-family: "Circular Std Book";
}

.sbc-checkmark .custom-control-label a,
.sbc-btn-link a {
  text-decoration: none;
  color: #e4ad24;
}

.sbc-btn-link a {
  color: #3864e4;
}

.equity-input .input-range__label--value span {
  top: 0;
}

.sbc-btn-link a {
  border-bottom: 1px solid #3864e4;
  font-family: "Circular Std Book";
}

.sbc-buttons {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.sbc-btn-link {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #5b607d;
  font-family: "Circular Std Book";
}

.sbc-btn Button {
  text-decoration: none;
  display: block;
  font-size: 15px;
  font-family: "CircularStd";
  font-weight: bold;
  color: rgb(255, 255, 255);
  line-height: 1.2;
  outline: none;
  background: none;
  border: none;
  width: 171px;
  height: 45px;
  background: #3864e4;
  border-radius: 10px;
  margin-right: 30px;
  transition: all 0.4s ease-in-out;
}

.exb-table {
  /* margin: 1em; */
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding-bottom: 15px;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
}

.Tabheader div {
  font-size: 12px;
  font-family: "Montserrat";
  color: rgb(131, 133, 135);
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 500;
  /* padding: 6px 0; */
}

.Tabheader {
  padding: 12px 0;
  border-bottom: 1px solid rgb(237, 239, 245);
}

.exb-table .Tabheader,
.exb-table .row {
  display: flex;
}

.exb-table .Tabheader div,
.exb-table .row div {
  flex: 1 1;
  padding: 0 26px;
}

.Tabheader div {
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: #9fa2b8;
  font-family: "CircularStd";
  font-weight: 500;
}

.Tab-body .row {
  border: 0px !important;
}

.Tab-body div {
  font-size: 14px;
  font-family: "Montserrat";
  color: rgb(46, 50, 56);
  line-height: 1.2;
}

.Tab-body .row {
  padding: 15px 0;
  border-bottom: 1px solid rgb(237, 239, 245);
}
.Tab-body .row div:first-child {
  font-weight: 500;
}

.AuctionTimeCard,
.AuctionMiniCard-section {
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
}

.AuctionTimeCard h3,
.AuctionMiniCardsGroup h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 20px;
  color: #5b607d;
}

.AuctionTimeboxes {
  display: flex;
  flex-wrap: wrap;
}

.AuctionTimeboxes .time-box {
  background: #f6faff;
  border-radius: 15px;
  width: 60px;
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.AuctionTimeboxes .time-box span {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 500;
  text-align: center;
}

.AuctionTimeboxes .time-box p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #9fa2b8;
  margin-top: 2px;
  font-family: "Circular Std Book";
}

.AuctionMiniCards .AuctionCardwrapper {
  display: flex;
  align-items: center;
  background: #f6faff;
  border-radius: 15px;
  padding: 12px;
  margin-bottom: 15px;
}

.AuctionCardwrapper a {
  text-decoration: none;
}

.AuctionMiniCards .AuctionCardwrapper:last-child {
  margin-bottom: 0px;
}

.AuctionminiCardText {
  margin-left: 20px;
}

.AuctionminiCardText .AuctionminiCardTitle h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #131521;
}

.AuctionminiCardText .AuctionCardSubWrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.AuctionminiCardText .AuctionCardSubWrapper h6 {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #9fa2b8;
  font-family: "Circular Std Book";
  padding: 0;
  margin-right: 4px;
}

.AuctionminiCardText .AuctionCardSubWrapper p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #131521;
  font-family: "Circular Std Book";
}

.card-body {
  padding: 0px !important;
  border: 0px;
  background-color: #131521;
  background-clip: border-box;
  border: 0 !important;
  border-radius: 0;
}

.card {
  border: 0 !important;
  border-radius: 0 !important;
}

.card-header {
  border-radius: 0 !important;
  border-bottom: 1px solid #26293a !important;
  margin-bottom: 0 !important;
  background: #181a2a !important;
  color: white !important;
  font-size: 13px;
}

.card-header:hover {
  cursor: pointer;
}

.card-header:last-child {
  border-bottom: 0 !important;
}

@media (max-width: 1199px) {
  .AuctionDoubleCard {
    grid-template-columns: repeat(1, 1fr);
  }
  .AuctionPage {
    flex-direction: column;
  }

  .auction-page-rightside {
    margin-left: 0;
    margin-top: 30px;
  }
}

@media (max-width: 900px) {
  .AuctionDoubleCard {
    grid-template-columns: repeat(2, 1fr);
  }

  .AddProperty {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .AuctionDoubleCard {
    grid-template-columns: repeat(1, 1fr);

    grid-gap: 10px;
  }

  #Dashboard .nav-right-side {
    width: 75%;
  }

  /* .Profile-image {
		left: 115px;
	} */
}

@media (max-width: 600px) {
  .DashContent {
    width: calc(100%);
    padding: 60px 15px 15px 15px;
    margin: 0 auto;
  }

  .AddProperty-form {
    width: 100%;
    max-width: 100%;
  }

  .Profile-image {
    left: 60px;
  }

  #menu_button {
    right: 20px;
    top: 34px;
  }

  .dashTitle h4 {
    font-size: 20px;
    line-height: 32px;
  }

  .auction-navbar .dashTitle h4,
  .auction-topbar .dashTitle h4 {
    padding-left: 15px;
  }

  .dropdown .btn-primary {
    width: 0px !important;
  }

  .Profile-image .btn-primary {
    padding-right: 0;
  }

  .auction-navbar {
    width: calc(100% - 30px);
  }

  .AuctionCardSubGroup {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px;
  }

  .action-ip-1,
  .action-ip-2 {
    max-width: 100%;
  }

  .AuctionCardText {
    padding: 30px 10px;
  }

  .AuctionTimeCard,
  .AuctionMiniCard-section {
    padding: 30px 20px;
  }

  #Dashboard .nav-right-side {
    width: 60%;
  }
}

@media (max-width: 560px) {
  .dropdown .btn-primary {
    width: 0px !important;
  }

  .equity-input {
    padding: 0 0;
  }

  #Dashboard .nav-right-side {
    width: 40%;
  }

  .auction-navbar .dashTitle {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 0px;
  }

  .auction-navbar .createauction-btn-2 button {
    width: 100% !important;
  }

  .auction-navbar .createauction-btn,
  .auction-navbar .createauction-btn-2 {
    margin-top: 20px;
    position: relative;
    top: 40px;
  }

  .auction-navbar,
  .auction-topbar {
    flex-direction: column;
  }

  .auction-navbar .createauction-btn,
  .auction-navbar .createauction-btn-2,
  .auction-navbar .createauction-btn a,
  .auction-navbar .createauction-btn button {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    left: 6px;
    flex: none;
  }
}

@media (max-width: 480px) {
}

.wj-inputnumber input.wj-form-control {
  text-align: left !important;
  width: 100%;
}

.Tabs-menu {
  position: absolute;
  right: 0;
  z-index: 99999;
}

.nav-tabs {
  border: none;
  position: fixed;
  top: 28px;
  right: 0;
  z-index: 99999;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: rgb(51, 136, 223);
  font-weight: 500;
  background-color: transparent;
  border-color: transparent;
  border-bottom: 2px solid rgb(51, 136, 223);
  padding-bottom: 17px;
  padding-left: 0px;
  padding-right: 0px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-bottom: 2px solid #6539c8;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: transparent;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 34px;
}

.nav-tabs a {
  font-size: 13px;
  font-family: "Montserrat";
  color: rgb(46, 50, 56);
  text-transform: uppercase;
  line-height: 1.2;
  border-bottom: 1px solid #6539c8;
}

.nav-tabs .nav-link.disabled {
  color: rgb(46, 50, 56);
}

@media only screen and (max-width: 900px) {
  .nav-tabing {
    display: none;
  }

  .nav-tabing.in {
    display: block;
    background-color: #252c41;
    color: #fff;
    left: 0;
    top: 0;
    overflow: hidden;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .nav-tabing .nav-item.show .nav-link,
  .nav-tabing .nav-link {
    font-size: 24px;
    line-height: 2;
  }

  .nav-tabs .nav-link.disabled {
    color: #fff;
  }

  .nav-tabing .nav-item.show .nav-link,
  .nav-tabing .nav-link.active {
    color: #fff;
    font-weight: 500;
    background-color: transparent;
    border-color: transparent;
    border-bottom: none;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.menu-calculator {
  margin-top: 10px;
}

.dashboard-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 320px;
}

.dashboard-navbar h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 600;
  margin-top: 25px;
}

.auction-navbar .createauction-btn-2 button {
  width: 165px;
  margin-right: 50px;
}

.dashTitle-2 {
  width: calc(100% - 270px);
  margin-left: auto;
  padding-top: 40px;
}

.dashTitle-2 h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 600;
  padding-left: 50px;
}

.auction-navbar {
  padding-top: 40px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-basic-button {
  background: #000000;
  border-radius: 33px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 11px 25px;
  font-family: "CircularStd";
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #000;
  color: white;
}

.dropdown-menu.show {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 12px 26px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  width: 285px;
  padding: 24px;
}

.dropdown-menu h3 {
  padding-bottom: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #131521;
  font-family: "CircularStd";
}

.properties .nav {
  width: 100% !important;
  max-width: 237px;
  justify-content: center;
  border: 1px solid rgba(236, 237, 243, 0.55);
  border-radius: 60px;
  background: rgba(236, 237, 243, 0.55);
  width: 237px;
  overflow: hidden;
}

.properties .nav-tabs .nav-link {
  border: none;
  border-radius: none;
  margin: 0;
  background: none;
  text-align: center;
  color: #4c506b;
  font-family: CircularStd;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 116px;
  padding: 12px 0;
  border-radius: 60px;
  text-transform: capitalize;
}

.properties .nav-tabs .nav-link.active {
  border: none;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 60px;
  color: #3864e4;
  padding: 12px 0;
}

.properties .tab-content {
  margin-top: 24px;
}

.properties .dropdown-items li {
  list-style: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding-bottom: 16px;
}

.properties .dropdown-items li::after {
  content: "";
  position: absolute;
  left: 8px;
  top: 5px;
  border-left: 1px dashed #e3e3e3;
  height: 100%;
  z-index: -1;
}

.properties .dropdown-items li:nth-last-of-type(2) {
  padding-bottom: 0px;
}

.properties .dropdown-items li a {
  display: flex;
  align-items: center;
  width: 100%;
  grid-gap: 12px;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #131521;
  font-family: "Circular Std Book";
  transition: 0.3s ease-in-out;
}

.properties .dropdown-items li a:hover {
  color: #3864e4;
}

.properties .dropdown-items .last-item a {
  padding-bottom: 0px;
  border-top: 1px solid #e3e3e3;
  padding-top: 20px;
  margin-top: 20px;
}

.properties .dropdown-items .last-item::after {
  border: none;
}

@media only screen and (max-width: 900px) {
  .dashboard-navbar {
    padding-left: 30px;
  }

  .dashTitle-2 {
    width: calc(100% - 0px);
    margin-left: auto;
  }

  .dashTitle-2 h4 {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard-navbar {
    padding-left: 15px;
  }

  .dashboard-navbar h1 {
    font-size: 26px;
    line-height: 36px;
    margin-top: 25px;
  }

  .dashTitle-2 h4 {
    font-size: 20px;
    line-height: 32px;
    padding-left: 15px;
  }

  .dashTitle-2 {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 560px) {
  .auction-navbar .createauction-btn-2 button {
    width: 100%;
    margin-right: 0px;
  }
}

/* --------------------------------- */

.properties-investor-table .table {
  display: inline-table;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 10px;
}

.properties-investor-table .dashTitle-bar {
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 10px 10px 0 0;
  padding: 30px 30px 10px 30px;
  max-width: 100%;
  width: 100%;
}

.properties-investor-table .dashTitle h4 {
  font-size: 22px;
  line-height: 28px;
  color: #131521;
  font-weight: 500;
}

.properties-investor-table .createauction-btn button {
  background: #000000;
  border-radius: 33px;
  color: #ffffff;
  padding: 11px 25px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  font-family: "CircularStd";
  border: none;
  outline: none;
  position: relative;
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
}

.properties-investor-table .table thead th {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 30px;
  color: #9fa2b8;
  position: relative;
  z-index: 10;
  width: 20%;
  font-family: "CircularStd";
}

.properties-investor-table .table td {
  border-top: 1px solid #f4f5fa;
  padding: 15px 30px;
  vertical-align: middle;
  /* min-width: 170px; */
}

.properties-investor-table .table .property-col {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.properties-investor-table .table .property-col img {
  border-radius: 50%;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
}

.properties-investor-table .table .property-col h6 {
  font-size: 15px;
  line-height: 21px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 500;
}

.properties-investor-table .table .tag--active,
.properties-investor-table .table .tag--inreview,
.properties-investor-table .table .tag--success,
.properties-investor-table .table .tag--closed {
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #3864e4;
  font-family: "CircularStd";
  font-weight: 500;
  background: rgba(56, 100, 228, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 60px;
  padding: 5px 15px;
}

.properties-investor-table .table .tag--inreview {
  background: rgba(255, 174, 0, 0.15);
  color: #ffae00;
}

.properties-investor-table .table .tag--success {
  background: rgba(83, 182, 73, 0.12);
  color: #53b649;
}

.properties-investor-table .table .tag--closed {
  background: rgba(189, 72, 101, 0.1);
  color: #bd4865;
}

.dash-tbl-col p {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131521;
  font-family: "Circular Std Book";
}

.Dashboard .AuctionPage {
  display: inherit;
}

/* @media only screen and (max-width: 1330px) {
	.paymentmade-table .table {
		display: block;
	}
} */

@media only screen and (max-width: 900px) {
  .paymentmade-page-topbar {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .properties-investor-table .table {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .properties-investor-table .dashTitle h4 {
    font-size: 19px;
    line-height: 25px;
  }

  .properties-investor-table .table .property-col {
    min-width: 250px;
  }

  .properties-investor-table .table .status-col {
    min-width: 190px;
  }

  .properties-investor-table .table .amount-raising-col,
  .properties-investor-table .table .date-col {
    min-width: 140px;
  }

  .paymentmade-page-topbar h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .paymentmade-page-topbar {
    padding-left: 15px;
  }

  .properties-investor-table .table .property-col h6 {
    font-size: 16px;
    line-height: 23px;
  }

  .properties-investor-table .table .property-col img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .properties-investor-table .table td {
    padding: 15px;
  }
  .properties-investor-table .table thead th {
    padding: 12px 15px;
  }

  .properties-investor-table .dashTitle-bar {
    padding: 30px 15px 10px 15px;
  }
}

@media only screen and (max-width: 480px) {
  .dashTitle-bar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .properties-investor-table .table {
    display: block;
  }

  .properties-investor-table .createauction-btn {
    margin-top: 20px;
  }

  .properties-investor-table .createauction-btn,
  .properties-investor-table .createauction-btn button {
    width: 100%;
  }
}

.search-sec {
	position: relative;
}

.searchproperty {
	max-width: 724px;
	margin: 0 auto;
	position: absolute;
	top: -130px;
	z-index: 111 !important;
	left: 0;
	right: 0;
}

.searchproperty-text p {
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 19px;
	color: #4c506b;
	font-family: "Circular Std Book";
	margin-bottom: 20px;
}

.search-wrapper .nav {
	justify-content: center;
	border: 0;
	border-radius: 10px 10px 0 0;
	background: #e9eaf0;
	width: 260px;
	margin-top: 24px;
	position: relative;
	z-index: -1;
	overflow: hidden;
}

.search-wrapper .nav-tabs .nav-link {
	border: none;
	border-radius: none;
	padding: 0;
	margin: 0;
	background: none;
	text-align: center;
	color: #4c506b;
	font-family: "CircularStd";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	width: 130px;
	padding: 16px 0;
	border-radius: 10px 0 10px 0;
	text-transform: capitalize;
}

.search-wrapper .nav-tabs .nav-item.show .nav-link,
.search-wrapper .nav-tabs .nav-link.active {
	border: none;
	background: #ffffff;
	box-shadow: none;
	border-radius: 0px;
	color: #3864e4;
	padding: 16px 0;
}

.nav-link:focus,
.search-wrapper .nav-tabs .nav-link:hover {
	border: none;
	cursor: pointer;
}

.search-input .css-2b097c-container {
	width: 724px;
	position: relative;
	box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.06);
	border-radius: 0 10px 10px 10px;
}

.property-search input {
	/* border: 1px solid rgb(235, 235, 235) !important; */
	padding: 15px 55px !important;
	/* padding-right: 55px !important;
	padding-left: 55px !important; */
	box-shadow: rgb(0 0 0 / 6%) 0px 6px 24px !important;
	position: relative;
}

.property-search input::-webkit-input-placeholder {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: #373f41;
	font-family: "Circular Std Book";
	position: relative;
}

.property-search input::placeholder {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: #373f41;
	font-family: "Circular Std Book";
	position: relative;
}

.property-search input:placeholder-shown {
	text-overflow: ellipsis;
}

div#preload img {
	display: none;
}

.property-search input[type="text"].property_address_input {
	background-image: url(/static/media/search-house.7623dc34.svg) !important;
	background-position: 24px 27px !important;
	background-repeat: no-repeat !important;
}

.property-search input[type="text"].region_input {
	background-image: url(/static/media/region.9713f7c8.svg) !important;
	background-position: 24px 27px !important;
	background-repeat: no-repeat !important;
}

.search-input .css-yk16xz-control,
.search-input .css-1hwfws3 {
	height: 74px;
	border: 0;
	font-family: "CircularStd";
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
}

.search-input .css-1wy0on6 {
	display: none;
}

.search-input button {
	position: absolute;
	top: 12px;
	right: 12px;
	text-decoration: none;
	display: block;
	font-size: 14px;
	font-family: "CircularStd";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: 0.3px;
	color: #ffffff;
	outline: none;
	background: none;
	border: none;
	width: 139px;
	height: 50px;
	background: #3864e4;
	border-radius: 6px;
	transition: all 0.4s ease-in-out;
}

.search-input .col-lg-6 {
	padding: 0;
}

.search-cross {
	background: rgba(55, 63, 65, 0.15) !important;
	width: 24px;
	height: 24px;
	border-radius: 100px;
	color: #373f41 !important;
	font-size: 16px !important;
	font-family: "CircularStd";
	font-weight: 500;
	padding: 7px 8px;
	top: 25px !important;
}

.search-cross span {
	display: block;
	line-height: 8px;
}

@media only screen and (max-width: 840px) {
	.search-input .css-2b097c-container {
		width: 100%;
		max-width: 100%;
	}

	.searchproperty-wrapper {
		padding: 0 30px;
	}

	.search-input .css-2b097c-container input {
		width: 100%;
		max-width: 100%;
	}
}

@media only screen and (max-width: 640px) {
	.searchproperty-wrapper {
		padding: 0 30px;
	}
}

@media only screen and (max-width: 480px) {
	.searchproperty {
		padding-bottom: 10px;
	}
}

.add_property_tabs .nav-tabs{
    align-items: center;
    display: inline-flex;
    margin-bottom: 8px;
    overflow: hidden;
}
.add_property_tabs .nav-tabs{
    border:1px solid rgba(0, 0, 0, 0.21);
    border-radius:8px;
}
.add_property_tabs .nav-tabs li{
    margin-top: -1px;
}
.add_property_tabs .nav-tabs li a{
    padding:16px 12px;
    margin-right: 0;
    border-right:1px solid rgba(0, 0, 0, 0.21);
    color:#222;
    cursor: pointer;
    letter-spacing: 0.5px;
    text-transform: capitalize ;
    font-weight: 500;
    border-bottom: 0px;
}
.add_property_tabs .nav-tabs li a:hover{
    border-bottom: 0px;
}
.add_property_tabs .nav-tabs li a.active{
    border:0;
    padding:16px 12px;
    background-color: rgb(51 136 223);
    color:white;
}
.add_property_tabs .nav-tabs li:last-child a{
    border-right: 0;
}

.search-dropdowns {
	box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.08);
	background: #ffffff;
	border-radius: 10px;
	padding-right: 10px;
}

.search-loading-state {
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 15px 24px;
	width: 100%;
	margin-top: 5px;
}

.search-loading-state .search-loading-text {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.2px;
	color: #373f41;
	font-family: "Circular Std Book";
}

.search-loading-state .spinning-loader img {
	width: 40px;
}

.main_box .box{
    flex:1 1;
    max-width:600px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.box label{
    font-size:12px;
    font-weight:700;
    cursor:pointer;
}
.box input,
.box select,
.box .dropdown_custom,
.box .dropdown_menu{
    border: 1px solid rgba(34, 34, 34, 0.253);
    border-radius: 8px;
}
.box input,
.box .dropdown_custom{
    width: 100%;
    height:40px;
    outline: 0;
    padding:4px 12px;
    box-sizing: border-box;
    font-size:14px;
}
.box input:focus{
    /* outline:1px solid rgba(34, 34, 34, 0.493);
    outline-offset: 2px; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-color:rgba(34, 34, 34, 0.453);
    border-width:2px;
}
.box select{
    width:100%;
    padding:6px 8px;
    font-size: 14px;
}
.box .dropdown_custom{
    position:relative;
    padding:0;
    
}
.box .dropdown_custom .select{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height:100%;
    position: relative;
    overflow:hidden;
    padding:4px 12px;
    z-index: 1;
    text-transform: capitalize;
}
.box .dropdown_custom .select i{
    font-size: 10px;
    color:rgba(34, 34, 34, 0.582);
}
.box .dropdown_custom .select::before{
    content:'';
    cursor: pointer;
    background-color: transparent;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    z-index: 10;
}
.box .dropdown_custom .dropdown_menu{
    position:absolute;
    top:45px;
    left:0;
    -webkit-transform:scale(0);
            transform:scale(0);
    list-style: none;
    width:100%;
    overflow: hidden;
    transition:all .25s;
}
.box .dropdown_custom .dropdown_menu.active{
    -webkit-transform: scale(1);
            transform: scale(1);
}
.box .dropdown_custom .dropdown_menu li{
    padding:4px 12px;
    background:#fff;
    border-bottom: 1px solid rgba(34, 34, 34, 0.116);
    transition:background-color .25s;
    cursor:pointer;
    text-transform: capitalize;
}
.box .dropdown_custom .dropdown_menu li:hover{
    background-color:#222;
    color:#fff;
}
.box .dropdown_custom .dropdown_menu li:last-child{
    border-bottom: 0;
}

.box .dropdown .dropdown-menu.active{
    height:100%;
}
.accerdition-content h5 {
  font-weight: bold;
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.accreditation-status {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #5b607d;
  font-family: "CircularStd";
}

.accreditation-status .status {
  background: rgba(75, 181, 67, 0.2);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 60px;
  color: #4bb543;
  padding: 6px 16px;
}

.accreditation-status .verified {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50px !important;
  background-color: #4bb543 !important;
  margin-right: 10px;
}

.accreditation-status .status-loader {
  background: rgba(189, 72, 101, 0.1);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 60px;
  padding: 6px 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #bd4865;
  font-family: "CircularStd";
}

.status {
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
}

.not_verified {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50px !important;
  background-color: red !important;
  margin-right: 7px;
}
.verified {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50px !important;
  background-color: green !important;
  margin-right: 7px;
}

.main-upload {
  border: 3px solid #000;
  border-style: dashed;
  height: 200px;
  width: 400px;
  border-radius: 15px;
}

.border-tbl {
  border: 3px solid black;
  padding: 5px;
}

.accreditation-DashContent {
  padding-top: 24px;
}

.accreditation-para {
  background: #f6f8fa;
  border-radius: 10px;
  padding: 34px 28px;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}

.accreditation-para p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #31354e;
  font-family: "Circular Std Book";
}

.accreditation-para a {
  color: #3864e4;
  text-decoration: underline !important;
}

.separator {
  border-color: #f0f1f6;
  border-width: 1px;
  margin: 32px 0;
}

.document-upload,
.accreditation-table-wrapper,
.done-uploading-wrapper {
  margin-top: 48px;
}

.document-upload h4,
.accreditation-table-wrapper h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #31354e;
  font-family: "CircularStd";
  padding-bottom: 24px;
}

.main-upload {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(19, 21, 33, 0.15);
  border-radius: 10px;
  min-height: 190px;
}

.drag-file-text {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px;
}

.drag-file-text h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #5b607d;
  font-family: "Circular Std Book";
}

.drag-file-text button {
  background: transparent;
  border: 0;
  padding: 0;
  color: #3864e4;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-family: "Circular Std Book";
  text-decoration: underline;
}

.drag-file-text button:hover {
  background-color: transparent;
  background: transparent;
  color: #072b95;
}

.uploading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 190px;
}

.accreditation-table-data {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid rgba(19, 21, 33, 0.15);
}

.accreditation-table-data .border-tbl {
  min-width: 200px;
  width: 100%;
  background: #ffffff;
  border: 0px;
  overflow: auto;
  padding: 0;
}

.accreditation-table-data table {
  display: block;
}

.accreditation-table-data .border-tbl th {
  background: rgba(56, 100, 228, 0.05);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #9fa2b8;
  font-family: "CircularStd";
  padding: 20px 30px;
}

.accreditation-table-data .border-tbl tr {
  border-bottom: 1px solid rgba(19, 21, 33, 0.15);
}

.accreditation-table-data .border-tbl tr:last-child {
  border-bottom: 0px;
}

.accreditation-table-data .border-tbl td,
.accreditation-table-data .file-name a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #131521;
  font-family: "Circular Std Book";
  padding: 24px 30px;
}

.accreditation-table-data .file-name {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.accreditation-table-data .file-name a {
  padding: 0;
}

.done-uploading-wrapper h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #31354e;
  font-family: "Circular Std Book";
}

.done-uploading-wrapper button {
  background: #3864e4;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  font-family: "CircularStd";
  border: 0;
  padding: 12px 32px;
}

.auction-table .table {
  display: inline-table;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 10px;
}

.auction-table .dashTitle-bar {
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 10px 10px 0 0;
  padding: 30px 30px 10px 30px;
  max-width: 100%;
  width: 100%;
}

.auction-table .dashTitle h4 {
  font-size: 22px;
  line-height: 28px;
  color: #131521;
  font-weight: 500;
}

.auction-table .createauction-btn button {
  background: #000000;
  border-radius: 33px;
  color: #ffffff;
  padding: 11px 25px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  font-family: "CircularStd";
  border: none;
  outline: none;
  position: relative;
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
}

.auction-table .createauction-btn-2 button {
  margin-top: 20px;
}

.auction-detailpage-row-1 .AuctionCardGroup {
  display: flex;
  grid-gap: 50px;
}

.auction-detailpage-row-2 {
  display: flex;
  grid-gap: 30px;
  /* display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 30px; */
}

.auction-detailpage-row-3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin-top: 30px;
}

.auction-detailpage-row-1 .AuctionCardSubGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.auction-detailpage-row-1 .auction-img {
  width: 700px;
}

.AuctionTimeboxes {
  grid-gap: 10px;
}

.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  font-size: 12px;
  color: #9fa2b8;
}

.ExistingBidCard .SubmitBidCardTitle {
  margin: 30px 15px 15px 15px;
}

.AuctionTimeboxes .time-box {
  margin-right: 0px;
  width: 100%;
}

.AuctionCard .AuctionCardText {
  width: 100%;
}

.auction-detailpage-row-2 .action-inputs {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
}

.auction-detailpage-row-2 .action-ip-1,
.auction-detailpage-row-2 .action-ip-2 {
  max-width: 100%;
  border: none;
  padding: 20px 8px;
}

.auction-table .table thead th {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 30px;
  color: #9fa2b8;
  position: relative;
  z-index: 10;
  width: 25%;
  font-family: "CircularStd";
  border-bottom: 1px solid #f4f5fa;
}

.auction-table .table td {
  border-top: 0;
  padding: 15px 30px;
  vertical-align: middle;
}

.auction-table .table tr {
  border-top: 1px solid #f4f5fa;
}

.AuctionTimeCard,
.AuctionMiniCard-section {
  margin-bottom: 0;
}

.auction-table .table .property-col {
  display: flex;
  align-items: center;
  min-width: 300px;
}

ul.AddProperty-inputs li input {
  height: auto;
  padding: 12px 17px;
}

.AddProperty {
  margin-top: 60px;
}

.auction-table .table .property-col img {
  border-radius: 50%;
  /* width: 55px; */
  margin-right: 15px;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
}

.AddProperty-rs-Group {
  width: 100%;
}

.auction-table .table .property-col h6 {
  font-size: 15px;
  line-height: 21px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 500;
}

.auction-table .table .amount-raising-col {
  font-size: 14px;
  line-height: 18px;
  color: #3864e4;
  font-family: "CircularStd";
  font-weight: 500;
  min-width: 150px;
}

.auction-table .table .date-col {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131521;
  font-family: "Circular Std Book";
  min-width: 150px;
}

.auction-table .table .status-col {
  min-width: 220px;
}

.auction-table .table .tag--active,
.auction-table .table .tag--inreview,
.auction-table .table .tag--success,
.auction-table .table .tag--closed {
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #3864e4;
  font-family: "CircularStd";
  font-weight: 500;
  background: rgba(56, 100, 228, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 60px;
  padding: 5px 15px;
}

.auction-table .table .tag--inreview {
  background: rgba(255, 174, 0, 0.15);
  color: #ffae00;
}

.auction-table .table .tag--success {
  background: rgba(83, 182, 73, 0.12);
  color: #53b649;
}

.auction-table .table .tag--closed {
  background: rgba(189, 72, 101, 0.1);
  color: #bd4865;
}

.auction-page-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 320px;
}

.auction-page-topbar h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 600;
  margin-top: 17px;
}

.table-empty-state {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 70px 15px;
  text-align: center;
}
.table-empty-state button {
  margin-top: 20px;
}

.table-empty-state-2 {
  position: relative;
  left: 25%;
}

.table-empty-state p {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #5b607d;
  font-family: "Circular Std Book";
  margin-top: 24px;
}

@media only screen and (max-width: 1200px) {
  .auction-table .table {
    display: block;
  }
}

@media only screen and (max-width: 1100px) {
  .auction-detailpage-row-1 .AuctionCardGroup {
    flex-direction: column;
    grid-gap: 0;
  }

  .auction-detailpage-row-1 .auction-img {
    max-width: 100%;
    width: 100%;
  }

  .auction-detailpage-row-2,
  .auction-detailpage-row-3 {
    /* grid-template-columns: repeat(1, 1fr); */
    grid-gap: 30px;
    flex-direction: column;
  }
}

@media only screen and (max-width: 900px) {
  .auction-page-topbar {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .auction-table .table {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .auction-table .dashTitle h4 {
    font-size: 19px;
    line-height: 25px;
  }

  .auction-table .table .property-col {
    min-width: 250px;
  }

  .auction-table .table .status-col {
    min-width: 190px;
  }

  .auction-table .table .amount-raising-col,
  .auction-table .table .date-col {
    min-width: 140px;
  }

  .auction-page-topbar h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .auction-page-topbar {
    padding-left: 15px;
  }

  .auction-table .table .property-col h6 {
    font-size: 16px;
    line-height: 23px;
  }

  .auction-table .table .property-col img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .auction-table .table td {
    padding: 15px;
  }
  .auction-table .table thead th {
    padding: 12px 15px;
  }

  .auction-table .dashTitle-bar {
    padding: 30px 15px 10px 15px;
  }
}

@media only screen and (max-width: 480px) {
  .dashTitle-bar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .auction-detailpage-row-2,
  .auction-detailpage-row-3 {
    grid-gap: 15px;
  }

  .AuctionCard {
    margin-bottom: 15px;
  }

  .auction-detailpage-row-3 {
    margin-top: 15px;
  }

  .SubmitBidCardGroup {
    padding: 20px 70px 20px 20px;
  }

  .auction-table .createauction-btn {
    margin-top: 20px;
  }

  .auction-table .createauction-btn,
  .auction-table .createauction-btn button {
    width: 100%;
  }
}

.existingbidcard-table .table .status-col {
	min-width: 220px;
}

.existingbidcard-table .table .tag--active,
.existingbidcard-table .table .tag--inreview,
.existingbidcard-table .table .tag--success,
.existingbidcard-table .table .tag--closed {
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #3864e4;
	font-family: "CircularStd";
	font-weight: 500;
	background: rgba(56, 100, 228, 0.1);
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
	border-radius: 60px;
	padding: 5px 15px;
}

.existingbidcard-table .table .tag--inreview {
	background: rgba(255, 174, 0, 0.15);
	color: #ffae00;
}

.existingbidcard-table .table .tag--success {
	background: rgba(83, 182, 73, 0.12);
	color: #53b649;
}

.existingbidcard-table .table .tag--closed {
	background: rgba(189, 72, 101, 0.1);
	color: #bd4865;
}





/* .auction-table .tag--active,
.auction-table .tag--inreview,
.auction-table .tag--Won,
.auction-table .tag--closed {
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #3864e4;
	font-family: "CircularStd";
	font-weight: 500;
	background: rgba(56, 100, 228, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 60px;
	padding: 5px 15px;
}

.auction-table .tag--inreview{
	background: rgba(255, 174, 0, 0.15);
	color: #ffae00;
}

.auction-table .tag--active{
	background: rgba(83, 182, 73, 0.12);
	color: rgb(43, 43, 190);
}


.auction-table .tag--Won {
	background: rgba(83, 182, 73, 0.12);
	color: #53b649;
}

.auction-table .tag--closed {
	background: rgba(189, 72, 101, 0.1);
	color: #bd4865;
} */
.paymentowed-table .table {
  display: inline-table;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 10px;
  display: block;
}

.paymentowed-table-2 .table {
  margin-top: 60px;
}

.paymentowed-table .dashTitle-bar {
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 10px 10px 0 0;
  padding: 30px 30px 10px 30px;
  max-width: 100%;
  width: 100%;
}

.paymentowed-table .dashTitle h4 {
  font-size: 22px;
  line-height: 28px;
  color: #131521;
  font-weight: 500;
}

.paymentowed-table .createauction-btn button {
  background: #000000;
  border-radius: 33px;
  color: #ffffff;
  padding: 11px 25px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  font-family: "CircularStd";
  border: none;
  outline: none;
  position: relative;
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
}

.paymentowed-table .table thead th {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 30px;
  color: #9fa2b8;
  position: relative;
  z-index: 10;
  width: 22%;
  min-width: 100%;
  font-family: "CircularStd";
}

.paymentowed-table .table td {
  border-top: 1px solid #f4f5fa;
  padding: 15px 30px;
  vertical-align: middle;
  /* min-width: 170px; */
}

.paymentowed-table .table .property-col {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.paymentowed-table .table .property-col img {
  border-radius: 50%;
  margin-right: 15px;
  width: 55px;
  height: 55px;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
}

.paymentowed-table .table .property-col h6 {
  font-size: 15px;
  line-height: 21px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 500;
}

.paymentowed-table .table .tag--active,
.paymentowed-table .table .tag--inreview,
.paymentowed-table .table .tag--success,
.paymentowed-table .table .tag--closed {
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #3864e4;
  font-family: "CircularStd";
  font-weight: 500;
  background: rgba(56, 100, 228, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 60px;
  padding: 5px 15px;
}

.paymentowed-table .table .tag--inreview {
  background: rgba(255, 174, 0, 0.15);
  color: #ffae00;
}

.paymentowed-table .table .tag--success {
  background: rgba(83, 182, 73, 0.12);
  color: #53b649;
}

.paymentowed-table .table .tag--closed {
  background: rgba(189, 72, 101, 0.1);
  color: #bd4865;
}

.dash-tbl-col p {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131521;
  font-family: "Circular Std Book";
}

.Dashboard .AuctionPage {
  display: inherit;
}

/* @media only screen and (max-width: 1330px) {
	.paymentowed-table .table {
		display: block;
	}
} */

@media only screen and (max-width: 900px) {
  .paymentowed-page-topbar {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .paymentowed-table .table {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .paymentowed-table .dashTitle h4 {
    font-size: 19px;
    line-height: 25px;
  }

  .paymentowed-table .table {
    display: block;
  }

  .paymentowed-table .table .property-col {
    min-width: 250px;
  }

  .paymentowed-table .table .status-col {
    min-width: 190px;
  }

  .paymentowed-table .table .amount-raising-col,
  .paymentowed-table .table .date-col {
    min-width: 140px;
  }

  .paymentowed-page-topbar h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .paymentowed-page-topbar {
    padding-left: 15px;
  }

  .paymentowed-table .table .property-col h6 {
    font-size: 16px;
    line-height: 23px;
  }

  .paymentowed-table .table .property-col img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .paymentowed-table .table td {
    padding: 15px;
  }
  .paymentowed-table .table thead th {
    padding: 12px 15px;
  }

  .paymentowed-table .dashTitle-bar {
    padding: 30px 15px 10px 15px;
  }
}

@media only screen and (max-width: 480px) {
  .dashTitle-bar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .paymentowed-table .createauction-btn {
    margin-top: 20px;
  }

  .paymentowed-table .createauction-btn,
  .paymentowed-table .createauction-btn button {
    width: 100%;
  }
}

.paymentmade-table .table {
	display: inline-table;
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	border-radius: 10px;
	/* margin-top: 60px; */
}

.paymentmade-table .dashTitle-bar {
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	border-radius: 10px 10px 0 0;
	padding: 30px 30px 10px 30px;
	max-width: 100%;
	width: 100%;
}

.paymentmade-table .dashTitle h4 {
	font-size: 22px;
	line-height: 28px;
	color: #131521;
	font-weight: 500;
}

.paymentmade-table .createauction-btn button {
	background: #000000;
	border-radius: 33px;
	color: #ffffff;
	padding: 11px 25px;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	font-family: "CircularStd";
	border: none;
	outline: none;
	position: relative;
	transition: all 0.6s ease-in-out;
	transition: 0.3s ease-in-out;
}

.paymentmade-table .table thead th {
	border: none;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	padding: 12px 30px;
	color: #9fa2b8;
	position: relative;
	z-index: 10;
	width: 20%;
	font-family: "CircularStd";
}

.paymentmade-table .table td {
	border-top: 1px solid #f4f5fa;
	padding: 0;
	vertical-align: middle;
	/* min-width: 170px; */
}

.paymentmade-table.countywise .table td{
	padding: 15px 30px;
}

.paymentmade-table .table td a{	
	padding: 15px 30px;
	display: block;
}
.paymentmade-table .table td a.nopayment{
	padding:0;
	display: inline-block;
}
.paymentmade-table .table .property-col {
	display: flex;
	align-items: center;
	min-width: 300px;
}

.paymentmade-table .table .property-col img {
	border-radius: 50%;
	margin-right: 15px;
	width: 55px;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
}

.paymentmade-table .table .property-col h6 {
	font-size: 15px;
	line-height: 21px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
}

.paymentmade-table .table .tag--active,
.paymentmade-table .table .tag--inreview,
.paymentmade-table .table .tag--success,
.paymentmade-table .table .tag--closed {
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #3864e4;
	font-family: "CircularStd";
	font-weight: 500;
	background: rgba(56, 100, 228, 0.1);
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
	border-radius: 60px;
	padding: 5px 15px;
}

.paymentmade-table .table .tag--inreview {
	background: rgba(255, 174, 0, 0.15);
	color: #ffae00;
}

.paymentmade-table .table .tag--success {
	background: rgba(83, 182, 73, 0.12);
	color: #53b649;
}

.paymentmade-table .table .tag--closed {
	background: rgba(189, 72, 101, 0.1);
	color: #bd4865;
}

.dash-tbl-col p {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #131521;
	font-family: "Circular Std Book";
}

.Dashboard .AuctionPage {
	display: inherit;
}

/* @media only screen and (max-width: 1330px) {
	.paymentmade-table .table {
		display: block;
	}
} */

@media only screen and (max-width: 900px) {
	.paymentmade-page-topbar {
		padding-left: 30px;
	}
}

@media only screen and (max-width: 1024px) {
	.paymentmade-table .table {
		margin-bottom: 80px;
	}
}

@media only screen and (max-width: 600px) {
	.paymentmade-table .dashTitle h4 {
		font-size: 19px;
		line-height: 25px;
	}

	.paymentmade-table .table .property-col {
		min-width: 250px;
	}

	.paymentmade-table .table .status-col {
		min-width: 190px;
	}

	.paymentmade-table .table .amount-raising-col,
	.paymentmade-table .table .date-col {
		min-width: 140px;
	}

	.paymentmade-page-topbar h1 {
		font-size: 28px;
		line-height: 36px;
	}

	.paymentmade-page-topbar {
		padding-left: 15px;
	}

	.paymentmade-table .table .property-col h6 {
		font-size: 16px;
		line-height: 23px;
	}

	.paymentmade-table .table .property-col img {
		width: 40px;
		height: 40px;
		margin-right: 15px;
	}

	.paymentmade-table .table td {
		padding: 15px;
	}
	.paymentmade-table .table thead th {
		padding: 12px 15px;
	}

	.paymentmade-table .dashTitle-bar {
		padding: 30px 15px 10px 15px;
	}
}

@media only screen and (max-width: 480px) {
	.dashTitle-bar {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.paymentmade-table .table {
		display: block;
	}

	.paymentmade-table .createauction-btn {
		margin-top: 20px;
	}

	.paymentmade-table .createauction-btn,
	.paymentmade-table .createauction-btn button {
		width: 100%;
	}
}

.investments-table .table {
	display: inline-table;
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	border-radius: 10px;
}

.investments-table .dashTitle-bar {
	background: #ffffff;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
	border-radius: 10px 10px 0 0;
	padding: 30px 30px 10px 30px;
	max-width: 100%;
	width: 100%;
}

.investments-table .dashTitle h4 {
	font-size: 22px;
	line-height: 28px;
	color: #131521;
	font-weight: 500;
}

.investments-table .createauction-btn button {
	background: #000000;
	border-radius: 33px;
	color: #ffffff;
	padding: 11px 25px;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
	font-family: "CircularStd";
	border: none;
	outline: none;
	position: relative;
	transition: all 0.6s ease-in-out;
	transition: 0.3s ease-in-out;
}

.investments-table .table thead th {
	border: none;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	padding: 12px 30px;
	color: #9fa2b8;
	position: relative;
	z-index: 10;
	width: 15%;
	font-family: "CircularStd";
}

.investments-table .table td {
	border-top: 1px solid #f4f5fa;
	padding: 15px 30px;
	vertical-align: middle;
	/* min-width: 170px; */
}

.investments-table .table .property-col {
	display: flex;
	align-items: center;
	min-width: 300px;
}

.investments-table .table .property-col img {
	border-radius: 50%;
	margin-right: 15px;
	width: 55px;
	box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
	-webkit-backdrop-filter: blur(100px);
	        backdrop-filter: blur(100px);
}

.investments-table .table .property-col h6 {
	font-size: 15px;
	line-height: 21px;
	color: #131521;
	font-family: "CircularStd";
	font-weight: 500;
}

.investments-table .table .tag--active,
.investments-table .table .tag--inreview,
.investments-table .table .tag--success,
.investments-table .table .tag--closed {
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #3864e4;
	font-family: "CircularStd";
	font-weight: 500;
	background: rgba(56, 100, 228, 0.1);
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
	border-radius: 60px;
	padding: 5px 15px;
}

.investments-table .table .tag--inreview {
	background: rgba(255, 174, 0, 0.15);
	color: #ffae00;
}

.investments-table .table .tag--success {
	background: rgba(83, 182, 73, 0.12);
	color: #53b649;
}

.investments-table .table .tag--closed {
	background: rgba(189, 72, 101, 0.1);
	color: #bd4865;
}

.dash-tbl-col p {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #131521;
	font-family: "Circular Std Book";
}

.Dashboard .AuctionPage {
	display: inherit;
}

@media only screen and (max-width: 1330px) {
	.investments-table .table {
		display: block;
	}
}

@media only screen and (max-width: 900px) {
	.investments-page-topbar {
		padding-left: 30px;
	}
}

@media only screen and (max-width: 1024px) {
	.investments-table .table {
		margin-bottom: 80px;
	}
}

@media only screen and (max-width: 600px) {
	.investments-table .dashTitle h4 {
		font-size: 19px;
		line-height: 25px;
	}

	.investments-table .table .property-col {
		min-width: 250px;
	}

	.investments-table .table .status-col {
		min-width: 190px;
	}

	.investments-table .table .amount-raising-col,
	.investments-table .table .date-col {
		min-width: 140px;
	}

	.investments-page-topbar h1 {
		font-size: 28px;
		line-height: 36px;
	}

	.investments-page-topbar {
		padding-left: 15px;
	}

	.investments-table .table .property-col h6 {
		font-size: 16px;
		line-height: 23px;
	}

	.investments-table .table .property-col img {
		width: 40px;
		height: 40px;
		margin-right: 15px;
	}

	.investments-table .table td {
		padding: 15px;
	}
	.investments-table .table thead th {
		padding: 12px 15px;
	}

	.investments-table .dashTitle-bar {
		padding: 30px 15px 10px 15px;
	}
}

@media only screen and (max-width: 480px) {
	.dashTitle-bar {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
	}

	.investments-table .createauction-btn {
		margin-top: 20px;
	}

	.investments-table .createauction-btn,
	.investments-table .createauction-btn button {
		width: 100%;
	}
}

.issuances-table .table {
  display: inline-table;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 10px;
  margin-top: 60px;
}

.issuances-table .dashTitle-bar {
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
  border-radius: 10px 10px 0 0;
  padding: 30px 30px 10px 30px;
  max-width: 100%;
  width: 100%;
}

.issuances-table .dashTitle h4 {
  font-size: 22px;
  line-height: 28px;
  color: #131521;
  font-weight: 500;
}

.issuances-table .createauction-btn button {
  background: #000000;
  border-radius: 33px;
  color: #ffffff;
  padding: 11px 25px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  font-family: "CircularStd";
  border: none;
  outline: none;
  position: relative;
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
}

.issuances-table .table thead th {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 30px;
  color: #9fa2b8;
  position: relative;
  z-index: 10;
  width: 30%;
  font-family: "CircularStd";
}

.issuances-table .table td {
  border-top: 1px solid #f4f5fa;
  padding: 15px 30px;
  vertical-align: middle;
  /* min-width: 170px; */
}

.issuances-table .table .property-col {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.issuances-table .table .property-col img {
  border-radius: 50%;
  margin-right: 15px;
  /* width: 55px; */
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px);
}

.issuances-table .table .property-col h6 {
  font-size: 15px;
  line-height: 21px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 500;
}

.issuances-table .table .tag--active,
.issuances-table .table .tag--inreview,
.issuances-table .table .tag--success,
.issuances-table .table .tag--closed {
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #3864e4;
  font-family: "CircularStd";
  font-weight: 500;
  background: rgba(56, 100, 228, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 60px;
  padding: 5px 15px;
}

.issuances-table .table .tag--inreview {
  background: rgba(255, 174, 0, 0.15);
  color: #ffae00;
}

.issuances-table .table .tag--success {
  background: rgba(83, 182, 73, 0.12);
  color: #53b649;
}

.issuances-table .table .tag--closed {
  background: rgba(189, 72, 101, 0.1);
  color: #bd4865;
}

.dash-tbl-col p {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131521;
  font-family: "Circular Std Book";
}

.Dashboard .AuctionPage {
  display: inherit;
}

@media only screen and (max-width: 1330px) {
  .issuances-table .table {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .issuances-page-topbar {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .issuances-table .table {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .issuances-table .dashTitle h4 {
    font-size: 19px;
    line-height: 25px;
  }

  .issuances-table .table .property-col {
    min-width: 250px;
  }

  .issuances-table .table .status-col {
    min-width: 190px;
  }

  .issuances-table .table .amount-raising-col,
  .issuances-table .table .date-col {
    min-width: 140px;
  }

  .issuances-page-topbar h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .issuances-page-topbar {
    padding-left: 15px;
  }

  .issuances-table .table .property-col h6 {
    font-size: 16px;
    line-height: 23px;
  }

  .issuances-table .table .property-col img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .issuances-table .table td {
    padding: 15px;
  }
  .issuances-table .table thead th {
    padding: 12px 15px;
  }

  .issuances-table .dashTitle-bar {
    padding: 30px 15px 10px 15px;
  }
}

@media only screen and (max-width: 480px) {
  .dashTitle-bar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .issuances-table .createauction-btn {
    margin-top: 20px;
  }

  .issuances-table .createauction-btn,
  .issuances-table .createauction-btn button {
    width: 100%;
  }
}


.tax-text-wrapper {
  padding-left: 45px;
  max-width: 650px;
}

.tax-text-wrapper h4 {
  padding-bottom: 20px;
}

@media only screen and (max-width: 900px) {
  .Tax-info-page .auction-navbar .dashTitle {
    margin-left: 0px;
  }

  .tax-text-wrapper {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .tax-text-wrapper {
    padding-left: 15px;
  }
}

main {
    display: flex !important;
    justify-content:center !important;
    align-items: center !important;
}

.box{
    max-width:600px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.dropdown-item{
    font-weight: 800;
}
.dropdown-item:hover{
    background-color: black;
    color: white;
    font-weight: 800;
}
.account-main {
    background-color: white;
    padding: 15px;
    display: flex;
    justify-content:space-between;
    border:2px solid grey;
    border-radius: 5px;
    cursor: pointer; 
    margin-bottom: 15px;
}
/* #131521 */
.active-account {
    background-color: grey;
    padding: 15px;
    display: flex;
    justify-content:space-between;
    border:2px solid #131521;
    border-radius: 5px;
    margin-bottom: 15px; 
    color: white;
}
.img-white {
    color:white
}
@media only screen and (min-width: 600px) {
    .heading-top {
      font-weight: 12px !important;
    }
  }
html,
body {
	margin: 0;
	padding: 0;
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: "CircularStd";
	src: url(/static/media/CircularStd-BookItalic.b978dbf0.woff2) format("woff2"),
		url(/static/media/CircularStd-BookItalic.ed8527fe.woff) format("woff");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "CircularStd";
	src: url(/static/media/CircularStd-Bold.a52eb239.woff2) format("woff2"),
		url(/static/media/CircularStd-Bold.f44f7272.woff) format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "CircularStd";
	src: url(/static/media/CircularStd-Medium.3f308568.woff2) format("woff2"),
		url(/static/media/CircularStd-Medium.7bb71f82.woff) format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Circular Std Book";
	src: url(/static/media/CircularStd-Book.c3523999.woff2) format("woff2"),
		url(/static/media/CircularStd-Book.fed81e89.woff) format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

