.footer {
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 30px;
}

.footergroup {
	display: flex;
	justify-content: space-between;
	align-content: space-between;
	align-items: center;
	width: 100%;
}

.footer-sub-sec {
	display: flex;
	align-items: center;
}

.footer-sub-sec .footer-logo {
	margin-right: 20px;
}

.footer h6 {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(92, 101, 123);
	text-transform: uppercase;
	line-height: 1.538;
	padding: 28px 0 20px 0;
	font-weight: 700;
}

.footer-sub-sec p {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #5b607d;

	font-family: "Circular Std Book";
	margin-bottom: 0;
}

.footer-sub-sec a {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.429;
	text-decoration: none;
}

.footer-link li,
.footer-link li a {
	list-style: none;
	text-decoration: none;
	font-size: 14px;
	font-family: "Montserrat";
	color: rgba(255, 255, 255, 0.8);
	font-weight: 400;
	display: block;
	text-transform: uppercase;
}

.footer-social-icon li,
.footer-social-icon li a {
	list-style: none;
	text-decoration: none;
	display: inline-block;
}

.footer-social-icon li a svg {
	width: 32px;
	margin-top: 10px;
	margin-right: 10px;
}

.footer-social-icon svg {
	-webkit-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	-o-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.footer-social-icon svg:hover {
	-webkit-transform: translateY(-3px);
	-ms-transform: translateY(-3px);
	transform: translateY(-3px);
}

.footer-sub-sec .email-link,
.footer-sub-sec .email-link a {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #4c506b;
	font-family: Circular Std Book;
	list-style: none;
	transition: all 0.3s ease-in-out;
}

.footer-sub-sec .email-link a:hover {
	color: #131521;
}

.footer-sub-sec .email-link {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 30px;
}

.footer-sub-sec .email-link svg {
	margin-right: 10px;
}

.footer-sub-sec .email-link .devider svg {
	margin-right: 0;
}

.twitter,
.fb,
.l-in,
.med {
	fill: #77829c;
	fill-rule: evenodd;
	-webkit-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	-o-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.footer-social-icon svg:hover .twitter {
	fill: #55acee;
}

.footer-social-icon svg:hover .fb {
	fill: #3b5999;
}

.footer-social-icon svg:hover .l-in {
	fill: #0077b5;
}

.footer-social-icon svg:hover .med {
	fill: #02b875;
}

.cl-effect li a {
	overflow: hidden;
	height: 1.2em;
	margin-top: 12px;
}

.cl-effect li a span {
	position: relative;
	display: block;
	-webkit-transition: -webkit-transform 0.3s;
	transition: -webkit-transform 0.3s;
	-o-transition: transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}

.cl-effect li a span::before {
	position: absolute;
	top: 100%;
	content: attr(data-hover);
	font-weight: 700;
	font-size: 13.5px;
	color: #10e6ac;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.cl-effect li a:hover span,
.cl-effect li a:focus span {
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
}

.join-newsletter-form input {
	border-radius: 4px;
	background-color: rgb(255, 255, 255);
	width: 232px;
	width: 100%;
	height: 40px;
	border: none;
	outline: none;
	font-size: 14px;
	font-family: "Montserrat";
	border: 1px solid rgba(56, 241, 191, 0);
	color: rgb(32, 32, 32);
	line-height: 2.286;
	padding: 0 30px 0 15px;
	position: relative;
}

.join-newsletter-form {
	position: relative;
	margin-top: 10px;
}

/* .footer-logo img {
	width: 110px;
	margin-bottom: 15px;
} */

.join-newsletter-form button {
	position: absolute;
	height: 40px;
	top: 0;
	right: 0px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border: none;
	outline: none;
	background: #fff;
}

.join-newsletter-form button:hover {
	cursor: pointer;
}

.contact-link,
.contact-link a {
	font-size: 13px;
	font-family: "Montserrat";
	color: rgb(152, 161, 182);
	line-height: 2.462;
	font-weight: 300;
	margin-top: 10px;
	text-decoration: none;
}

.join-newsletter-form a {
	-webkit-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	-o-transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
	transition: 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.contact-link a:hover {
	color: #10e6ac;
}

.newsletter-hidden {
	position: absolute;
	left: -5000px;
}

li.f-email-add {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgba(255, 255, 255, 0.8);
	line-height: 1.429;
	font-weight: 400;
	padding: 6px 0;
}

@media only screen and (max-width: 940px) {
	.footergroup {
		grid-template-columns: repeat(2, 1fr);
	}

	.footer-sub-sec:nth-child(3),
	.footer-sub-sec:nth-child(4) {
		margin-top: 60px;
	}
}

@media only screen and (max-width: 767px) {
	.footergroup {
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}

	.footer-sub-sec:nth-child(2),
	.footer-sub-sec:nth-child(3),
	.footer-sub-sec:nth-child(4) {
		margin-top: 20px;
	}

	.footer-sub-sec:nth-child(1) {
		padding: 0 0 10px 0;
	}

	.footer-sub-sec:nth-child(2) {
		justify-self: center;
	}
}

.newsletterwrapper {
	background-color: rgb(48, 57, 78);
	max-width: 870px;
	/* padding: 27px 50px; */
	margin: 0 auto;
	border-radius: 50px;
	position: relative;
}

.newsletter-btn {
	position: absolute;
	top: 31%;
	right: 50px;
	width: 40px;
	height: 40px;
	background: none;
	border: none;
	outline: none;
	border-radius: 50%;
	background-image: -moz-linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	background-image: -ms-linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	background-image: linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	box-shadow: 0px 6px 25px 0px rgba(45, 10, 124, 0.2);
	transition: all 0.3s ease-in-out;
}

.newsletter-btn img {
	position: relative;
	top: -1px;
	transition: all 0.8s ease;
}

.newsletter-btn:hover img {
	-webkit-animation: arrrow-animate 0.8s ease infinite;
	animation: arrrow-animate 0.8s ease infinite;
}

@keyframes arrrow-animate {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(2px);
	}
	75% {
		transform: translateX(-2px);
	}
	100% {
		transform: translateX(0px);
	}
}

.newsletter-btn:hover {
	background: none;
	border: none;
	outline: none;
	background-image: linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
}

.newsletter-btn2 {
	position: absolute;
	top: 31%;
	right: 50px;
	width: 152px;
	height: 40px;
	background: none;
	border: none;
	outline: none;
	border-radius: 20px;
	background-image: -moz-linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	background-image: -ms-linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	background-image: linear-gradient(
		-60deg,
		rgb(101, 57, 200) 0%,
		rgb(151, 94, 223) 100%
	);
	box-shadow: 0px 6px 25px 0px rgba(45, 10, 124, 0.2);
}

.newsletter-btn2:hover img {
	-webkit-animation: arrrow-animate 0.8s ease infinite;
	animation: arrrow-animate 0.8s ease infinite;
}

.subscribe-btn {
	padding-right: 9px;
}
.inputwrapper {
	padding: 27px 50px;
}

.inputwrapper h4 {
	font-size: 20px;
	font-family: "Montserrat";
	color: rgb(255, 255, 255);
	line-height: 1.3;
	padding-bottom: 10px;
}

.inputwrapper p {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(118, 128, 153);
	line-height: 1.4;
}

.iw-hover:hover {
	cursor: pointer;
}

.iw-hover {
	cursor: pointer;
	padding: 27px 50px;
}

.input-label,
.email-r-title {
	font-size: 14px;
	font-family: "Montserrat";
	color: rgb(118, 128, 153);
	line-height: 1.429;
}

.email-feild {
	border: none;
	outline: none;
	background: none;
	color: #fff;
	width: 60%;
	font-size: 20px;
}

.email-feild:focus,
.email-feild:active {
	outline: none;
	border: none;
	background: none;
}

.email-feild::placeholder {
	color: rgb(233, 233, 233);
	font-size: 14px;
	opacity: 1;
}

.tick-mark {
	border-radius: 50%;
	background-color: rgb(118, 128, 153);
	padding: 9px 10px;
	margin-right: 25px;
}

.thanku-textGroup {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 600px) {
	.inputwrapper {
		padding-right: 70px;
	}

	.footer {
		padding: 0 20px;
	}

	.footer-sub-sec .email-link {
		grid-gap: 15px;
		gap: 15px;
	}

	.inputwrapper2 {
		padding-right: 0px;
	}

	.inputwrapper h4 {
		font-size: 16px;
	}

	.inputwrapper {
		padding: 27px 30px;
	}

	.newsletter-btn {
		right: 30px;
	}

	.email-feild {
		width: 100%;
	}

	.newsletter-btn2 {
		position: relative;
		width: 86%;
		margin-top: 30px;
		right: 0;
		left: 0;
		top: -30px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (max-width: 428px) {
	.resend-link {
		margin-left: 0px;
	}

	.email-feild {
		font-size: 16px;
	}

	.tick-mark {
		margin-right: 15px;
	}
}

.cursor {
	position: relative;
}
.cursor i {
	position: absolute;
	width: 1px;
	height: 80%;
	left: 0;
	background-color: #fff;
	top: 10%;
	animation-name: blink;
	animation-duration: 900ms;
	animation-iteration-count: infinite;
	opacity: 1;
}

.cursor input:focus + i {
	display: none;
}

/* .join-newsletter-form input {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  width: 232px;
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: "Montserrat";
  border: 1px solid rgba(56, 241, 191, 0);
  color: rgb(32, 32, 32);
  line-height: 2.286;
  padding: 0 30px 0 15px;
  position: relative;
}

.join-newsletter-form input:focus {
  border: 1px solid #10e6ac;
}

.join-newsletter-form {
  position: relative;
  margin-top: 10px;
}

.join-newsletter-form button {
  position: absolute;
  height: 40px;
  top: 0;
  right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  outline: none;
  background: transparent;
}

.join-newsletter-form button:hover {
  cursor: pointer;
} */
