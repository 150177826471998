.tax-text-wrapper {
  padding-left: 45px;
  max-width: 650px;
}

.tax-text-wrapper h4 {
  padding-bottom: 20px;
}

@media only screen and (max-width: 900px) {
  .Tax-info-page .auction-navbar .dashTitle {
    margin-left: 0px;
  }

  .tax-text-wrapper {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .tax-text-wrapper {
    padding-left: 15px;
  }
}
