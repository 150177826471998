.existingbidcard-table .table .status-col {
	min-width: 220px;
}

.existingbidcard-table .table .tag--active,
.existingbidcard-table .table .tag--inreview,
.existingbidcard-table .table .tag--success,
.existingbidcard-table .table .tag--closed {
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #3864e4;
	font-family: "CircularStd";
	font-weight: 500;
	background: rgba(56, 100, 228, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 60px;
	padding: 5px 15px;
}

.existingbidcard-table .table .tag--inreview {
	background: rgba(255, 174, 0, 0.15);
	color: #ffae00;
}

.existingbidcard-table .table .tag--success {
	background: rgba(83, 182, 73, 0.12);
	color: #53b649;
}

.existingbidcard-table .table .tag--closed {
	background: rgba(189, 72, 101, 0.1);
	color: #bd4865;
}





/* .auction-table .tag--active,
.auction-table .tag--inreview,
.auction-table .tag--Won,
.auction-table .tag--closed {
	font-weight: normal;
	font-size: 11px;
	line-height: 14px;
	text-align: center;
	color: #3864e4;
	font-family: "CircularStd";
	font-weight: 500;
	background: rgba(56, 100, 228, 0.1);
	backdrop-filter: blur(10px);
	border-radius: 60px;
	padding: 5px 15px;
}

.auction-table .tag--inreview{
	background: rgba(255, 174, 0, 0.15);
	color: #ffae00;
}

.auction-table .tag--active{
	background: rgba(83, 182, 73, 0.12);
	color: rgb(43, 43, 190);
}


.auction-table .tag--Won {
	background: rgba(83, 182, 73, 0.12);
	color: #53b649;
}

.auction-table .tag--closed {
	background: rgba(189, 72, 101, 0.1);
	color: #bd4865;
} */