
.main_box .box{
    flex:1;
    max-width:600px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.box label{
    font-size:12px;
    font-weight:700;
    cursor:pointer;
}
.box input,
.box select,
.box .dropdown_custom,
.box .dropdown_menu{
    border: 1px solid rgba(34, 34, 34, 0.253);
    border-radius: 8px;
}
.box input,
.box .dropdown_custom{
    width: 100%;
    height:40px;
    outline: 0;
    padding:4px 12px;
    box-sizing: border-box;
    font-size:14px;
}
.box input:focus{
    /* outline:1px solid rgba(34, 34, 34, 0.493);
    outline-offset: 2px; */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-color:rgba(34, 34, 34, 0.453);
    border-width:2px;
}
.box select{
    width:100%;
    padding:6px 8px;
    font-size: 14px;
}
.box .dropdown_custom{
    position:relative;
    padding:0;
    
}
.box .dropdown_custom .select{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height:100%;
    position: relative;
    overflow:hidden;
    padding:4px 12px;
    z-index: 1;
    text-transform: capitalize;
}
.box .dropdown_custom .select i{
    font-size: 10px;
    color:rgba(34, 34, 34, 0.582);
}
.box .dropdown_custom .select::before{
    content:'';
    cursor: pointer;
    background-color: transparent;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    z-index: 10;
}
.box .dropdown_custom .dropdown_menu{
    position:absolute;
    top:45px;
    left:0;
    transform:scale(0);
    list-style: none;
    width:100%;
    overflow: hidden;
    transition:all .25s;
}
.box .dropdown_custom .dropdown_menu.active{
    transform: scale(1);
}
.box .dropdown_custom .dropdown_menu li{
    padding:4px 12px;
    background:#fff;
    border-bottom: 1px solid rgba(34, 34, 34, 0.116);
    transition:background-color .25s;
    cursor:pointer;
    text-transform: capitalize;
}
.box .dropdown_custom .dropdown_menu li:hover{
    background-color:#222;
    color:#fff;
}
.box .dropdown_custom .dropdown_menu li:last-child{
    border-bottom: 0;
}

.box .dropdown .dropdown-menu.active{
    height:100%;
}