.account-main {
    background-color: white;
    padding: 15px;
    display: flex;
    justify-content:space-between;
    border:2px solid grey;
    border-radius: 5px;
    cursor: pointer; 
    margin-bottom: 15px;
}
/* #131521 */
.active-account {
    background-color: grey;
    padding: 15px;
    display: flex;
    justify-content:space-between;
    border:2px solid #131521;
    border-radius: 5px;
    margin-bottom: 15px; 
    color: white;
}
.img-white {
    color:white
}
@media only screen and (min-width: 600px) {
    .heading-top {
      font-weight: 12px !important;
    }
  }