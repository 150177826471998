.dashboard-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 320px;
}

.dashboard-navbar h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 600;
  margin-top: 25px;
}

.auction-navbar .createauction-btn-2 button {
  width: 165px;
  margin-right: 50px;
}

.dashTitle-2 {
  width: calc(100% - 270px);
  margin-left: auto;
  padding-top: 40px;
}

.dashTitle-2 h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 600;
  padding-left: 50px;
}

.auction-navbar {
  padding-top: 40px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-basic-button {
  background: #000000;
  border-radius: 33px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 11px 25px;
  font-family: "CircularStd";
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #000;
  color: white;
}

.dropdown-menu.show {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 12px 26px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  width: 285px;
  padding: 24px;
}

.dropdown-menu h3 {
  padding-bottom: 24px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #131521;
  font-family: "CircularStd";
}

.properties .nav {
  width: 100% !important;
  max-width: 237px;
  justify-content: center;
  border: 1px solid rgba(236, 237, 243, 0.55);
  border-radius: 60px;
  background: rgba(236, 237, 243, 0.55);
  width: 237px;
  overflow: hidden;
}

.properties .nav-tabs .nav-link {
  border: none;
  border-radius: none;
  margin: 0;
  background: none;
  text-align: center;
  color: #4c506b;
  font-family: CircularStd;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 116px;
  padding: 12px 0;
  border-radius: 60px;
  text-transform: capitalize;
}

.properties .nav-tabs .nav-link.active {
  border: none;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 60px;
  color: #3864e4;
  padding: 12px 0;
}

.properties .tab-content {
  margin-top: 24px;
}

.properties .dropdown-items li {
  list-style: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding-bottom: 16px;
}

.properties .dropdown-items li::after {
  content: "";
  position: absolute;
  left: 8px;
  top: 5px;
  border-left: 1px dashed #e3e3e3;
  height: 100%;
  z-index: -1;
}

.properties .dropdown-items li:nth-last-of-type(2) {
  padding-bottom: 0px;
}

.properties .dropdown-items li a {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #131521;
  font-family: "Circular Std Book";
  transition: 0.3s ease-in-out;
}

.properties .dropdown-items li a:hover {
  color: #3864e4;
}

.properties .dropdown-items .last-item a {
  padding-bottom: 0px;
  border-top: 1px solid #e3e3e3;
  padding-top: 20px;
  margin-top: 20px;
}

.properties .dropdown-items .last-item::after {
  border: none;
}

@media only screen and (max-width: 900px) {
  .dashboard-navbar {
    padding-left: 30px;
  }

  .dashTitle-2 {
    width: calc(100% - 0px);
    margin-left: auto;
  }

  .dashTitle-2 h4 {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard-navbar {
    padding-left: 15px;
  }

  .dashboard-navbar h1 {
    font-size: 26px;
    line-height: 36px;
    margin-top: 25px;
  }

  .dashTitle-2 h4 {
    font-size: 20px;
    line-height: 32px;
    padding-left: 15px;
  }

  .dashTitle-2 {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 560px) {
  .auction-navbar .createauction-btn-2 button {
    width: 100%;
    margin-right: 0px;
  }
}

/* --------------------------------- */

.properties-investor-table .table {
  display: inline-table;
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(100px);
  border-radius: 10px;
}

.properties-investor-table .dashTitle-bar {
  background: #ffffff;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(100px);
  border-radius: 10px 10px 0 0;
  padding: 30px 30px 10px 30px;
  max-width: 100%;
  width: 100%;
}

.properties-investor-table .dashTitle h4 {
  font-size: 22px;
  line-height: 28px;
  color: #131521;
  font-weight: 500;
}

.properties-investor-table .createauction-btn button {
  background: #000000;
  border-radius: 33px;
  color: #ffffff;
  padding: 11px 25px;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  font-family: "CircularStd";
  border: none;
  outline: none;
  position: relative;
  transition: all 0.6s ease-in-out;
  transition: 0.3s ease-in-out;
}

.properties-investor-table .table thead th {
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 30px;
  color: #9fa2b8;
  position: relative;
  z-index: 10;
  width: 20%;
  font-family: "CircularStd";
}

.properties-investor-table .table td {
  border-top: 1px solid #f4f5fa;
  padding: 15px 30px;
  vertical-align: middle;
  /* min-width: 170px; */
}

.properties-investor-table .table .property-col {
  display: flex;
  align-items: center;
  min-width: 300px;
}

.properties-investor-table .table .property-col img {
  border-radius: 50%;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(100px);
}

.properties-investor-table .table .property-col h6 {
  font-size: 15px;
  line-height: 21px;
  color: #131521;
  font-family: "CircularStd";
  font-weight: 500;
}

.properties-investor-table .table .tag--active,
.properties-investor-table .table .tag--inreview,
.properties-investor-table .table .tag--success,
.properties-investor-table .table .tag--closed {
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #3864e4;
  font-family: "CircularStd";
  font-weight: 500;
  background: rgba(56, 100, 228, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 60px;
  padding: 5px 15px;
}

.properties-investor-table .table .tag--inreview {
  background: rgba(255, 174, 0, 0.15);
  color: #ffae00;
}

.properties-investor-table .table .tag--success {
  background: rgba(83, 182, 73, 0.12);
  color: #53b649;
}

.properties-investor-table .table .tag--closed {
  background: rgba(189, 72, 101, 0.1);
  color: #bd4865;
}

.dash-tbl-col p {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #131521;
  font-family: "Circular Std Book";
}

.Dashboard .AuctionPage {
  display: inherit;
}

/* @media only screen and (max-width: 1330px) {
	.paymentmade-table .table {
		display: block;
	}
} */

@media only screen and (max-width: 900px) {
  .paymentmade-page-topbar {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .properties-investor-table .table {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .properties-investor-table .dashTitle h4 {
    font-size: 19px;
    line-height: 25px;
  }

  .properties-investor-table .table .property-col {
    min-width: 250px;
  }

  .properties-investor-table .table .status-col {
    min-width: 190px;
  }

  .properties-investor-table .table .amount-raising-col,
  .properties-investor-table .table .date-col {
    min-width: 140px;
  }

  .paymentmade-page-topbar h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .paymentmade-page-topbar {
    padding-left: 15px;
  }

  .properties-investor-table .table .property-col h6 {
    font-size: 16px;
    line-height: 23px;
  }

  .properties-investor-table .table .property-col img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  .properties-investor-table .table td {
    padding: 15px;
  }
  .properties-investor-table .table thead th {
    padding: 12px 15px;
  }

  .properties-investor-table .dashTitle-bar {
    padding: 30px 15px 10px 15px;
  }
}

@media only screen and (max-width: 480px) {
  .dashTitle-bar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .properties-investor-table .table {
    display: block;
  }

  .properties-investor-table .createauction-btn {
    margin-top: 20px;
  }

  .properties-investor-table .createauction-btn,
  .properties-investor-table .createauction-btn button {
    width: 100%;
  }
}
